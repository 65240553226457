import React, { useEffect, useRef, useState } from "react";
import {
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Modal
} from "reactstrap";

import Helmet from "react-helmet";
// availity-reactstrap-validation

import { withRouter } from "react-router-dom";

import SimpleBar from "simplebar-react";
import MetisMenu from "metismenujs";
import { Link } from "react-router-dom";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import { ApiUrl } from "../../config";
import { bcrypt, currentDate, dcrypt, uInfo, uToken } from "../../useToken";
import { toast, ToastContainer, Flip } from "react-toastify";
import Survey from "./survey.js";
import Summary from "./summary.js";
import Results from "./results";
import Agreements from "./agreements.js";
import Priorities from "./priorities.js";
import Reports from "./reports.js";
import Guides from "./guides.js";
import Roi from "./roi.js";
import Implementation from "./implementation";
import "./style.css";
import $ from "jquery";
import Loader from "react-loader";
import { currencies } from "../../common/Common-Currency.js";
import Favicon from './../../assets/images/favicon.png';
import swal from 'sweetalert';
import ProgressBar from 'react-bootstrap/ProgressBar'
require("highcharts/modules/exporting")(Highcharts);

const Assesments = (props) => {
  const ref = useRef();
  const [params] = useState(props.match.params);
  const defaultPages = [
    "priorities",
    "implementation",
    "reports",
    "roi",
    "agreements",
    "guides",
    "coaching",
  ];
  const [sidebar, setSideBar] = useState([]);
  const [isCatCompleted, setIsCatCompleted] = useState(0);
  const [records, setRecords] = useState({ report: [] });
  const [dafault_summary, setDafaultSummary] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [assessment, setAssessment] = useState({ report: [], questions: [], reports: [] });
  const [currency, setCurrency] = useState("");
  const [results, setResults] = useState([]);
  const [report, setReport] = useState(false);
  const [AssessmentTab, setAssessmentTab] = useState("summary");
  const [siderbarShow, setSideBarShow] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [rerender_roi, setRerenderRoi] = useState(true);
  const [make_default, setMakeDefalt] = useState(0);
  const [assesment_list, setAssessmentList] = useState([]);
  const [toggleSwitch, settoggleSwitch] = useState(false);
  const [grossPercentage, setGrossPercentage] = useState(0);
  const [netPercentage, setNetPercentage] = useState(0);
  const [canEdit] = useState(uInfo().type == 'Client' ? false : true);
  const [proposalPreviewModal, setProposalPreviewModal] = useState(false);
  const [proposal, setProposals] = useState({
    p_type: '',
    default: [],
    custom: [],
    revenueProducts: [],
    proposal_id: 0,
    product_id: 0
  });
  const [itemsArray, setItemArray] = useState([]);


  const initMenu = () => {
    let pathName = window.location.pathname;
    if (pathName.split("/").pop() == 'result') {
      pathName = window.location.pathname.substring(0, pathName.lastIndexOf('/'));
    }
    new MetisMenu("#side-menu");
    const ul = document.getElementById("side-menu");
    const items = ul.getElementsByTagName("a");
    for (let i = 0; i < items.length; ++i) {
      if (pathName === items[i].pathname) {
        if (items[i].parentNode.parentElement.tagName == "LI") {
          items[i].parentNode.parentElement.firstChild.classList.add("active");
        }
        if (items[i].parentNode.parentElement.tagName == "DIV") {
          items[i].parentNode.parentElement.classList.add("d-block");
          items[i].parentNode.parentElement.parentElement.firstChild.classList.add("active");
        }
        items[i].parentNode.classList.add("d-block");
        items[i].classList.add("active");
        // break;
      } else {
        items[i].classList.remove("active");
      }
    }
  };

  React.useEffect(() => {
    initMenu();
    getAssessment();
    // getResults();
    //console.log(props.match.params.slug, "params"); 
  }, [props]);

  React.useEffect(() => {
    initMenu();
  }, [sidebar]);

  React.useEffect(() => {
    console.log('yy', toggleSwitch)
  }, [toggleSwitch]);

  useEffect(() => {
    getModules();
    getAssessmentList();
    const page_slug = props.match.params.slug;
    if (defaultPages.indexOf(page_slug) == "-1") {
      const page_slug = "";
    } else {

      toggleAssTab(page_slug);
    }
  }, []);

  const findSymbol = (code) => {
    let currency = currencies[code];
    if (currency) {
      setCurrency(currency.symbol);
    }
  };

  const getModules = () => {
    // console.log(`Bearer ` + uToken())
    fetch(`${ApiUrl}` + "get/assessment/modules", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({
        id: dcrypt(params.id),
        slug: props.match.params.slug,
      }),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        //console.log('report--- ', response.data.report)
        if (response.status === true) {
          setIsCatCompleted(response.data.is_cat_completed);
          setSideBar(response.data.records);
          setRecords(response.data);
          setDafaultSummary(response.data.report);
          findSymbol(response.data.assessment.currency_code);
          setGrossPercentage(response.data.gross_percentage);
          setNetPercentage(response.data.net_percentage);
          setLoading(true);
        } else {
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
          setLoading(true);
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
        setLoading(true);
      });
  };
  const defaultSummary = async () => {
    getModules();
  };


  const getAssessmentUpdate = () => {
    setLoading(false);
    fetch(`${ApiUrl}` + "get/assessment", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({
        id: dcrypt(params.id),
        slug: props.match.params.slug,
      }),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          setAssessment(response.data);
          setLoading(true);
        } else {
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
          setLoading(true);

        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
        setLoading(true);

      });
  };

  const getAssessment = () => {
    //console.log('heeeeeee')
    setLoading2(false);
    setLoading(false);
    fetch(`${ApiUrl}` + "get/assessment", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({
        id: dcrypt(params.id),
        slug: props.match.params.slug,
      }),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          // setSideBar(response.data.records);
          if (response.data.assessment.other_settings.welcome_accepted == 0) {
            setConfirmModal(true);
          }
          setAssessment(response.data);
          setLoading2(true);
          setLoading(true);
        } else {
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
          setLoading2(true);
          setLoading(true);

        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
        setLoading2(true);
        setLoading(true);

      });
  };

  const getAssessmentList = () => {
    fetch(`${ApiUrl}` + "assessments", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({
        id: dcrypt(params.id),
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          setAssessmentList(response.data.records);
        } else {
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const getRerenderAssessment = () => {
    setLoading(false);
    fetch(`${ApiUrl}` + "get/assessment", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({
        id: dcrypt(params.id),
        slug: props.match.params.slug,
      }),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          // setSideBar(response.data.records);
          //console.log(response.data.assessment.module, "setAssessment");
          getModules();
          setAssessment(response.data);

        } else {
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });

        }
        setLoading(true);
      })
      //Then with the error genereted...
      .catch((error) => {
        setLoading(true);

        console.error("Error:", error);

      });
  };

  const getResults = () => {
    fetch(`${ApiUrl}` + "get/assessments/results", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({
        id: dcrypt(params.id),
        slug: props.match.params.slug,
      }),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          // setSideBar(response.data.records);
          setResults(response.data);
          setReport(response.data.report);
          setLoading(true);
        } else {
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
          setLoading(true);
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
        setLoading(true);
      });
  };


  const welcomeAccepted = () => {
    fetch(`${ApiUrl}` + "assessments/accepted", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({
        id: dcrypt(params.id),
        slug: props.match.params.slug,
      }),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          setConfirmModal(false);
          props.history.push(`/assessment/${params.id}/revenue_statement`);
          setLoading(true);
        } else {
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
          setLoading(true);
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
        setLoading(true);
      });
  }
  function NumberWithCommas(x) {
    let y = Math.trunc(x);
    return y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  const toggleAssTab = (tab) => {
    setAssessmentTab(tab);
    props.history.push("/assessment/" + params.id + "/" + tab);
  };

  function isWhatPercentOf(numA, numB) {
    if (numB == 0) {
      return 0;
    }
    console.log((numA / numB) * 100)
    return (numA / numB) * 100;
  }

  // const updateRoiComponent = () => {
  //   setRerenderRoi(false);
  //   // getAssessment();
  //   setTimeout(() => {
  //     setRerenderRoi(true);
  // }, 1000);



  // }

  const markAssessmentDefault = () => {
    fetch(`${ApiUrl}` + "mark/assessmet/default", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({
        id: dcrypt(params.id),
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          setMakeDefalt(1);
        } else {
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  const sendAssessmentProposal = (proposal_id, client_id, assesments_id, user_id, type) => {
    swal({
      title: "Are you sure?",
      text: "You want to send proposal.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((confirmation) => {
        if (confirmation) {
          fetch(`${ApiUrl}` + "send/assessment/proposal", {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({
              proposal_id: proposal_id,
              client_id: client_id,
              assesments_id: assesments_id,
              user_id: user_id,
              type: type,
            }),
          })
            .then((response) => response.json())
            .then((response) => {
              if (response.status === true) {
                swal({
                  title: response.message,
                  icon: "success",
                });
                setProposalPreviewModal(false);
                setProposals({
                  p_type: '',
                  default: [],
                  custom: [],
                  revenueProducts: [],
                  proposal_id: 0
                });

              } else {
                swal({
                  title: response.message,
                  icon: "warning",
                });
              }
            })
            //Then with the error genereted...
            .catch((error) => {
              console.error("Error:", error);
            });
        }
      });
  }

  const getProposal = (type = "0", p_type, product_id = "0") => {
    let id = 0;
    let proposal_type = type;
    if (product_id != 0) {
      id = product_id;
      proposal_type = 'coaching';

    }

    fetch(`${ApiUrl}` + "getProduct/proposals/" + id + '/' + proposal_type, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({
        products: 1,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {


          setProposals((prevState) => ({
            ...prevState,
            custom: response.data.record,
            default: response.data.record,
            revenueProducts: response.data.revenueProducts,
            p_type: type,
            product_id: product_id
          }));


        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  }


  const handleSubmitAssignProduct = async (event) => {
    event.preventDefault();

    let bodyParams = {
      product_id: proposal.product_id,
      proposal_id: proposal.proposal_id,
      master_type: proposal.p_type,
      renewal_interval: proposal.product_interval,
      sub_proposal_type: 'AssessmentProposal',
      type: proposal.payment_type,
      items_data: itemsArray,
      assessment_id: assessment.assessment?.assessment_id,
      created_for_id: assessment.assessment?.client_id,
      invoice_for: 'Client',
      start_date: currentDate(),
      total_price: proposal.total_price,
    };

    // Check if any property is empty, null, or undefined
    for (const key in bodyParams) {
      // if (key === 'total_price' && bodyParams[key] === 0) {
      //   continue; 
      // }

      if (!bodyParams[key]) {
        let words = key.split('_');
        let capitalizedKey = words.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
        swal({
          title: `Please add a value for ${capitalizedKey}`,
          icon: "warning",
        });
        return;
      }
    }

    // Check if itemsArray length is less than 1
    if (itemsArray.length < 1) {
      swal({
        title: `Please add a product description`,
        icon: "warning",
      });
      return;
    }

    fetch(`${ApiUrl}` + "add/invoice", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(bodyParams),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          // setModal(false);
          setProposalPreviewModal(false);
          setProposals({
            product_id: 0,
            proposal_id: 0,
            payment_type: '',
            product_interval: '',
            description: '',
            invoice_type: '',
            total_price: 0,
            p_type: '',
            currency: currency
          });
          setItemArray([]);

          swal({
            title: response.message,
            icon: "success",
          });
        } else {
          swal({
            title: response.message,
            icon: "warning",
          });

        }
        // setLoaded(true);
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
        // setLoaded(true);
      });
  }

  const handleChange = (e, index, field) => {
    const updatedItemArray = [...itemsArray];
    updatedItemArray[index][field] = e.target.value;
    if (field === 'total') {
      const sum = updatedItemArray.reduce((total, item) => total + parseFloat(item.total || 0), 0);
      setProposals((prevState) => ({
        ...prevState,
        total_price: sum
      }));
    }

    setItemArray(updatedItemArray);
  };

  return (
    <React.Fragment>

      <Row className="mb-4">
        <Col lg={1}></Col>

        <Col lg={siderbarShow ? '9' : '10'}>
          <Row>

            <Col lg="5">
              <section className="breakthrough d-flex border-radius-0 justify-content-start m-0">
                <button title="Back to assessment lists" className="btn btn-link"
                  onClick={() => props.history.push(uInfo().type == 'Client' ? `/client/profile` : `/assessment/list/all`)}>
                  <i className="fas fa-arrow-left"></i>
                </button>
                <div className="MonthlyDropDown p-tracker mb-1">
                  <select value={dcrypt(params.id)} onChange={(e) => {
                    if (e.target.value != '') {
                      props.history.push(`/assessment/${bcrypt(e.target.value)}/summary`)
                    }
                  }
                  } className="form-control">
                    <option value={''}>Assessment Id</option>
                    {assesment_list.length > 0 &&
                      assesment_list.map((row, i) => {
                        return (
                          <option key={i} value={row.assessment_id}>{row.assessment_id + ' # ' + row.name}</option>
                        )
                      }
                      )
                    }
                  </select>

                </div>
                {
                  canEdit && assessment.assessment && (
                    <div className="d-flex">
                      {
                        assessment.assessment.default_status == 0 && (
                          <input title="Set this assessmet as default assessmet" className="ml-3" type={'checkbox'} disabled={make_default == 0 ? false : true} onChange={(e) => e.target.checked && (markAssessmentDefault())} />
                        )
                      }

                      {
                        canEdit && (
                          <button title="Share Proposal" onClick={() => setProposalPreviewModal(true)} className="btn btn-link btn-sm text-black fs-4 ml-3"><i class="fas fa-share-alt"></i></button>
                        )
                      }

                      {/* <button onClick={() => sendAssessmentProposal(2, assessment.assessment?.client_id, assessment.assessment?.assessment_id, uInfo().user_id, 'p')} className="btn btn-link btn-sm text-black" title="Share the proposal"><i class="fas fa-share-alt"></i></button>
                      <Link target={'_blank'} to={`/proposal/Mg==?c=${bcrypt(assessment.assessment?.client_id)}&a=${bcrypt(assessment.assessment?.assessment_id)}&u=${bcrypt(uInfo().user_id)}&t=p`} className="btn btn-link btn-sm text-black" title="Preview the proposal"><i className="fas fa-eye"></i></Link> */}
                    </div>
                  )
                }

              </section>
            </Col>
            <Col lg="7" className="d-flex align-items-center flex-row-reverse">
              <div className="form-check form-switch pull-right" >
                <label className="m-0">Toggle Gross Profit</label>
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="customSwitch2"
                  onClick={e => {
                    settoggleSwitch(!toggleSwitch)
                  }}
                />
              </div>
              <section className="breakthrough  d-flex border-radius-0 justify-content-end m-0">
                <h5 className="mb-0 mr-3">
                  {records.assessment &&
                    <button title="Go to lead details" className="btn btn-link text-dark fs-5"
                      onClick={() => props.history.push(uInfo().type == 'Client' ? `/client/profile` : `/client/dialer/${bcrypt(assessment.assessment.client_id)}/${bcrypt(assessment.assessment.coach_id)}`)}>
                      {records.assessment.name}'s
                    </button>}

                  Total Impact:</h5>
                <h3 title="Total impact" className="mb-0 color-success mr-30">
                  <span className={`currencyIcon ${!toggleSwitch}`}>{currency}</span>
                  {dafault_summary.length > 0 && !toggleSwitch && (
                    NumberWithCommas(dafault_summary[0].amount)
                  )}

                  {/* dafault_summary.length > 0 && toggleSwitch && (
                    NumberWithCommas(dafault_summary[4].amount)
                  ) */}
                  {/* changed 4 "total_net_profit_impact" to 8 "total_gross_profit_impact" */}
                  {dafault_summary.length > 0 && toggleSwitch && (
                    NumberWithCommas(dafault_summary[8].amount)
                  )}


                </h3>
              </section>
            </Col>
            {/* </div> */}
            <Col lg="12">
              <section className="top-section Assessments card card-body p-0">
                <Row>
                  <Col xs="12">


                    <div className="SectionHeader">
                      {dafault_summary.length > 0 && !toggleSwitch &&
                        <div className="Percents">
                          <h4 title={'Current revenue ' + currency + '' + NumberWithCommas(dafault_summary[1].amount)} className="GrayBG" style={{ background: '#041934', width: `${isWhatPercentOf(dafault_summary[1].amount, Number(dafault_summary[1].amount) + Number(dafault_summary[3].amount))}%` }}>
                            <span className="pull-left">
                              <p>
                                {/* <i className="fas fa-dollar-sign"></i> */}
                                {currency}{NumberWithCommas(dafault_summary[1].amount)}
                              </p>
                            </span>
                          </h4>
                          <h4 title={'New Annual gross revenue ' + currency + '' + NumberWithCommas(dafault_summary[3].amount)} className="GreenBG text-end" style={{ background: '#008037', width: `${isWhatPercentOf(dafault_summary[3].amount, Number(dafault_summary[1].amount) + Number(dafault_summary[3].amount))}%` }}>
                            <div className="show-value">
                              {/* <i className="fas fa-dollar-sign"></i> */}
                              {currency}{NumberWithCommas(dafault_summary[3].amount)}
                            </div>

                          </h4>
                          <div className="TotalPercent">
                            <h4 title="Profit impact" className="text-end">

                              <span className="percentageSign pull-right"> <i className="fa fa-arrow-circle-up"></i> {dafault_summary[0].percentage}%</span>

                            </h4>
                          </div>
                        </div>
                      }
                      {/* changed 5 "current_net_profit" to 9 "current_gross_profit" */}
                      {/* changed 6 "new_annual_net_profit" to 10 "new_annual_gross_profit" */}
                      {dafault_summary.length > 0 && toggleSwitch &&
                        <div className="Percents">
                          <h4 title={'Current Gross Profit ' + currency + '' + NumberWithCommas(dafault_summary[9].amount)} className="GrayBG" style={{ background: '#041934', width: `${isWhatPercentOf(dafault_summary[9].amount, Number(dafault_summary[9].amount) + Number(dafault_summary[10].amount))}%` }}>
                            <span className="pull-left">
                              <p>
                                {/* <i className="fas fa-dollar-sign"></i> */}
                                {/* changed 5 "current_net_profit" to 9 "current_gross_profit" */}
                                {currency}{NumberWithCommas(dafault_summary[9].amount)}
                              </p>
                            </span>
                          </h4>
                          {/* changed 6 "new_annual_net_profit" to 10 "new_annual_gross_profit" */}
                          <h4 title={'New Annual Gross Profit ' + currency + '' + NumberWithCommas(dafault_summary[10].amount)} className="GreenBG text-end" style={{ background: '#008037', width: `${isWhatPercentOf(dafault_summary[10].amount, Number(dafault_summary[9].amount) + Number(dafault_summary[10].amount))}%` }}>
                            <div className="show-value"><span className="currencyIcon">
                              {/* <i className="fas fa-dollar-sign"></i>  */}
                            </span>
                              {currency}{NumberWithCommas(dafault_summary[10].amount)}</div>

                          </h4>
                          <div className="TotalPercent">
                            <h4 title="Profit impact" className="text-end">

                              <span className="percentageSign pull-right"> <i className="fa fa-arrow-circle-up"></i> {dafault_summary[4].percentage}%</span>

                            </h4>
                          </div>
                        </div>
                      }

                    </div>
                  </Col>


                </Row>
                {loading &&
                  <Summary
                    currency={currency}
                    slug={props.match.params.slug}
                    questions={assessment.reports}
                    assessment={dcrypt(params.id)}
                    module={assessment.current_module}
                  // history={props.history}
                  // profitImpact={dafault_summary.length > 0 ? NumberWithCommas(dafault_summary[0].amount) : 0}
                  // currentProfit={dafault_summary.length > 0 ? Number(dafault_summary[2].amount) : 0}
                  // currentRevenue={dafault_summary.length > 0 ? Number(dafault_summary[1].amount) : 0}
                  // ExpectedIncrease={dafault_summary.length > 0 ? Number(dafault_summary[3].amount) : 0}
                  // AnnualProfit={dafault_summary.length > 0 ? Number(dafault_summary[4].amount) : 0}
                  // AnnualGrossRevenue={dafault_summary.length > 0 ? Number(dafault_summary[5].amount) : 0}
                  // YearProfitImpact={dafault_summary.length > 0 ? Number(dafault_summary[6].amount) : 0}
                  />

                }
              </section>


              <Loader loaded={loading}></Loader>

              <section className="mt-3 SummaryAssessment">
                <Row>

                  {
                    props.match.params.slug == "implementation" || props.match.params.slug == 'summary' && (
                      loading &&
                      props.match.params.slug == "implementation" || props.match.params.slug == 'summary' &&
                      !props.match.params.result && (
                        <Col xs="12">
                          <div className="">
                            <Implementation
                              currency={currency}
                              canEdit={canEdit}
                              slug={props.match.params.slug}
                              assessment={dcrypt(params.id)}
                              module={assessment.current_module}
                              name={records.assessment ? records.assessment.name : ''}
                              getAssessment={getAssessment}

                            />
                          </div>
                        </Col>
                      ))
                  }


                  <Col xs="12">
                    <div className={`${props.match.params.slug == "reports" ? 'card card-body' : 'survey'}`}>
                      {loading &&
                        props.match.params.slug == "priorities" &&
                        !props.match.params.result && (

                          <Priorities
                            currency={currency}
                            slug={props.match.params.slug}
                            questions={assessment.questions}
                            assessment={dcrypt(params.id)}
                            module={assessment.current_module}
                          />
                        )}

                      {loading &&
                        props.match.params.slug == "agreements" &&
                        !props.match.params.result && (
                          <Agreements
                            slug={props.match.params.slug}
                            assessment={dcrypt(params.id)}
                            module={assessment.current_module}
                          />
                        )}
                      {loading &&
                        props.match.params.slug == "reports" &&
                        !props.match.params.result && (
                          <Reports
                            slug={props.match.params.slug}
                            assessment={dcrypt(params.id)}
                            module={assessment.current_module}
                          />
                        )}
                      {/* {
                        loading2 &&
                        props.match.params.slug == "roi" && assessment.assessment && (
                          <Roi
                            slug={props.match.params.slug}
                            assessment={dcrypt(params.id)}
                            module={assessment.current_module}
                            investment={assessment.assessment.other_settings}
                            getAssessment={getAssessment}
                            currentProfit={dafault_summary.length > 0 ? Number(dafault_summary[2].amount) : 0}
                            currentRevenue={dafault_summary.length > 0 ? Number(dafault_summary[1].amount) : 0}
                          />
                        )} */}
                      {loading &&
                        props.match.params.slug == "guides" &&
                        !props.match.params.result && (
                          <Guides
                            slug={props.match.params.slug}
                            assessment={dcrypt(params.id)}
                            module={assessment.current_module}
                          />
                        )}

                      {loading2 &&
                        props.match.params.slug != "summary" &&
                        defaultPages.indexOf(props.match.params.slug) == "-1" &&
                        !props.match.params.result && (
                          <Survey
                            defaultSummary={defaultSummary}
                            currency={currency}
                            report={report}
                            slug={props.match.params.slug}
                            questions={assessment.questions}
                            assessment={dcrypt(params.id)}
                            module={assessment.current_module}
                            getAssessment={getRerenderAssessment}
                            module_id={assessment.assessment.module}
                            fillSurvey={sidebar}
                            isCatCompleted={isCatCompleted}
                            module_name={assessment.cat_name}
                            module_description={assessment.slug_description}
                            assessment_name={records.assessment ? records.assessment.name : ''}
                            canEdit={canEdit}
                          // updateRoiComponent={updateRoiComponent}
                          />
                        )}

                      {loading &&
                        props.match.params.slug == "summary" &&
                        !props.match.params.result && (
                          <>
                            {/* <Summary
                                    currency={currency}
                                    slug={props.match.params.slug}
                                    questions={assessment.questions}
                                    assessment={dcrypt(params.id)}
                                    module={assessment.current_module}
                                    history={props.history}
                                    profitImpact={dafault_summary.length > 0 ? NumberWithCommas(dafault_summary[0].amount) : 0}
                                    currentProfit={dafault_summary.length > 0 ? Number(dafault_summary[2].amount) : 0}
                                    currentRevenue={dafault_summary.length > 0 ? Number(dafault_summary[1].amount) : 0}
                                    ExpectedIncrease={dafault_summary.length > 0 ? Number(dafault_summary[3].amount) : 0}
                                    AnnualProfit={dafault_summary.length > 0 ? Number(dafault_summary[4].amount) : 0}
                                    AnnualGrossRevenue={dafault_summary.length > 0 ? Number(dafault_summary[5].amount) : 0}
                                    YearProfitImpact={dafault_summary.length > 0 ? Number(dafault_summary[6].amount) : 0}
                                  /> */}
                            {/* 
                                  {
                                    dafault_summary.length > 0 && assessment.assessment && (
                                      <Roi
                                        slug={props.match.params.slug}
                                        assessment={dcrypt(params.id)}
                                        module={assessment.current_module}
                                        investment={assessment.assessment.other_settings}
                                        getAssessment={getAssessment}
                                        questions={assessment.questions}
                                        currentProfit={dafault_summary.length > 0 ? Number(dafault_summary[2].amount) : 0}
                                        currentRevenue={dafault_summary.length > 0 ? Number(dafault_summary[1].amount) : 0}
                                      />
                                    )} */}
                          </>
                        )}

                      {results.datam && props.match.params.result && (
                        <Results
                          currency={currency}
                          slug={props.match.params.slug}
                          results={results.datam}
                          assessment={dcrypt(params.id)}
                          module={assessment.current_module}
                        />
                      )}

                      {
                        dafault_summary.length > 0 && assessment.assessment && loading && (
                          <Roi
                            slug={props.match.params.slug}
                            currency={currency}
                            assessment={dcrypt(params.id)}
                            module={assessment.current_module}
                            investment={assessment.assessment.other_settings}
                            getAssessment={getAssessmentUpdate}
                            questions={assessment.reports}

                            currentRevenue={dafault_summary[1].amount}
                            AnnualGrossRevenue={dafault_summary[3].amount}
                            currentRevenuePercentage={dafault_summary[0].percentage}
                            ExpectedIncrease={dafault_summary[2].amount}

                            currentProfit={dafault_summary[9].amount}
                            AnnualProfit={dafault_summary[10].amount}
                            currentProfitPercentage={dafault_summary[8].percentage}
                            ExpectedIncreaseProfit={dafault_summary[8].amount}


                            currentNetProfit={dafault_summary[5].amount}
                            AnnualNetProfit={dafault_summary[6].amount}
                            currentNetProfitPercentage={dafault_summary[4].percentage}
                            ExpectedIncreaseNetProfit={dafault_summary[4].amount}

                            profitImpact={dafault_summary[3].amount}
                            YearProfitImpact={dafault_summary[6].amount}

                            grossPercentage={grossPercentage}
                            netPercentage={netPercentage}
                            canEdit={canEdit}


                          />
                        )}
                    </div>
                  </Col>
                  {/* <Col md="4" className="">
                          <div className="card card-body">
                            <HighchartsReact
                              highcharts={Highcharts}
                              options={options.chartData}
                            />
                          </div>
                        </Col> */}


                </Row>
              </section>
            </Col>
          </Row>
          <Row></Row>
        </Col>
        <Col lg={siderbarShow ? '2' : '1'} className="assessment">
          <button className={!siderbarShow ? 'border-0 fixed-faveicon' : 'd-none'} onClick={() => setSideBarShow(!siderbarShow)} >
            <img src={Favicon} width={40} />
          </button>
          <div className={siderbarShow ? 'assessment-vertical-menu' : 'd-none'}>
            <div data-simplebar className={'h-100'}>
              <i onClick={() => setSideBarShow(!siderbarShow)} className="fas fa-times sidebar-close"></i>
              <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
                <div id="sidebar-menu">
                  <ul className="metismenu list-unstyled" id="side-menu">
                    <li className="all-item sub-nav-bg p-3 text-center mb-1" key={"s-0"}> <h5>Impact Strategies</h5></li>
                    {sidebar.map((row, i) => {
                      return (
                        <li className={'all-item '} key={"s-" + i}>
                          {row.has_child == 0 ? (
                            <Link
                              to={`/assessment/${params.id}/${row.key}`}
                              className={`waves-effect dropdown-btn i_${i}`}
                              onClick={() => {
                                $(".dropdown-btn").removeClass("active");
                                $(".all-item").removeClass("active");
                                $(".dropdown-container").removeClass("d-block");
                                $(".i_" + i).addClass("active");
                              }}
                            >
                              <span>{row.label}</span>
                            </Link>
                          ) : row.has_child == 1 ? (
                            <>
                              <button
                                className={`dropdown-btn i_${i}`}
                                onClick={() => {
                                  if ($('.i_' + i).hasClass('active')) {
                                    $(".dropdown-btn").removeClass("active");
                                    $(".dropdown-container").removeClass(
                                      "d-block"
                                    );
                                  } else {
                                    $(".dropdown-btn").removeClass("active");
                                    $(".dropdown-container").removeClass(
                                      "d-block"
                                    );
                                    $(".i_" + i).addClass("active");
                                    $(".k_" + i).addClass("d-block");
                                  }
                                }}
                              >
                                {row.label}
                                {/* <i className="fa fa-caret-down"></i> */}
                              </button>
                              <div className={`dropdown-container k_` + i}>
                                {row.child &&
                                  row.child.map((c, k) => {
                                    if (c.has_child == 1) {
                                      return (
                                        <div key={k}>
                                          <button
                                            className={`dropdown-btn i_${i}`}
                                            onClick={() => {
                                              $(".dropdown-btn").removeClass("active");
                                              $(".dropdown-container").removeClass(
                                                "d-block"
                                              );
                                              $(".i_" + i).addClass("active");
                                              $(".k_" + i).addClass("d-block");
                                            }}
                                          >
                                            {c.label}
                                            {/* <i className="fa fa-caret-down"></i> */}
                                          </button>
                                          <div className={`sub-child dropdown-container k_` + i}>
                                            {c.child &&
                                              c.child.map((s, k) => {
                                                return (
                                                  <Link
                                                    key={i + "---" + k}
                                                    to={`/assessment/${params.id}/${s.key}`}
                                                    className="waves-effect sub-child-a"
                                                  >
                                                    <span>{s.label}</span>
                                                  </Link>
                                                );
                                              })}
                                          </div>
                                        </div>
                                      )
                                    } else {
                                      return (
                                        <Link
                                          key={i + "---" + k}
                                          to={`/assessment/${params.id}/${c.key}`}
                                          className={`waves-effect ${c.fill == 1 ? 'survey-filled' : ''}`}
                                        >
                                          <span>{c.label}</span>
                                        </Link>
                                      );
                                    }
                                  })}
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </SimpleBar>
            </div>
          </div>
        </Col>

        <Modal
          isOpen={confirmModal}
          fade={true}
          className="confirm"
          backdrop={'static'}
          size={'lg'}
          centered={true}
        >
          <div className="confirm-title-header">
            <h3 className="title btn-primary btn w-50">Business Fundamentals</h3>
          </div>
          <div className="alert text-center">
            {/* (<b>{uInfo().name}</b>) */}
            <h5>Welcome to the Pathway to Profit!</h5>
            <p>
              To get started, please first begin inputting the business fundamentals about your client's company.
              Make sure they have their information ready to input for past revenue numbers,
              details about their typical customer, and their strategic outlook for their future.
            </p>
            <br />
            <p>The onboarding phase only takes a few minutes and when you've finished,
              you may begin building your client's Pathway to Profit with specific and actionable
              strategies aimed at increasing their revenue. </p>
            <br />
            <h6>Ready to get started?</h6>
            <button onClick={() => welcomeAccepted()} className="btn btn-success mt-3 w-50">Start</button>

          </div>

        </Modal>

        <Modal
          isOpen={proposalPreviewModal}
          fade={true}
          className="confirm"
          backdrop={'static'}
          size={'xl'}
          centered={true}
        >
          <div className="confirm-title-header">
            <h3 className="title btn-primary btn w-50">Proposal</h3>
            <div className="text-align-right">
              <button onClick={() => {
                setProposalPreviewModal(false); setProposals({
                  p_type: '',
                  default: [],
                  custom: [],
                  revenueProducts: [],
                  proposal_id: 0
                })
              }} className="btn btn-primary btn-sm m-1" >X </button>
            </div>
          </div>
          <div className="card card-body">

            <div className="form-group mb-3">
              <label>Select Type</label>
              <select name="p_type" className="form-control" onChange={(e) => {
                getProposal(e.target.value, 'default');
                setProposals((prevState) => ({
                  ...prevState,
                  product_id: 0,
                  proposal_id: 0,
                  payment_type: '',
                  product_interval: '',
                  description: '',
                  invoice_type: 'product',
                  total_price: 0,
                  p_type: ''
                }));
                setItemArray([]);
              }}>
                <option value={''}>Select Type</option>
                <option value={'roadmap'}>Send Roadmap</option>
                {/* {
                  assessment?.assessment?.client_sub_type == 1 && ( */}
                <option value={'coaching'}>Assign Product</option>
                {/* )
                } */}
              </select>
            </div>


            {
              proposal.p_type == 'coaching' && (
                <>
                  <form method="POST" onSubmit={handleSubmitAssignProduct} encType="multipart/form-data">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group mb-3">
                          <label>Select Product</label>
                          <select required name="product_id" className="form-control" onChange={(e) => {
                            if (e.target.value != '0') {

                              const row = proposal?.revenueProducts.filter(item => item.product_id == e.target.value);

                              let itemsArray = [
                                { items: `${row[0].name}`, quantity: "1", price: `${row[0].price}`, total: `${row[0].price}`, readOnly: '0' },
                                { items: `One time Setup Fee`, quantity: "1", price: `${row[0].setup_fee}`, total: `${row[0].setup_fee}`, readOnly: '0' },
                              ];

                              const total_price = itemsArray.reduce((total, item) => total + parseFloat(item.total || 0), 0);


                              setItemArray(itemsArray);

                              getProposal(proposal.p_type, 'custom', e.target.value)


                              setProposals((prevState) => ({
                                ...prevState,
                                product_id: e.target.value,
                                payment_type: row[0].payment_type,
                                product_interval: row[0].product_interval,
                                description: row[0].description,
                                invoice_type: 'product',
                                total_price: total_price


                              }));


                            } else {
                              setProposals((prevState) => ({
                                ...prevState,
                                product_id: 0,
                                payment_type: '',
                                product_interval: '',
                                description: '',
                                total_price: 0
                              }));
                              setItemArray([]);
                            }


                          }}>
                            <option value={''}>Select Product</option>
                            {proposal?.revenueProducts.map((row, i) => {
                              return <option value={row.product_id}>{row.name}</option>
                            })}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group mb-3">
                          <label>Select Proposal</label>
                          <select required name="proposal_id" className="form-control" onChange={(e) => {
                            if (e.target.value != '0') {

                              const row = proposal?.custom.filter(item => item.proposal_id == e.target.value);

                              let itemsArray = [
                                { items: `${row[0].name}`, quantity: "1", price: `${row[0].price}`, total: `${row[0].price}`, readOnly: '0' },
                                { items: `One time Setup Fee`, quantity: "1", price: `${row[0].setup_price}`, total: `${row[0].setup_price}`, readOnly: '0' },
                              ];

                              const total_price = itemsArray.reduce((total, item) => total + parseFloat(item.total || 0), 0);

                              setItemArray(itemsArray);


                              setProposals((prevState) => ({
                                ...prevState,
                                description: row[0].description,
                                invoice_type: 'product',
                                total_price: total_price,
                                proposal_id: e.target.value
                              }));

                            } else {
                              setProposals((prevState) => ({
                                ...prevState,
                                proposal_id: 0
                              }));
                            }
                          }}>
                            <option value={''}>Select Proposal</option>
                            <optgroup label="Default Proposal">
                              {
                                proposal?.custom.map((row, i) => {
                                  if (row.sub_type == 'Proposal') {
                                    return (
                                      <option value={row.proposal_id}
                                        className="form-check pt-3" key={i}>
                                        {`${row.name} | (${row.price}) ---`}
                                      </option>
                                    );
                                  }
                                })
                              }
                            </optgroup>
                            <optgroup label="Assessment Proposal">
                              {
                                proposal?.custom.map((row, i) => {
                                  if (row.sub_type == 'AssessmentProposal') {
                                    return (
                                      <option value={row.proposal_id}
                                        className="form-check pt-3" key={i}>
                                        {`${row.name} | (${row.price})`}
                                      </option>
                                    );
                                  }
                                })
                              }
                            </optgroup>
                          </select>
                        </div>
                      </div>
                    </div>


                    <table className="table table-stripped">

                      <thead>
                        <tr>
                          <th>Product Description</th>
                          <th>Product Price</th>
                        </tr>
                      </thead>
                      <tbody>
                        {itemsArray.map((item, index) => (
                          <tr>

                            <td>
                              <div className="form mb-3">
                                <div class="group-addnon">

                                  <div class="form-group">
                                    <input placeholder="Product Title" onChange={(e) => handleChange(e, index, 'items')}
                                      required="" type="text" class="form-control px-1" value={item.items} />
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>

                              <div className="form">
                                <div class="group-addnon">
                                  <div class="input-group" style={{ gridTemplateColumns: `60px auto` }}><div class="input-group-prepend">
                                    <span class="input-group-text" id="validationTooltipUsernamePrepend">{currency}</span>
                                  </div>
                                    <div class="form-group">
                                      <input placeholder="Product Price" onChange={(e) => handleChange(e, index, 'total')} pattern="\d+"
                                        required="" type="number" min="0" class="form-control px-1" value={item.total} />
                                    </div>
                                  </div>
                                </div>

                              </div>

                            </td>
                            <td>
                              {
                                index == 1 && (
                                  <button type="button" className="btn btn-md btn-danger" onClick={() => {
                                    const itemsArray1 = itemsArray.filter((_, i) => i !== 1);

                                    const sum = itemsArray1.reduce((total, item) => total + parseFloat(item.total || 0), 0);
                                    setProposals((prevState) => ({
                                      ...prevState,
                                      total_price: sum
                                    }));
                                    // Update the state with the new array
                                    setItemArray(itemsArray1);
                                  }}><i className="fas fa-trash"></i></button>
                                )
                              }
                            </td>
                          </tr>
                        ))}
                      </tbody>
                      <tfoot>
                        <tr>
                          <th></th>
                          <th><h5 className="mt-1 text-align-right">Total Price: {currency}{NumberWithCommas(Math.round(proposal.total_price))}</h5></th>
                        </tr>
                      </tfoot>
                    </table>

                    {
                      proposal?.proposal_id != '0' && (
                        <div className="preview_proposal">
                          {/* <th><h5 className="mt-1 text-align-right">Total Price: {currency}{proposal.total_price}</h5></th>
                          <th><h5 className="mt-1 text-align-right">Total Price: {currency}{proposal.total_price}</h5></th>
                          <th><h5 className="mt-1 text-align-right">Total Price: {currency}{proposal.total_price}</h5></th> */}
                          <iframe width={'100%'} height={'300px'} src={`/proposal/${bcrypt(proposal?.proposal_id)}?c=${bcrypt(assessment.assessment?.client_id)}&a=${bcrypt(assessment.assessment?.assessment_id)}&u=${bcrypt(uInfo().user_id)}&t=p`}></iframe>
                        </div>
                      )
                    }

                    <div className="btns text-align-right">
                      {
                        (proposal?.product_id != 0 && proposal?.proposal_id != 0) ? (
                          <button className="btn btn-primary btn-sm " >Assign Product</button>
                        ) : (
                          <button type="button" disabled className="btn btn-primary btn-sm" >Assign Product </button>
                        )
                      }

                      <button type="button" onClick={() => {
                        setProposalPreviewModal(false); setProposals({
                          p_type: '',
                          default: [],
                          custom: [],
                          revenueProducts: [],
                          proposal_id: 0
                        })
                      }} className="btn btn-danger btn-sm ml-3" >Close </button>

                    </div>
                  </form>
                </>
              )
            }

            {
              proposal.p_type == 'roadmap' && (
                <>
                  <div className="form-group mb-3">
                    <label>Select Proposal</label>
                    <select name="proposal_id" className="form-control" onChange={(e) => {
                      if (e.target.value != '0') {
                        setProposals((prevState) => ({
                          ...prevState,
                          proposal_id: e.target.value
                        }));
                      } else {
                        setProposals((prevState) => ({
                          ...prevState,
                          proposal_id: 0
                        }));
                      }
                    }}>
                      <option value={''}>Select Proposal</option>
                      {
                        proposal?.custom.map((row, i) => {
                          return (
                            <option value={row.proposal_id}
                              className="form-check pt-3" key={i}>
                              {`${row.name}`}
                            </option>
                          );
                        })
                      }
                    </select>
                  </div>

                  {
                    proposal?.proposal_id != '0' && (
                      <div className="preview_proposal">
                        <iframe width={'100%'} height={'350px'} src={`/proposal/${bcrypt(proposal?.proposal_id)}?c=${bcrypt(assessment.assessment?.client_id)}&a=${bcrypt(assessment.assessment?.assessment_id)}&u=${bcrypt(uInfo().user_id)}&t=p`}></iframe>
                      </div>
                    )
                  }
                  <hr />

                  <div className="btns text-align-right">
                    {
                      proposal?.proposal_id != 0 ? (
                        <button onClick={() => sendAssessmentProposal(proposal?.proposal_id, assessment.assessment?.client_id, assessment.assessment?.assessment_id, uInfo().user_id, 'p')} className="btn btn-primary btn-sm " title="Share the proposal">Share Proposal</button>
                      ) : (
                        <button disabled className="btn btn-primary btn-sm" >Share Proposal </button>
                      )
                    }

                    <button onClick={() => {
                      setProposalPreviewModal(false); setProposals({
                        p_type: '',
                        default: [],
                        custom: [],
                        revenueProducts: [],
                        proposal_id: 0
                      })
                    }} className="btn btn-danger btn-sm ml-3" >Close </button>

                  </div>


                </>
              )
            }
          </div>

        </Modal>
      </Row >
    </React.Fragment >
  );
};

export default Assesments;