import React, { Component } from "react";
import { Row, Col, Button, ModalBody, ModalFooter } from "reactstrap";
import { ApiUrl, ProductName } from "../../config";
import swal from "sweetalert";
import { Link } from "react-router-dom";
import { uToken, dcrypt } from "../../useToken";
import ProfileSettings from "../Authentication/profile-sidebar";
import Froala from "../Froala";
import { toast, Flip } from "react-toastify";

export default class EditCmsPages extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.match.params.id ? dcrypt(this.props.match.params.id) : '',
            records: [],
            loader: true,
            row: {
                page_name: '',
                page_title: '',
                slug: '',
                body: '',
            }
        };
    }

    componentDidMount() {
        this.setState({ isMounted: true });
        if (this.state.id) {
            this.fetchRecords();
        }
    
    }
   
    componentWillUnmount() {
        this.setState({ isMounted: false });
    }

    fetchRecords = () => {
        fetch(`${ApiUrl}get/single/page`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${uToken()}`
            },
            body: JSON.stringify({ id: this.state.id }),
        })
            .then(response => response.json())
            .then(response => {
                if (response.status === true) {
                    this.setState({
                        records: response.data.records,
                    });
                } else {
                    swal({
                        title: response.message,
                        icon: "warning",
                    });
                }
                this.setState({ loader: false });
            })
            .catch(error => {
                console.error('Error:', error);
                this.setState({ loader: false });
            });
    }


    

    updatePage = (event) => {
        event.preventDefault();

        const formData = new FormData(event.target);
    
        fetch(`${ApiUrl}update/cms/proglobal-pages`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${uToken()}`
            },
            body: formData
        })
            .then(response => response.json())
            .then(data => {
                if (data.status === true) {
                    this.setState({
                        row: {
                            id: '',
                            page_name: '',
                            page_title: '',
                            slug: '',
                            body: '',
                        },
                    });
                    this.fetchRecords();
                    this.props.history.push('/settings/proglobal/pages');
                } else {
                    toast.error(data.message || "Failed to update page", { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
                this.setState({ loader: false });
            })
            .catch(error => {
                this.setState({ loader: false });
                console.error("Update error:", error);
                toast.error("Failed to update page. Please try again later.", { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
            });
    };




    render() {
        return (
            <React.Fragment>
                <Row>
                    <Col lg="2" className="pl-0">
                        <ProfileSettings menuOpen={false} props={this.props} />
                    </Col>
                    <Col lg="10">
                        <Row>
                            <Col sm={6}>
                                <div className="page-title-box">
                        
                                    <ol className="breadcrumb m-0">
                                        <li key={0} className="breadcrumb-item active">{ProductName}</li>
                                        <li key={1} className="breadcrumb-item"><Link to="#">{this.state.page_name}</Link></li>
                                    </ol>
                                </div>
                            </Col>
                        </Row>

                        <form onSubmit={this.updatePage} encType="multipart/form-data" className="form-horizontal" id="selection-myform-blog">
                            <ModalBody>
                                <div className="form-group mb-3">
                                    <label>Page Name</label>
                                    <input
                                        name="page_name"
                                        defaultValue={this.state.records.page_name}
                                        className="form-control"
                                        placeholder="Enter name"
                                        required
                                    />
                                   
                                </div>

                                <div className="form-group mb-3">
                                    <label>Page Title</label>
                                    <input
                                        name="page_title"
                                        defaultValue={this.state.records.page_title}
                                        className="form-control"
                                        placeholder="Enter title"
                                        required
                                    />

                                    <input
                                        name="id"
                                        type='hidden'
                                        value={this.state.records.id}
                                    />
                                </div>
                               

                                <fieldset>
                                        <Col md="12">
                                            <div className="form-group mb-3">
                                                <label>Meta keywords</label>
                                                <input
                                                    name="meta_keywords"
                                                    defaultValue={this.state.records.meta_keywords}
                                                    className="form-control"
                                                    placeholder="E.g blog, seo, etc"
                                                />
                                            </div>
                                        </Col>


                                        <Col md="12">
                                            <div className="form-group mb-3">
                                                <label>Meta Description</label>
                                                <textarea
                                                    defaultValue={this.state.records.meta_description}
                                                    name="meta_description"
                                                    className="form-control"
                                                    placeholder="Enter meta description"
                                                    rows={4}
                                        
                                                />
                                            </div>
                                        </Col>
                                    </fieldset>

                                <div className="form-group mb-3">
                                    <label>Page Detail</label>
                                    <Froala content={this.state.records.body ?? ''} nameKey={'body'} insideForm={true} btnId={'submit-blog'} handleSubmit={() => console.log('hi')} use_token={'users'} />
                                   

                                </div>
                            </ModalBody>

                            <ModalFooter>
                                <Button
                                    color="info"
                                    type="submit"
                                    id="submit-blog"
                                >
                                    Submit
                                </Button>
                                <Button
                                    color="danger"
                                    type="button"
                                >
                                    Cancel
                                </Button>
                            </ModalFooter>
                        </form>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}
