import React, { useState, useEffect, useRef } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  CardHeader,
} from "reactstrap";
import Helmet from "react-helmet";
import { Link, Redirect } from "react-router-dom";
import Phone from "../../InitiateCall/Phone";
import {
  ApiUrl,
  AttachementsUrl,
  HostUrl,
  ProductName,
} from "../../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import { bcrypt, convertPhoneFormat, convertPhoneWithCountryCodeFormat, dcrypt, isObjectEmpty, uInfo, uToken } from "../../../useToken";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Badge from "react-bootstrap/Badge";
import "audio-react-recorder/dist/index.css";
import AudioReactRecorder, { RecordState } from "audio-react-recorder";
import {
  AvForm,
  AvField,
  AvGroup,
  AvFeedback,
  AvInput,
} from "availity-reactstrap-validation";
import AvRadioGroup from "availity-reactstrap-validation/lib/AvRadioGroup";
import AvRadio from "availity-reactstrap-validation/lib/AvRadio";

import Select from "react-select";
import { Accordion, Dropdown, Tab, Tabs, Tooltip } from "react-bootstrap";
import Avatar from "react-avatar";
// actions
import CreateUpdate from "../Clients/ClientCreateUpdate.js";
import CreateTask from "../../Tasks/create.js";
import CompanyCreateUpdate from "../Companies/CreateUpdate.js";
import ClientContract from "../../Contract/clientContract.js";
import CreateAssessment from "../../Assessment/create.js";
import EditAssessment from "../../Assessment/update.js";
import $ from "jquery";
import { useHistory } from "react-router-dom";
import Loader from "react-loader";
import swal from 'sweetalert';
import {
  Asia,
  Africa,
  America,
  Antartica,
  Atlantic,
  Australia,
  Europe,
  Indian,
  Pacific,
} from "../../../common/data/timezones";
import CalanderView from "../../Dashboard/calanderView";
import Cog from "../../../assets/images/profile-icons/gear-icon.png";
import UpdateCredential from "../Clients/UpdateCredential";
import WayTrainingAssign from "../Clients/WayTrainingAssign";
import CallWidget from "../Clients/CallWidget";
import moment from 'moment';
import { currencies } from "../../../common/Common-Currency.js";
import SunEditor, { buttonList } from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";

const LeadDetails = (props) => {
  const [page_name] = useState("Lead Dialer");
  const [idx] = useState(dcrypt(props.match.params.id));
  const [coach_id] = useState(dcrypt(props.match.params.coach_id));
  const [info, setInfo] = useState({});
  const [website, setWebsite] = useState("");
  const [websiteUrl, setWebsiteUrl] = useState("");
  const [assignCallStatus, setAssignCallStatus] = useState({});
  const [tab, setTab] = useState("3");
  const [btab, setBTab] = useState("3");
  const [groupModal, setGroupModal] = useState(false);
  const [tagModal, setTagModal] = useState(false);
  const [loginHistory, setLoginHistory] = useState([]);
  const [loginHistoryColumn, setLoginHistoryColumn] = useState([]);
  const [index, setIndex] = useState("");
  const [tags, setTags] = useState([]);
  const [alltags, setAllTags] = useState([]);
  const [groups, setGroups] = useState([]);
  const [tag, setTag] = useState("");
  const [selectedValue, setSelectedValue] = useState([]);
  const [style, setStyle] = useState({ display: "none" });
  const [page2Class, setpage2Class] = useState("");
  const [page3Class, setpage3Class] = useState("");
  const [page4Class, setpage4Class] = useState("");
  const [page5Class, setpage5Class] = useState("");
  const [page6Class, setpage6Class] = useState("");
  const [page7Class, setpage7Class] = useState("");
  const [page8Class, setpage8Class] = useState();
  const [page9Class, setpage9Class] = useState("");
  const [page10Class, setpage10Class] = useState("");
  const [page11Class, setpage11Class] = useState("");
  const [page12Class, setpage12Class] = useState("");
  const [page13Class, setpage13Class] = useState("");
  const [callToken, setCallToken] = useState(null);
  const [searchClient, setSearchClient] = useState("");
  const [existing, setExisting] = useState([]);
  const [clients, setClients] = useState([]);
  const [DynamicModal, setDynamicModal] = useState(false);
  const [EmailModal, setEmailModal] = useState(false);
  const [DynamicModalName, setDynamicModalName] = useState("");
  const [activity, setActivity] = useState([]);
  const [scripts, setScripts] = useState([]);
  const [script, setScript] = useState("");
  const [templates, setSmsTemplates] = useState([]);
  const [clicked, setClicked] = useState(false);
  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const [emailSetting, setEmailSetting] = useState([]);
  const [tokens, setTokens] = useState([]);
  const [smsBody, setSmsBody] = useState("");
  const [token, setToken] = useState("");
  const [subject, setSubject] = useState("");
  const [html, setHtml] = useState("");
  const [calendarReload, setCalendarReload] = useState(true);
  const [sentEmailTemplateModal, setSentEmailTemplateModal] = useState(false);
  const [activityData, setActivityData] = useState({});
  const [emailData, setEmailData] = useState("");
  const [taskCompleteModal, setTaskCompleteModal] = useState(false);
  const [taskID, setTaskID] = useState(false);
  const [attachment, setAttachment] = useState({});
  const [pipeline, setPipeline] = useState({});
  const [pipelineStatus, setPipelineStatus] = useState([]);
  const [masterPipelines, setMasterPipelines] = useState([]);
  const [masterPipelineId, setMasterPipelineId] = useState(0);

  const [todoLists, setTodoLists] = useState([]);
  const [todoModal, setTodoModal] = useState(false);
  const [selectedPipeline, setSelectedPipeline] = useState({});
  const [pipelineModal, setPipelineModal] = useState(false);
  const [coachPartners, setCoachPartners] = useState([]);
  // const [coachClients, setCoachClients] = useState([]);
  const [coachUser, setCoachUser] = useState({});
  const [taskModal, setTaskModal] = useState(false);
  const [taskData, setTaskData] = useState({});
  const [eventData, setEventData] = useState({});
  const [month_year, setMonthYear] = useState(new moment().format("YYYY-MM"));
  const [UpPermission, setUpPermission] = useState({
    permission: 0,
    remaining_coach: 0,
    packages: []
  });
  const [invoices, setInvoices] = useState([]);
  const [defaultCalendar, setDefaultCalendar] = useState(0);
  const identity = "proglobal";
  const childRef = useRef();
  const [phoneno, setphoneno] = useState(null);
  const [attachmentFile, setattachmentFile] = useState("");
  const [assessment_id, setAssessmentId] = useState("");
  const [training, setTraining] = useState({
    userTraining: [],
    allTraining: [],
    userResource: [],
    allResource: [],
    subCategories: []
  });

  const [loader, setLoader] = useState(false);
  const [smsloader, setSmsLoader] = useState(true);
  const [timeZone_data, setnewzone] = useState(America);
  const [timezone, setTimeZone] = useState("");
  const [maintimezone, setMainTimeZone] = useState("");
  const [reminderModal, setReminderModal] = useState(false);
  const [reminders, setReminders] = useState([]);
  const [template_id, setTemplateId] = useState(0);
  const [toggleSwitch, settoggleSwitch] = useState(true);
  const [notes, setNotes] = useState([]);

  const [recordState, setRecordState] = useState(null);
  const [selected_file, SetSelectedFile] = useState("");
  const [selected_mms_custom, SetSelectedMmsCustom] = useState("");
  const [selected_mms_default, SetSelectedMmsDefault] = useState("");
  const [file_type_validate, SetFileTypeValidate] = useState("");
  const [file_type, setFileType] = useState("template-section");
  const [audioData, setAudioData] = useState("");
  const [clearFile, setClearFile] = useState(true);
  const [sendNotes, setSendNote] = useState(false);
  const [noteType, setNoteType] = useState('manual');

  const [verifiedFromEmail, setVerfiedFromEmail] = useState([]);
  const [verifiedFromEmailId, setVerfiedFromEmailId] = useState("");
  const [verifiedFromName, setVerfiedFromName] = useState("");
  const [defaultFromEmail, SetDefaultFromEmail] = useState("");
  const [email_body, setEmailBody] = useState("");
  // console.log("verifiedEmail", verifiedFromEmail);


  const [points_contact, setPointsContact] = useState({
    calendar_events: 0,
    call: 0,
    email: 0,
    sms: 0,
    total: 0
  });

  const [inbox_activity, setInboxActivity] = useState({
    report_results: [],
    sms_count: 0,
    email_count: 0,
    call_count: 0,
    voice_count: 0,
    rvm_count: 0,
    voice_message_un_listen: 0
  });

  const [task_record, setTaskRecord] = useState({
    task_log: [],
    today: 0,
    weekly: 0,
    monthly: 0,
    unassigned: 0
  });
  const [inboxDay, setInboxDay] = useState("today");
  let this_form = useRef(null);
  const paramsters = {
    token: callToken,
    from_number: uInfo().outbound_phone,
    phoneno: phoneno,
    record: "false",
    client_id: info.client_id,
    coach_id: coach_id,
    phone: info.phone != '' ? info.country_code + '' + info.phone : '',
    mobile: info.mobile != '' ? info.country_code + '' + info.mobile : '',
  };
  let history = useHistory();
  const childClientRef = useRef();
  useEffect(() => {
    getData();
    //getTag();
    getActivity("3");
    getScripts("4");
    getCompanyClients();
    getTodoList();
    getTag();
    getInboxActivity('all');
    getCoachUsers();
    getTaskStatistics("monthly");
    getClientPipeline();
    getVerifiedFromEmail();

  }, []);
  const changeHandler = (e) => {
    if (e.target.files[0].size < 5036070 && e.target.files[0].type.match('image.*')) {
      let selected_file = [];
      var reader = new FileReader();
      reader.onload = () => {
        selected_file.push(reader.result);
      };
      reader.readAsDataURL(e.target.files[0]);
      SetSelectedMmsCustom(selected_file);
    } else {
      toast('Invalid file', { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });

    }
  };
  const viewLogDetails = (record, type) => {
    if (type == 'email' || type == 'voice' || type == 'call' || type == 'rvm') {
      fetch(`${ApiUrl}` + "get/sub/report/body/" + record.sub_id + '/' + info.client_id, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ` + uToken(),
        },
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.status === true) {
            record.sub_body = response.data.body;
            record.sub_subject = response.data.subject;
            record.media_url = response.data.media_url;

            setActivityData(record);
            setSentEmailTemplateModal(true)

          } else {
            toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      setActivityData(record);
      setSentEmailTemplateModal(true)
    }

  };

  const getNotes = (data = {}, from = '') => {
    fetch(`${ApiUrl}` + "get/activity/" + info.client_id + "/5", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          setNotes(response.data.records)

        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const getCoachUsers = () => {
    fetch(`${ApiUrl}` + "get/coach/users", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          setCoachPartners(response.data.coachPartners);
          // setCoachClients(response.data.coachClients);
          setCoachUser(response.data.coachUser);
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const handleClearFile = () => {
    setClearFile(false);
    setTimeout(() => {
      setClearFile(true);
    }, 100);
  };
  const getInboxActivity = (type = 'all', day = inboxDay) => {
    fetch(`${ApiUrl}` + "get/business/inbox", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ day: day, 'type': type, 'client_id': idx }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          setInboxActivity(response.data.records);
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const getTaskStatistics = (task_interval = 'today', month_year = "") => {
    fetch(`${ApiUrl}` + "dashboard/task/statistics", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ task_interval: task_interval, client_id: idx, month_year: month_year }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          //console.log(response.data.record, 'recccc')
          setTaskRecord(response.data.record);
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const download = (e) => {
    fetch(
      "https://cors-anywhere.herokuapp.com/" +
      AttachementsUrl +
      "user_" +
      coach_id +
      "/attachment/" +
      selected_mms_default,
      {
        method: "GET",
        headers: {},
      }
    )
      .then((response) => {
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const maintimezones = [
    { id: "Asia", label: "Asia" },
    { id: "America", label: "America" },
    { id: "Australia", label: "Australia" },
    { id: "Europe", label: "Europe" },
    { id: "Indian", label: "Indian" },
  ];
  function settimezone(e) {
    setMainTimeZone(e);
    if (e.label == "Australia") {
      setnewzone(Australia);
    } else if (e.label == "America") {
      setnewzone(America);
    } else if (e.label == "Asia") {
      setnewzone(Asia);
    } else if (e.label == "Europe") {
      setnewzone(Europe);
    } else if (e.label == "Indian") {
      setnewzone(Indian);
    }
    setTimeZone({});
    // console.log(timeZone_data,'Zone Change');
  }
  const handleAttachementRequestSubmit = async (event, values) => {
    values.client_id = info.client_id;
    values.email = info.email;
    fetch(`${ApiUrl}` + "send/attachment/request", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((data) => {
        this_form && this_form.reset();
        if (data.status === true) {
          setSubject("");
          setSmsBody("");
          setToken('');
          getActivity(tab);
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const handleAtSubmit = async (event, values) => {
    // values.client_id = info.client_id;
    // values.attachment_file = attachmentFile;
    setLoader(false);
    const formData = new FormData();
    formData.append("client_id", info.client_id);
    formData.append("attachment_file", attachmentFile);
    formData.append("file_title", values.file_title);
    formData.append("type", uInfo().type);
    fetch(`${ApiUrl}` + "client/attachment", {
      method: "POST",
      headers: {
        Authorization: `Bearer ` + uToken(),
      },
      body: formData,
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((data) => {
        console.log(data, "Add new attach");
        if (data.status === true) {
          this_form && this_form.reset();
          getActivity(tab);
          getCompanyClients();
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
        setLoader(true);

      })
      //Then with the error genereted...
      .catch((error) => {
        setLoader(true);

        console.error("Error:", error);
      });
  };

  const handleTodoSubmit = async (event, values) => {
    values.client_id = info.client_id;
    fetch(`${ApiUrl}` + "add/todo", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((data) => {
        if (data.status === true) {
          setTodoModal(false);
          getTodoList();
        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  const getTodoList = () => {
    fetch(`${ApiUrl}` + "get/todo/" + idx, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          setTodoLists(response.data.todoTists);
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const revertBack = () => {
    swal({
      title: "Are you sure?",
      text: "You want to revert back to lead profile.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((confirmation) => {
        if (confirmation) {
          fetch(`${ApiUrl}` + "revertback/client/profile", {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({
              client_id: info.client_id,
              linked_user_id: info.linked_user_id,
            })
          })
            .then((response) => response.json())
            .then((response) => {
              if (response.status === true) {
                getData();
                toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
              } else {
                toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
              }
            })
            .catch((error) => {
              console.error("Error:", error);
            });
        }
      });
  };
  const todoRemove = (id) => {
    // swal({
    //   title: "Are you sure?",
    //   text: "You want to remove this action",
    //   icon: "warning",
    //   buttons: true,
    //   dangerMode: true,
    // })
    //   .then((confirmation) => {
    //     if (confirmation) {
    fetch(`${ApiUrl}` + "todo/delete/" + id, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          getTodoList();
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });

        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
    //   }
    // });
  };

  const getScriptById = (id) => {
    setScript("");
    fetch(`${ApiUrl}` + "get/script/" + id, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ 'client_id': idx }),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          setScript(response.data.record)
        } else {
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  const todoComplete = (id) => {
    // swal({
    //   title: "Are you sure?",
    //   text: "You want to mark complete",
    //   icon: "warning",
    //   buttons: true,
    //   dangerMode: true,
    // })
    //   .then((confirmation) => {
    //     if (confirmation) {
    fetch(`${ApiUrl}` + "todo/complete/" + id, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          getTodoList();
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });

        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
    //   }
    // });
  };



  const getCompanyClients = () => {
    fetch(`${ApiUrl}` + "get/client/information/" + idx + "/" + coach_id, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          setClients(response.data.records);
          setpage3Class("slide-out2");
          setAttachment(response.data.attachments);
          setUpPermission(response.data.coachPermission);
          setTraining(response.data.training);
          var tokens_group = [
            {
              label: "Lead Default Field Tokens",
              options: response.data.tokens.default_fields,
            },
            {
              label: "Lead Custom Field Tokens",
              options: response.data.tokens.custom_fields,
            },
            {
              label: "User Tokens",
              options: response.data.tokens.users_token,
            },
          ];
          setTokens(tokens_group);
          setInvoices(response.data.invoices);
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const getClientPipeline = (master_id = 0) => {
    fetch(`${ApiUrl}` + "get/client/pipeline/" + idx + "/" + coach_id, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ master_id: master_id }),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          setMasterPipelineId(master_id);
          setPipelineStatus(response.data.allPipelines);
          setMasterPipelines(response.data.masterPipelines);
          // if (response.data.allPipelines.length > 0) {
          //   setPipeline(response.data.allPipelines[0]);
          // }
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleExistingClient = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    let companyIds = [];
    for (let [key, value] of formData.entries()) {
      companyIds.push(value);
    }
    if (companyIds.length > 0) {
      fetch(`${ApiUrl}` + "update/company/client", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ` + uToken(),
        },
        body: JSON.stringify({
          clients: info.client_id,
          comapny_id: companyIds[0],
        }),
      })
        .then((response) => response.json())
        //Then with the data from the response in JSON...
        .then((response) => {
          // console.log(response, "data");
          if (response.status === true) {
            setpage3Class("slide-out2");
            setSearchClient("");
            setExisting([]);
            getCompanyClients();
            toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
          } else {


            toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
          }
        })
        //Then with the error genereted...
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };
  const getData = () => {
    fetch(`${ApiUrl}` + "lead/details/" + idx + "/" + coach_id, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          var client_info = response.data.records;
          setInfo(client_info);

          //console.log(response.data.groups_data, 'groups_data')
          var phone_number = client_info.phone
            ? client_info.country_code + client_info.phone
            : client_info.country_code + client_info.mobile;
          setphoneno(phone_number);

          setGroups(response.data.groups_data);
          setTags(response.data.tag_data);
          setAssignCallStatus(response.data.call_status);
          setPointsContact(response.data.points_of_contact);
          getTaskStatistics("monthly");
          if (client_info.website != "" && client_info.website != null) {
            let http_status = client_info.website.replace(/(^\w+:|^)\/\//, '');
            let website_url = "https://" + http_status;
            setWebsiteUrl(website_url);


            let websiteInfo = client_info.website.replace(/(^\w+:|^)\/\//, '');
            setWebsite(websiteInfo);
          }
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const getReminderList = (meeting_id) => {
    setLoader(false);
    fetch(`${ApiUrl}` + "get/meeting/reminder/" + meeting_id, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((data) => {
        if (data.status === true) {
          setReminders(data.data.records);
          setReminderModal(true);
        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
        setLoader(true);
      })
      //Then with the error genereted...
      .catch((error) => {
        setLoader(true);
        console.error("Error:", error);
      });
  }
  const changeMeetingStatus = (meeting_id, status) => {
    swal({
      title: "Are you sure?",
      text: "You want to mark this " + status,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((confirmation) => {
        if (confirmation) {
          fetch(`${ApiUrl}` + "update/status/appointment", {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ 'meeting_id': meeting_id, 'status': status }),
          })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((data) => {
              if (data.status === true) {
                getActivity('12');
              } else {
                toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
              }
            })
            //Then with the error genereted...
            .catch((error) => {
              console.error("Error:", error);
            });
        }
      });
  }

  const btoggle = (index) => {
    if (btab !== index) {
      setBTab(index);
      getActivity(index);
      setActivity([]);
    }
  };

  const toggle = (index) => {
    if (tab !== index) {
      setTab(index);
      getActivity(index);
      setActivity([]);
    }
  };

  const removeGroupLead = () => {
    fetch(
      `${ApiUrl}` + "remove/group/lead/" + idx + "/" + index + "/" + coach_id,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ` + uToken(),
        },
      }
    )
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        // console.log(response, "Group removed");
        if (response.status === true) {
          setGroupModal(false);
          getData();
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const removeTagLead = () => {
    // console.log(index, "index");
    fetch(
      `${ApiUrl}` + "remove/tag/lead/" + idx + "/" + index + "/" + coach_id,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ` + uToken(),
        },
      }
    )
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          // console.log(response);
          setTagModal(false);
          getData();
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const getTag = () => {
    fetch(`${ApiUrl}` + "get/group/tag", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },

      body: JSON.stringify({ manager_id: coach_id }),
    })
      .then((response) => response.json())
      .then((response) => {
        // console.log(response, "data");
        if (response.status === true) {
          setAllTags(response.data.tags);
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const removeGroupModal = (i) => {
    setGroupModal(!groupModal);
    setIndex(i);
  };
  const removeTagModal = (i) => {
    setTagModal(!tagModal);
    setIndex(i);
  };
  const generateClientLogin = () => {
    setLoader(false);
    fetch(`${ApiUrl}` + "generate/client/login", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ client_id: info.client_id, coach_id: info.coach_id }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          getData();
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
        setLoader(true);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const sendLoginDetails = async (event, values) => {
    setLoader(false);
    values.client_id = info.client_id;
    values.coach_id = info.coach_id;
    fetch(`${ApiUrl}` + "send/client/login", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
        setLoader(true);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const generateMetting = async () => {
    setLoader(false);
    let data = {
      client_id: info.client_id,
      client_email: info.email,
      email: uInfo().email,
      body: "An instant meeting  request sent  to ({client_name}) {link}",
    };
    // console.log(index, "index");
    fetch(`${ApiUrl}` + "client/meeting/create", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          let row = response.data.records;

          history.push({
            pathname: "/view/meeting",
            search: `?u=${bcrypt(row.client_id)}&c_id=${bcrypt(
              row.coach_id
            )}&meta=${bcrypt(row.meeting_id)}`,
            state: { from: true },
          });

          // getActivity(tab);
          // toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
        setLoader(true);
      })
      //Then with the error genereted...
      .catch((error) => {
        setLoader(true);

        console.error("Error:", error);
      });
  };
  const AddTags = async (event, values) => {
    setSmsLoader(false);
    // console.log(index, "index");
    fetch(`${ApiUrl}` + "add/tags/lead/" + idx + "/" + coach_id, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          setTag(0)
          setGroupModal(false);
          setTagModal(false);
          getData();
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
        setSmsLoader(true);
      })
      //Then with the error genereted...
      .catch((error) => {
        setSmsLoader(true);

        console.error("Error:", error);
      });
  };
  const handleChange = (e) => {
    setSelectedValue(Array.isArray(e) ? e.map((x) => x.id) : []);
  };
  useEffect(() => {
    if (searchClient != "") {
      fetch(`${ApiUrl}` + "get/companies", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ` + uToken(),
        },
        body: JSON.stringify({ filter_value: searchClient }),
      })
        .then((response) => response.json())
        //Then with the data from the response in JSON...
        .then((response) => {
          // console.log(response, "data");
          if (response.status === true) {
            setExisting(response.data.records);
            // this.setState({
            //   records: response.data.records,
            // });
          } else {


            toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
          }
        })
        //Then with the error genereted...
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      setExisting([]);
    }
  }, [searchClient]);

  const handleSubmitSmsSend = async (event, values) => {
    values.client_id = info.client_id;
    values.type = 'sms';
    values.body = smsBody;
    setLoader(false);
    setSmsLoader(false);
    fetch(`${ApiUrl}` + "lead/activity", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((data) => {
        if (data.status === true) {

          getInboxActivity('sms', 'today');
          //getActivity("8");
          setDynamicModal(false);
          setDynamicModalName("sms");
          setSmsBody("");
          SetSelectedMmsCustom("");
          SetSelectedMmsDefault("");
          setToken("");
          setSubject("");
          setHtml("");
          setTemplateId(0);
        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
        setLoader(true);
        setSmsLoader(true);
      })
      //Then with the error genereted...
      .catch((error) => {
        setLoader(true);
        setSmsLoader(true);

        console.error("Error:", error);
      });
  }
  const handleTaskSubmit = async (event, values) => {
    setCalendarReload(false);
    values.client_id = info.client_id;
    fetch(`${ApiUrl}` + "add/task/activity", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((data) => {
        if (data.status === true) {
          setTaskModal(false);
          getTaskStatistics("monthly");
          setCalendarReload(true);
          setTaskData({});
        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  }
  const handleSubmit = async (event, values) => {
    setCalendarReload(false);
    values.client_id = info.client_id;
    values.type = DynamicModalName.toLowerCase();
    values.body = smsBody;
    values.selected_mms_default = selected_mms_default;
    values.selected_mms_custom = selected_mms_custom;
    values.media_file = selected_file;
    values.file_type_validate = file_type_validate;
    setLoader(false);
    fetch(`${ApiUrl}` + "lead/activity", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((data) => {
        setCalendarReload(true);
        if (data.status === true) {

          if (values.type == "note") {
            setSendNote(false);
            getNotes({ 'note_type': 'manual' }, 'notes');
          } else {
            if (tab == "5") {
              getActivity("5");
            } else if (tab == "12") {
              getActivity("12");
            } else {
              getActivity("3");
            }
            setDynamicModal(false);
          }
          setSmsBody("");

          setToken("");
          setSubject("");
          setHtml("");
          setTemplateId(0);
          setEventData({});
          setScript("");
          getInboxActivity('all', 'today');
        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
        setLoader(true);
      })
      //Then with the error genereted...
      .catch((error) => {
        setLoader(true);
        console.error("Error:", error);
      });
  };
  const handleDealSubmit = async (pipeline_id, status_id, client_id, final_pipeline) => {
    fetch(`${ApiUrl}` + "assinged_pipeline", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({
        'pipeline_id': pipeline_id,
        'status_id': status_id,
        'client_id': client_id,
        'final_pipeline': final_pipeline
      }),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((data) => {
        console.log(data, "Editing");
        if (data.status === true) {
          getClientPipeline(masterPipelineId);
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const handleEmailBody = async (event, values) => {
    console.log(values, 'valuesvalues')
    setEmailBody(values);
  }
  const handleEmailSubmit = async (event, values) => {
    event.preventDefault();

    values.client_id = info.client_id;
    values.type = DynamicModalName.toLowerCase();
    values.from_name = verifiedFromName;
    values.from_email_id = verifiedFromEmailId;

    if (html != "") {
      let body = document.getElementById("custom-template").innerHTML;
      values.body = body;
    }else{
      values.body = smsBody;
    }


    fetch(`${ApiUrl}` + "company/email/activity", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((data) => {

        if (data.status === true) {
          //getActivity(tab);
          setEmailModal(false);
          setDynamicModalName("");
          setHtml('');
          setSmsBody("");
          SetSelectedMmsCustom("");
          SetSelectedMmsDefault("");
          setToken("");
          setSubject("");
          setVerfiedFromEmailId("");
          setVerfiedFromName("");
          getInboxActivity('email', 'today');

          toast(data.message, { hideProgressBar: false, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });

        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const getCallSid = (call_sid) => {

  }


  const getScripts = (index) => {
    fetch(`${ApiUrl}` + "get/activity/" + idx + "/" + index, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          setScripts(response.data.records);
        } else {
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {

        console.error("Error:", error);
      });
  };

  const getActivity = (index) => {
    setLoader(false);
    fetch(`${ApiUrl}` + "get/activity/" + idx + "/" + index, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          setBTab(index);
          setActivity(response.data.records);
          setDefaultCalendar(response.data.calendarId);
          // setpage3Class('slide-out2');


          setDynamicModalName("");

          setHtml('');
          setSmsBody("");
          SetSelectedMmsCustom("");
          SetSelectedMmsDefault("");
          setToken("");
          setSubject("");

          setLoader(true);

        } else {
          setLoader(true);



          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        setLoader(true);

        console.error("Error:", error);
      });
  };

  function groupArrayOfObjects(list, key) {
    return list.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  }

  const getEmailTemplate = () => {
    fetch(`${ApiUrl}` + "get/email/template", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          setSmsTemplates(response.data.templates);
          var tokens_group = [
            {
              label: "Lead Default Field Tokens",
              options: response.data.tokens.default_fields,
            },
            {
              label: "Lead Custom Field Tokens",
              options: response.data.tokens.custom_fields,
            },
            {
              label: "User Tokens",
              options: response.data.tokens.users_token,
            },
          ];
          setTokens(tokens_group);
          setEmailSetting(response.data.phoneNumbers);
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };




  const getVerifiedFromEmail = () => {
    fetch(`${ApiUrl}` + "verify/email/list", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          setVerfiedFromEmail(response.data.records);
          var verify_rec = response.data.records && response.data.records.filter(item => item.is_default === 1);
          console.log(verify_rec, 'verify_rec')
          SetDefaultFromEmail(verify_rec);
          setVerfiedFromEmailId(verify_rec && verify_rec[0].id);
        } else {
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });

  };




  const getSmsTemplate = () => {
    fetch(`${ApiUrl}` + "get/sms/template", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          var templates = groupArrayOfObjects(response.data.templates, "type");
          var templates_group = [
            { label: "SMS", options: templates.SMS },
            { label: "MMS", options: templates.MMS },
          ];
          setSmsTemplates(templates_group);

          var tokens_group = [
            {
              label: "Lead Default Field Tokens",
              options: response.data.tokens.default_fields,
            },
            {
              label: "Lead Custom Field Tokens",
              options: response.data.tokens.custom_fields,
            },
            {
              label: "User Tokens",
              options: response.data.tokens.users_token,
            },
          ];

          setTokens(tokens_group);
          setPhoneNumbers(response.data.phoneNumbers);
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  const getRVMTemplate = () => {
    fetch(`${ApiUrl}` + "get/rvm/template", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          setSmsTemplates(response.data.templates);

          setPhoneNumbers(response.data.phoneNumbers);
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const getEmailTemplateHtml = (id) => {
    let coach_id = uInfo().type == 'CoachPartner' ? uInfo().coach_id : uInfo().user_id;
    fetch(`${ApiUrl}` + "get/html/" + id + "/" + coach_id, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        setHtml(response.theme);
        // localStorage.setItem("theme", response.theme);
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const cancelTask = () => {
    setpage4Class("slide-out2");
    getActivity("6");
  };
  const cancelAssessment = () => {
    setpage9Class("slide-out2");
    setpage10Class("slide-out2");
    getActivity("13");
  };
  const completeTask = () => {
    fetch(`${ApiUrl}` + "update/task/status", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ task_id: taskID, client_id: info.client_id }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          getActivity("6");
          setTaskCompleteModal(false);
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleOutCall = () => {
    fetch(`${ApiUrl}` + "voice/token", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ identity: encodeURIComponent(identity) }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          setCallToken(response.data.token);
          setClicked(true);
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const removeAttachement = async (id, index) => {
    swal({
      title: "Are you sure?",
      text: "You want to remove this record.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((confirmation) => {
        if (confirmation) {
          const formData = new FormData();
          formData.append("id", id);
          formData.append("index", index);
          formData.append("client_id", info.client_id);
          fetch(`${ApiUrl}` + "remove/attachments", {
            method: "POST",
            headers: {
              Authorization: `Bearer ` + uToken(),
            },
            body: formData,
          })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((data) => {
              if (data.status === true) {
                getActivity(tab);
                getCompanyClients();
                toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });

              } else {
                toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
              }
            })
            //Then with the error genereted...
            .catch((error) => {
              console.error("Error:", error);
            });
        }
      });
  };


  const handleTrainingChange = (selectedTags) => {
    let tags = [];
    selectedTags.map(tag => {
      tags.push(tag.cat_id);
    });
    setTraining(prevTraining => ({
      ...prevTraining,
      userTraining: tags
    }));

    fetch(`${ApiUrl}` + "trainings/subcategories", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ 'cat_id': tags }),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((data) => {
        if (data.status === true) {
          setTraining(prevTraining => ({
            ...prevTraining,
            subCategories: data.data.records
          }));

        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });


  }
  const handleResourceChange = (selectedTags) => {
    let tags = [];
    selectedTags.map(tag => {
      tags.push(tag.cat_id);
    });
    setTraining(prevTraining => ({
      ...prevTraining,
      userResource: tags
    }));
  }




  const assignTraining = async (event, values) => {

    if (training.userTraining.length > 0) {
      let cat_id = training.userTraining.toString();
      let resource_id = training.userResource.toString();



      fetch(`${ApiUrl}` + "assign/training/user/" + info.linked_user_id, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ` + uToken(),
        },
        body: JSON.stringify({ 'cat_id': cat_id, 'resource_id': resource_id, 'user_id': info.linked_user_id, 'client_id': info.client_id }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.status === true) {
            toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });

            setpage11Class("slide-out2");
            getActivity("3");
          } else {
            toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
          }
        })
        //Then with the error genereted...
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }

  const scheduleEvent = (event, custom_date = '', zone = '') => {
    if (event) {
      var obj = { meeting_id: event.meeting_id, calendar_id: event.calendar_id };

      if (custom_date) {
        obj.date = custom_date;
        obj.timezone = zone == '' ? timezone.id : zone;
      } else {
        setTimeZone(event.time_zone1);
        setMainTimeZone(event.time_zone);
        setEventData(event);
        obj.date = event.server_date;
        obj.timezone = event.client_time_zone;
      }

      if (event.meeting_id) {
        fetch(`${ApiUrl}` + "get/meeting/slots", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ` + uToken(),
          },
          body: JSON.stringify(obj),
        })
          .then((response) => response.json())
          //Then with the data from the response in JSON...
          .then((data) => {
            if (data.status === true) {
              setEventData((prevState) => ({
                ...prevState,
                server_date: obj.date,
                time_slots: data.data.slots
              }));
              // setTimeZone(event.time_zone1);
              // setMainTimeZone(event.time_zone);
              //setEventData(event);
              //setEventModal(true);

            } else {
              toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
            }
          })
          //Then with the error genereted...
          .catch((error) => {
            console.error("Error:", error);
          });
      }

    }
    setDynamicModal(true);
    setDynamicModalName("meeting");
  }
  const deleteTaskAndEvents = (meeting_id, status = 'remove') => {
    swal({
      title: "Are you sure?",
      text: status == 'complete' ? "You want to mark complete this record" : "You want to remove this record.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((confirmation) => {
        if (confirmation) {
          setCalendarReload(false);
          fetch(`${ApiUrl}` + "delete/meeting", {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ id: meeting_id, status: status }),
          })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((data) => {
              if (data.status === true) {
                setTaskModal(false);
                setTaskData({});
                getTaskStatistics("monthly");

                setEventData({});
                setDynamicModal(false);
                setDynamicModalName("");
              } else {
                toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
              }
              setCalendarReload(true);
            })
            //Then with the error genereted...
            .catch((error) => {
              setCalendarReload(true);
              console.error("Error:", error);
            });
        }
      });
  };
  const start = () => {
    console.log(RecordState.START, 'DEBUGGINGG');
    setRecordState(RecordState.START);
  };

  const stop = () => {
    setRecordState(RecordState.STOP);
  };
  //audioData contains blob and blobUrl
  const onStop = (audioData) => {
    setAudioData(audioData);
    let selected_file = [];
    console.log("audioData", audioData);
    var reader = new FileReader();
    reader.readAsDataURL(audioData.blob);
    reader.onloadend = function () {
      selected_file.push(reader.result);
    }


    SetSelectedFile(selected_file);
    SetFileTypeValidate("wav");
  };
  const onChangefileTypeAction = (value) => {
    var x = document.getElementById("record-section");
    var y = document.getElementById("template-section");
    if (value == "record-section") {
      setFileType("record-section");
      x.style.display = "block";
      y.style.display = "none";
    } else {
      setFileType("template-section");
      x.style.display = "none";
      y.style.display = "block";
    }
  };
  const getInvoiceCurrency = (code) => {
    let currencysymbol = currencies[code];
    if (currencysymbol) {
      return currencysymbol.symbol;
    }
    return '$';
  }


  return (
    <React.Fragment>
      <Helmet>
        <title> {page_name} </title>
      </Helmet>
      <ToastContainer />
      <Row className="CompanyDetail ClientDialer ClientProfileDetails NewLeadDialer">
        <Col md={3}>
          <div className="NoShadow custom-scroll LeftSideUser">
            <div className="user-profile-pic-area">
              <CardHeader>
                <div className="top-heading WithDrop">
                  <Link className="text-primary" to={"/lead/list"}>
                    <i className="fas fa-angle-left pr-5"> </i>Leads
                  </Link>
                  <p className="text-white">{info.first_name} {info.last_name}</p>
                  <Dropdown className="d-inline d-none">
                    <Dropdown.Toggle
                      className="btn btn-link text-primary"
                      id="dropdown-autoclose-true"
                    >
                      <i className="ion ion-md-flower"> </i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => {
                          toggle("1");
                        }}
                        eventKey={"1"}
                      >
                        Basic Info
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          toggle("2");
                        }}
                        eventKey={"2"}
                      >
                        Add Tag
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </CardHeader>
              <Card className="UserProfileBG">
                <CardBody className="px-0">
                  <div className="company p-3">
                    {
                      info.client_id > 0 && (
                        <button
                          className="btn btn-link btn-md pull-right"
                          onClick={() => {
                            setpage2Class("slide-in");
                            childRef.current.getAlert();
                          }}
                        >
                          <i className="fas fa-pencil-alt"> </i>
                        </button>
                      )
                    }
                    <div className="company-info">
                      {
                        info.coach_login == 'yes' ? (
                          <div className="OnlineOffline">
                            <span className="OnlineIcon"></span>
                            Online
                          </div>
                        ) : (
                          <div className="OnlineOffline">
                            <span className="OfflineIcon OnlineIcon"></span>
                            Offline
                          </div>
                        )
                      }
                      <div className="logo">
                        <Avatar
                          name={`${info.first_name} ${info.last_name}`}
                          size="76"
                          round={true}
                        />
                      </div>
                      <div className="info">
                        <h3></h3>
                        {website != "" && website != null && (
                          <div className="url">
                            <a
                              href={`${websiteUrl}`}
                              target="_blank"
                              className="pr-5"
                            >
                              {website}

                              <i className="fas fa-external-link-alt"> </i>
                            </a>
                            <OverlayTrigger
                              placement="right"
                              delay={{ show: 250, hide: 400 }}
                              overlay={
                                <Tooltip id="overlay-example">
                                  Copy domain to clipboard
                                </Tooltip>
                              }
                            >
                              <button
                                className="btn btn-link btn-md"
                                onClick={() => {
                                  navigator.clipboard.writeText(`${website}`);
                                }}
                              >
                                <i className="fas fa-copy"> </i>
                              </button>
                            </OverlayTrigger>
                          </div>
                        )}

                        {info.phone != "" && info.mobile != "" && info.phone != null && info.mobile != null && (
                          <>
                            <Link to={"#"}>

                              {
                                info.phone ?
                                  convertPhoneWithCountryCodeFormat(info.country_code, info.phone)
                                  :
                                  convertPhoneWithCountryCodeFormat(info.country_code, info.mobile)
                              }


                            </Link>
                            <OverlayTrigger
                              placement="right"
                              delay={{ show: 250, hide: 400 }}
                              overlay={
                                <Tooltip id="overlay-example">
                                  Copy phone number to clipboard assad
                                </Tooltip>
                              }
                            >
                              <button
                                onClick={() => {
                                  navigator.clipboard.writeText(`${info.phone ?
                                    convertPhoneWithCountryCodeFormat(info.country_code, info.phone)
                                    :
                                    convertPhoneWithCountryCodeFormat(info.country_code, info.mobile)
                                    }`);
                                }}
                                className="btn btn-link btn-md"
                              >
                                <i className="fas fa-copy"> </i>
                              </button>
                            </OverlayTrigger>
                          </>
                        )}
                        <div className="compant-action-btn">
                          <OverlayTrigger
                            placement="auto"
                            overlay={
                              <Tooltip id="overlay-example"> Add/View notes </Tooltip>
                            }
                          >
                            <button
                              onClick={() => {
                                getNotes({ 'note_type': 'manual' }, 'notes');
                                setDynamicModal(true);
                                setDynamicModalName("Note");
                              }}
                              className="btn btn-primary btn-md"
                            >
                              <i className="fas fa-edit"> </i>
                            </button>
                          </OverlayTrigger>
                          {(info.mobile != '' || info.phone != '') && info.phone_status == 1 && (
                            <OverlayTrigger
                              placement="auto"
                              overlay={
                                <Tooltip id="overlay-example"> Send Sms </Tooltip>
                              }
                            >
                              <button
                                onClick={() => {
                                  setDynamicModal(true);
                                  setDynamicModalName("sms");
                                  getSmsTemplate();

                                }}
                                className="btn btn-primary btn-md"
                              >
                                <i className="fas fa-comment"> </i>
                              </button>
                            </OverlayTrigger>
                          )}
                          {info.email != "" && info.email != null && info.email_status == 1 && (
                            <OverlayTrigger
                              placement="auto"
                              overlay={
                                <Tooltip id="overlay-example">Create an email</Tooltip>
                              }
                            >
                              <button
                                onClick={() => {
                                  setEmailModal(true);
                                  setDynamicModalName("email");
                                  getEmailTemplate();
                                  getVerifiedFromEmail("");
                                }}
                                className="btn btn-primary btn-md"
                              >
                                <i className="fas fa-envelope"> </i>
                              </button>
                            </OverlayTrigger>
                          )}
                          {(info.mobile != '' || info.phone != '') && info.phone_status == 1 && (
                            <OverlayTrigger
                              placement="auto"
                              overlay={
                                <Tooltip id="overlay-example">Make a phone call</Tooltip>
                              }
                            >
                              <CallWidget className="btn btn-primary btn-md" info={{
                                client_id: info.client_id,
                                coach_id: info.coach_id,
                                phoneno: info.phone ? info.country_code + info.phone : info.country_code + info.mobile,
                                phone: info.phone !== '' ? info.country_code + '' + info.phone : '',
                                mobile: info.mobile !== '' ? info.country_code + '' + info.mobile : '',
                                record: "false"

                              }} onPopupTrigger={console.log('call')} />

                              {/* <button
                                onClick={() => {
                                  setDynamicModal(true);
                                  setDynamicModalName("call");
                                  handleOutCall();
                                }}
                                className="btn btn-primary btn-md"
                              >
                                <i className="fas fa-phone-alt"> </i>
                              </button> */}
                            </OverlayTrigger>
                          )}
                          <OverlayTrigger
                            placement="auto"
                            overlay={<Tooltip id="overlay-example"> Task </Tooltip>}
                          >
                            <button
                              onClick={() => {
                                setTaskModal(true);
                              }}
                              className="btn btn-primary btn-md"
                            >
                              <i className="fas fa-tasks"> </i>
                            </button>
                          </OverlayTrigger>
                          {(info.mobile != '' || info.phone != '') && info.phone_status == 1 && (
                            <OverlayTrigger
                              placement="auto"
                              overlay={
                                <Tooltip id="overlay-example"> Send RVM </Tooltip>
                              }
                            >
                              <button
                                onClick={() => {
                                  setDynamicModal(true);
                                  setDynamicModalName("rvm");
                                  getRVMTemplate();
                                }}
                                className="btn btn-primary btn-md"
                              >
                                <i className="fas fa-microphone"> </i>
                              </button>
                            </OverlayTrigger>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>
            <div className="user-profile-pic-area">

              <div className="action-coach-trianing text-center d-none">

                {
                  info.already_coach == 'No' && (
                    <>
                      {(UpPermission.permission == 1 && UpPermission.remaining_coach >= 1) && (
                        info.email != '' && (info.phone != '' || info.mobile != '') && info.linked_user_id != 0 ?
                          <button
                            onClick={() => props.history.push(`/invoice/add/${bcrypt(info.client_id)}/coach`)}
                            className="btn btn-primary btn-sm mt-2 mb-2"
                          >
                            Convert To Coach
                          </button>
                          :
                          <button
                            onClick={() => swal({
                              title: "Convert to coach",
                              text: `To convert into a coach, a lead (phone or mobile), email, and {lead login} are compulsory.`,
                              icon: "warning",
                              dangerMode: true,
                            })}
                            className="btn btn-primary btn-sm mt-2 mb-2"
                          >
                            Convert To Coach
                          </button>

                      )}
                    </>
                  )
                }

              </div>

              <div className="AboutCard">
                <div className="details">
                  <Accordion className="border-0">

                    {
                      info.linked_user_id == "0" ? (
                        <button
                          className="btn btn-sm btn-primary m-2"
                          onClick={() => {
                            generateClientLogin();
                          }}
                        >Generate Prospect Account <i className="fas fa-sign-in-alt" aria-hidden="true"></i>
                        </button>
                      ) : (
                        <Accordion.Item eventKey="0">
                          <Accordion.Header> Client Login Credentials</Accordion.Header>
                          <Accordion.Body className="pt-3">
                            <div className="position-relative">
                              <AvForm
                                onValidSubmit={sendLoginDetails}

                                className="needs-validation"
                              >
                                <div className="info info d-flex justify-content-end">
                                  <button
                                    className="btn btn-link btn-md"
                                    type="button"
                                    onClick={() => {
                                      revertBack();
                                    }}
                                  >
                                    Revert Back To Lead Profile <i className="fas fa-arrow-right"></i>
                                  </button>

                                </div>
                                <div className="info pb-2">
                                  <div className="info info pt-2 pb-2">
                                    <AvField
                                      className="form-control"
                                      type="textarea"
                                      rows={4}
                                      name="login_details"
                                      value={`Please find below the PROFITCOACH login details. \nLogin URL: ${HostUrl} \nLogin Email: ${info.link_user_email} \nLogin Password: ${info.link_user_password}`}
                                    />
                                  </div>

                                </div>
                                <div className="info info pt-2 pb-2 d-flex ActionBtsns">
                                  <button
                                    className="btn btn-link btn-md"
                                    type="submit"
                                  >
                                    <i className="fa fa-paper-plane"></i> Send
                                  </button>
                                  <button
                                    className="btn btn-link btn-md"
                                    type="button"
                                    onClick={() => {
                                      navigator.clipboard.writeText(` Login URL: ${HostUrl} \nLogin Email: ${info.link_user_email}  \nLogin Password: ${info.link_user_password}`);
                                    }}
                                  >
                                    <i className="fas fa-copy"></i> Copy
                                  </button>
                                </div>
                              </AvForm>
                              <div className="info info pt-2 pb-2 d-flex ActionBtsns" style={{ position: 'absolute', bottom: 0, right: 0 }}>

                                <div className="RemovePostion"><UpdateCredential email={info.link_user_email} user_id={info.linked_user_id} refreshScreen={getData} /></div>
                              </div>

                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      )
                    }
                    <Accordion.Item eventKey="1">
                      <Accordion.Header> Basic Info</Accordion.Header>
                      <Accordion.Body className="pt-3">
                        <div className="info info pb-2 ">
                          <span> Lead ID </span> <p> {info.client_id} </p>
                        </div>
                        <div className="info info pt-2 pb-2">
                          <span> Lead Name </span> <p> {info.first_name} {info.last_name} </p>
                        </div>
                        <div className="info info  pt-2 pb-2">
                          <span> Lead Domain </span> <p> {website} </p>
                        </div>
                        <div className="info info  pt-2 pb-2">
                          <span> Lead Email </span> <p> {info.email} </p>
                        </div>
                        <div className="info info  pt-2 pb-2">
                          <span> Lead Phone </span> <p> {info.phone && convertPhoneWithCountryCodeFormat(info.country_code, info.phone)} </p>
                        </div>

                        <div className="info info  pt-2 pb-2">
                          <span> Lead Mobile </span> <p> {info.mobile && convertPhoneWithCountryCodeFormat(info.country_code, info.mobile)} </p>
                        </div>

                        <div className="info info  pt-2 pb-2">
                          <span> Lead Address </span> <p> {info.address} </p>
                        </div>
                        <div className="info info  pt-2 pb-2">
                          <span> Lead City </span> <p> {info.city} </p>
                        </div>
                        <div className="info info  pt-2">
                          <span> Lead State </span> <p> {info.state} </p>
                        </div>
                        <div className="info info  pt-2">
                          <span> Lead Source </span> <p> {info.source} </p>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header> Additional Info </Accordion.Header>
                      <Accordion.Body className="pt-3">
                        <div className="info info pt-2">
                          <h6 className="ProfileSecHeading p-1"> Deal Status </h6>
                          {info.deal && info.deal.deal_id && (
                            <div className="info info p-2">
                              <span>Name</span>
                              <p>{info.deal.pipeline_status.title} ({info.deal.pipeline.name})</p>
                              {/* <div className="info info mb-2 mt-2"></div>
                              <span> Assign to </span>
                              <p>{info.deal.assigned_to.name}</p> */}
                              <div className="info info mb-2 mt-2"></div>
                              <span> Deal Date </span>
                              <p> {info.deal.created_at} </p>
                            </div>
                          )}
                        </div>
                        <div className="info info pt-2 pb-2">
                          <h6 className="ProfileSecHeading p-1 mb-1"> Call Status </h6>
                          {assignCallStatus && info.status_id && (
                            <div className="info">
                              <span>Title</span>
                              <p>{assignCallStatus.call_status_title} ({assignCallStatus.created_at})</p>
                            </div>
                          )}
                        </div>
                        <div className="info info pb-2">
                          <h6 className="ProfileSecHeading p-1 mb-1"> Group </h6>
                          {groups.map((row) => {
                            return (
                              <div key={row.title + "-group"} className="info info">
                                <span> Group Name </span> <p> {row.title} </p>
                                <div className="pt-2 pb-1">
                                  <span> Group Date </span> <p> {row.dated} </p>
                                </div>
                                <div className="pt-2"></div>
                              </div>
                            );
                          })}
                        </div>
                        <div className="info info pb-2">
                          <h6 className="ProfileSecHeading p-1 mb-1"> Tag </h6>
                          {tags.length > 0 && (
                            <div className="mb-3">
                              {tags.map((row, i) => {
                                if (row.group_id) {
                                  return (
                                    <OverlayTrigger
                                      key={"tag-" + i}
                                      placement="auto"
                                      overlay={
                                        <Tooltip id={`tooltip-auto`}>
                                          <strong> {tags ? row.title : ""} </strong>
                                        </Tooltip>
                                      }
                                    >
                                      <Button
                                        className={`btn-sm btn-success mb-3 btn-round`}
                                      >
                                        <Badge title="Remove tag" bg="" onClick={() => removeTagModal(row.group_id)}>
                                          <i className="fas fa-times-circle pr-5"></i>
                                        </Badge>
                                        {tags ? row.title : ""}
                                      </Button>
                                    </OverlayTrigger>
                                  );
                                }
                              })}
                            </div>
                          )}
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                    {
                      clients.length == 0 ? (
                        <>
                          {/* <button
                          className="btn btn-sm pull-right m-2"
                          onClick={() => {
                            setpage3Class("slide-in");
                            childClientRef.current.getAlert();
                          }}
                        >
                          Add Company
                        </button> */}
                        </>
                      ) : (
                        <Accordion.Item eventKey="3">
                          <Accordion.Header>
                            Company {clients.length}
                          </Accordion.Header>
                          <Accordion.Body>
                            {clients.map((client, k) => {
                              return (
                                <div
                                  className="card"
                                  key={k + "--" + client.client_id}
                                >
                                  <div className="client-info info">
                                    <Link
                                      to={
                                        "/company/dialer/" +
                                        bcrypt(client.client_id) +
                                        "/" +
                                        bcrypt(client.coach_id)
                                      }
                                    >
                                      <h4>
                                        {client.first_name} {client.last_name}
                                      </h4>
                                    </Link>
                                    {
                                      client.email != '' && (
                                        <div className="url">
                                          <a href="#" className="pr-5">
                                            {client.email}
                                          </a>
                                          <OverlayTrigger
                                            placement="right"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={
                                              <Tooltip id="overlay-example">
                                                Copy email to clipboard
                                              </Tooltip>
                                            }
                                          >
                                            <button
                                              className="btn btn-link btn-md"
                                              onClick={() => {
                                                navigator.clipboard.writeText(
                                                  `${client.email}`
                                                );
                                              }}
                                            >
                                              <i className="fas fa-copy"> </i>
                                            </button>
                                          </OverlayTrigger>
                                        </div>
                                      )
                                    }
                                    {
                                      client.phone != '' && (
                                        <div className="url">
                                          <a href="#" className="pr-5">
                                            {client.phone}
                                          </a>
                                          <OverlayTrigger
                                            placement="right"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={
                                              <Tooltip id="overlay-example">
                                                Copy phone to clipboard
                                              </Tooltip>
                                            }
                                          >
                                            <button
                                              className="btn btn-link btn-md"
                                              onClick={() => {
                                                navigator.clipboard.writeText(
                                                  `${client.phone}`
                                                );
                                              }}
                                            >
                                              <i className="fas fa-copy"> </i>
                                            </button>
                                          </OverlayTrigger>
                                        </div>
                                      )
                                    }
                                  </div>
                                </div>
                              );
                            })}
                          </Accordion.Body>
                        </Accordion.Item>
                      )
                    }
                  </Accordion>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className={`page2 ${page2Class} custom-scroll`}>
              <div>
                <div className="modal-head top-heading">
                  <h2> Edit Detail </h2>
                  <button
                    onClick={() => {
                      setpage2Class("slide-out");
                      getData();
                      // getActivity("3");
                    }}
                    className="btn btn-link text-white"
                  >
                    <i className="fas fa-times"> </i>
                  </button>
                </div>
                <CreateUpdate
                  ref={childRef}
                  getData={getData}
                  id={info.client_id}
                  coach_id={info.coach_id}
                  from="companyDetails"
                />
              </div>
            </div>
          </div>
        </Col>
        <Col md={7} className="px-1">
          <Row>
            <Col lg={4} className="px-1">
              <Card className="mt-3 boxescard">
                <CardBody>
                  <section className="breakthrough clientProfileHeading sub-nav-bg mb-0 text-center">
                    <h4 className="card-title">Points of Contact</h4>
                  </section>
                  <div className="PointsContent">
                    <div className="row">
                      <div className="col-lg-4">
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="PointsCount code-dark d-flex justify-content-between mb-1 p-2">
                              <i className="fas fa-phone-alt"></i>
                              <h4 className="count-numbers">{points_contact.call}</h4>
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="PointsCount code-dark d-flex justify-content-between mb-1 p-2">
                              <i className="mdi mdi-comment"></i>
                              <h4 className="count-numbers">{points_contact.sms}</h4>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 px-0"><div className="code-green TotalPoints">{points_contact.total}</div></div>
                      <div className="col-lg-4">
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="PointsCount code-dark d-flex justify-content-between mb-1 p-2">
                              <i className="ion ion-md-mail"></i>
                              <h4 className="count-numbers">{points_contact.email}</h4>
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="PointsCount code-dark d-flex justify-content-between mb-1 p-2">
                              <i className="fas fa-calendar-alt"></i>
                              <h4 className="count-numbers">{points_contact.calendar_events}</h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg={4} className="px-1">
              <Card className="mt-3 boxescard">
                <CardBody>
                  <section className="breakthrough clientProfileHeading sub-nav-bg mb-0 text-center">
                    <h4 className="card-title">Pipeline Status</h4>
                    {/* <i className="fas fa-bars bars-icon"></i> */}
                  </section>
                  <div className="card card-body Breakdown PathwayProgression">
                    {
                      pipelineStatus.map((pstatus, i) => {
                        if (pstatus.is_complete == 1) {
                          return (
                            <div key={i} className="ROiPercents mb-2">
                              <div className="revenue w-100 pull-left"><p>{pstatus.name}</p></div>
                              <div className="SectionHeader RoiPage ">
                                <div className="Percents">
                                  <h4 className="GreenBG text-center FullBG" style={{ background: '#f4bc33', width: `100%` }}>
                                    {pstatus.final_pipeline == 1 ? `${pstatus.current_status}` : 'Complete'}
                                  </h4>
                                </div>
                              </div>
                            </div>
                          )
                        } else {
                          return (
                            <div key={i} className="ROiPercents mb-2">
                              <div className="revenue w-100 pull-left"><p>{pstatus.name}</p></div>
                              <div className="SectionHeader RoiPage ">
                                <div className="Percents">
                                  <h4 className="GrayBG justify-content-center" style={{ background: '#041934', width: `100%` }}>
                                    <span className="pull-left">Next</span>
                                  </h4>
                                  <h4 className="GreenBG text-end" style={{ background: '#008037', width: `0%` }}>
                                    000
                                  </h4>
                                </div>

                              </div>
                            </div>
                          )
                        }

                      })
                    }
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg={4} className="px-1">
              <Card className="mt-3  boxescard">
                <CardBody>
                  <section className="breakthrough clientProfileHeading sub-nav-bg mb-0 text-center">
                    <h4 className="card-title">ACTION LIST</h4>
                  </section>
                  <div className="text-right mb-1">
                    <button onClick={() => setTodoModal(true)} className="btn code-green" ><i className="fas fa-plus"></i></button>
                  </div>

                  <ul className="list-group todo-list">
                    {
                      todoLists.map((list, i) => {
                        return (
                          <li className="mb-2" key={i}>
                            <div className="d-flex justify-content-between todotask">
                              <div className="list-todo">
                                {
                                  list.status == 1 ?
                                    <button disabled className="btn btn-outline-success boder-green rounded-100"><i className="fas fa-check"></i></button>
                                    :
                                    <button title="Mark as complete" onClick={() => todoComplete(list.id)} className="bwaves-effect waves-light btn btn-outline-dark rounded-100"></button>
                                }

                                <p className="notes">{list.description}</p>
                              </div>

                              {/* {
                                list.status == 1 && ( */}
                              <button title="Remove action list" onClick={() => todoRemove(list.id)} className="btn btn-link Remove-Action"><i className="fas fa-trash-alt"></i></button>
                              {/* )
                              } */}
                            </div>
                          </li>
                        )
                      })
                    }
                  </ul>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row className="mb-1">
            <Col lg={4} className="px-1">
              <Card className="VariousCalls mb-2 pb-3 mt-3  boxescard">
                <CardBody className=" InboxBusiness ClientDailer">
                  <section className="breakthrough clientProfileHeading ToolKit sub-nav-bg mb-0 text-center">
                    <h4 className="card-title">Activity</h4></section>
                  <div className="MonthlyDropDown mb-2">
                    <select onChange={(e) => { setInboxDay(e.target.value); getInboxActivity('all', e.target.value) }} className="form-control">
                      <option value={'today'}>Today</option>
                      <option value={'weekly'}>Weekly</option>
                      <option value={'monthly'}>Current Month</option>
                      <option value="1_month"> Last Month </option>
                      <option value="3_month"> Last 3 Month </option>
                      <option value="6_month"> Last 6 Month </option>
                      <option value="9_month"> Last 9 Month </option>
                      <option value="all">All </option>
                    </select>
                  </div>
                  <div className="border-three py-0">
                    <Row>
                      <Col title="Sms/Mms" xl="6" lg="6" xs="6" className="p-2 pb-2"><Card onClick={() => getInboxActivity('sms')}
                        className="Message  p-2"><i className="mdi mdi-comment"></i>
                        <div className="NumberFunc">{inbox_activity.sms_count}</div>
                      </Card>
                      </Col>
                      <Col title="Email" xl="6" lg="6" xs="6" className="p-2 pb-2"><Card onClick={() => getInboxActivity('email')} className="Email p-2 mb-0"><i className="ion ion-md-mail"></i>
                        <div className="NumberFunc">{inbox_activity.email_count}
                        </div>
                      </Card>
                      </Col>
                      <Col title="Call" xl="6" lg="6" xs="6" className="p-2 pb-2"><Card onClick={() => getInboxActivity('call')}
                        className="ReceivedCalls p-2"><i className="ion ion-md-call"></i>
                        <div className="NumberFunc" >{inbox_activity.call_count}
                        </div>
                      </Card></Col>
                      <Col title="Voicemail" xl="6" lg="6" xs="6" className="p-2 pb-2"><Card onClick={() => getInboxActivity('voice')} className="MissedCalls p-2"><i className="ion ion-md-call"></i><div className="AlertBadge"><i className="fas fa-bookmark"></i><span>{inbox_activity.voice_message_un_listen}</span></div>
                        <div className="NumberFunc"  >{inbox_activity.voice_count}
                        </div>
                      </Card>
                      </Col>
                      <Col title="Rvm" xl="6" lg="6" xs="6" className="p-2 pb-2"><Card onClick={() => getInboxActivity('rvm')} className="MissedCalls p-2">
                        <i className="ion ion-ios-recording"></i>
                        <div className="NumberFunc"  >{inbox_activity.rvm_count}
                        </div>
                      </Card>
                      </Col>
                    </Row>
                  </div>
                  {(info.mobile != '' || info.phone != '') && inbox_activity.type == 'sms' && (
                    <AvForm
                      onValidSubmit={handleSubmitSmsSend}
                      className="needs-validation"
                      ref={(c) => (this_form = c)}
                    >
                      {smsloader && (
                        <div className="flex-grow-0  SendMessage">
                          <AvField type="hidden" name="tos" value={info.mobile != '' ? info.mobile : info.phone} />

                          <AvField type={'hidden'} name="froms" value={uInfo().outbound_phone} />
                          <AvField type="hidden" name="activity" value={"sms send"} />
                          <div className="row mt-1">
                            <div className="col-md-12 input-group">
                              {/* <AvField
                                        className="form-control"
                                        type="text"
                                        name="body"
                                        value={smsBody}
                                        placeholder={"Enter body"}
                                        onChange={(e) => setSmsBody(e.target.value)}
                                      /> */}
                              <AvInput
                                onChange={(e) => setSmsBody(e.target.value)}
                                value={smsBody}
                                type="textarea"
                                name="body"
                                className="form-control"
                                required
                                placeholder="Type Message..."
                              />
                              <button
                                disabled={""}
                                type="submit"
                                className="btn btn-primary"
                              >
                                <i className="fa fa-paper-plane" aria-hidden="true"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    </AvForm>
                  )}
                  <ul className="mt-2" style={{ minHeight: inbox_activity.type == 'sms' ? '320px' : '360px', maxHeight: inbox_activity.type == 'sms' ? '320px' : '360px' }}>
                    {inbox_activity.report_results.length > 0 ? (
                      inbox_activity.report_results.map((row, i) => {
                        return (
                          <li role={'button'} onClick={() => {
                            if (row.type == 'email' || row.type == 'call' || row.type == 'voice' || row.type == 'rvm') {
                              viewLogDetails(row, row.type);
                            }
                          }} className={`mt-3 activity-note-${row.direction}`} key={"message" + i}>
                            <div className={`StatusIcon ${row.background}`}>
                              {/* <i className="mdi mdi-comment"></i> */}
                              <i className={`${row.icon}`}></i>
                            </div>
                            <div className="desp-box">
                              <div className="d-flex  align-items-center flex-wrap">
                                <div className="ContactDescription p-2">
                                  <div className="NameUser"><h6 className="card-title">{row.client_name}</h6>
                                  </div>
                                  <div className="UserMessage"><p>{row.body}</p></div>
                                </div>
                                {
                                  row.type == 'sms' && (
                                    <div className="reply-icon"><i className="fas fa-reply"></i></div>
                                  )
                                }
                              </div>
                              <div className="MessageDate">{row.created_at}&nbsp;<span className="roadmapDashboard">{row.master_type === 'Roadmap' ? '(Roadmap)' : ''} </span></div>

                              {/* <div className="MessageDate">November 9th <span>9:00AM (EST)</span></div> */}
                            </div>
                          </li>
                        );
                      })
                    ) : (
                      <li key={"message1"} className="mt-2">
                        <div className="ContactDescription p-2">
                          <div className="UserMessage text-center no-record"><p>No record found</p></div>
                        </div>
                      </li>
                    )}
                  </ul>

                </CardBody>
              </Card>
            </Col>
            <Col xl="4" lg="4" sm="12" xs="12" className="display-flex space-between mb-2 px-1">
              <Card className="VariousCalls TotalRecord MyScheduleMain TaskManager mt-3">
                <CardBody>
                  <section className="breakthrough clientProfileHeading ToolKit sub-nav-bg mb-0 text-center">
                    <h5 className="mb-0">Task Manager</h5></section>
                  <div className="selectboxtop PlusSign">
                    <div className="d-flex justify-content-between StatusHeading">

                      <input value={month_year} required="required" onChange={(e) => {
                        let date = e.target.value;
                        if (date != '') {
                          setMonthYear(date);
                        } else {
                          setMonthYear(new moment().format("YYYY-MM"))
                        }
                        getTaskStatistics("monthly", date)
                      }} className="form-control" type={'month'} name="deal_month" />


                      <h5 className="card-title">
                        <button
                          onClick={() => {
                            setTaskModal(true);
                            setTaskData({});
                          }}
                          className="btn btn-primary code-green border-0 float-end"><i className="fas fa-plus"></i></button>
                      </h5>
                    </div>


                  </div>
                  <div className="border-three py-0">
                    <Row>
                      <Col xl="6" lg="6" xs="6" className="p-2 pb-2">
                        <Card onClick={() => getTaskStatistics("today")} className="Message p-1 mb-1">
                          <div className="IconWithCount">
                            <div className="dot code-red"></div>
                            <span>Today</span>
                          </div>
                          <div className="NumberFunc">{task_record.today}</div>
                        </Card>
                      </Col>
                      <Col xl="6" lg="6" xs="6" className="p-2 pb-2">
                        <Card onClick={() => getTaskStatistics("weekly")} className="Email p-1 mb-1">
                          <div className="IconWithCount">
                            <div className="dot code-dark"></div>
                            <span>Week</span>
                          </div>
                          <div className="NumberFunc">{task_record.weekly}</div>
                        </Card>
                      </Col>
                      <Col xl="6" lg="6" xs="6" className="p-2 pb-2">
                        <Card onClick={() => getTaskStatistics("monthly")} className="ReceivedCalls p-1">
                          <div className="IconWithCount"><div className="dot code-green"></div>
                            <span>Month</span>
                          </div>
                          <div className="NumberFunc">{task_record.monthly}</div>
                        </Card>
                      </Col>
                      <Col xl="6" lg="6" xs="6" className="p-2 pb-2">
                        <Card onClick={() => getTaskStatistics("unassigned")} className="MissedCalls p-1">
                          <div className="IconWithCount">
                            <div className="dot code-yellow"></div>
                            <span>Unassigned</span>
                          </div>
                          <div className="NumberFunc">{task_record.unassigned}</div>
                        </Card>
                      </Col>
                    </Row>
                  </div>
                  <Card className="MyScheduleMain">
                    <CardBody className="RecentRecord MyTasks NewTasks mt-2">
                      <ul>
                        {task_record.task_log.length > 0 ? (
                          task_record.task_log.map((row, i) => {
                            return (
                              <li key={i} className="p-2 mt-1 mb-3" onClick={() => {
                                setTimeZone(row.time_zone1);
                                setMainTimeZone(row.time_zone);
                                setTaskData(row);
                                setTaskModal(true);
                              }}>
                                <div className={`DotTask code-${row.color_code}`}></div>
                                <div className="TaskDescp">
                                  <div className="research-date d-flex justify-content-between"><p>{row.created_at}</p>
                                    {row.status == 'complete' && (
                                      <i title="Task completed" class="far fa-check-circle"></i>
                                    )}
                                  </div>
                                  <hr className="my-1"></hr>
                                  <p>{row.title}</p>
                                </div>
                              </li>
                            );
                          })
                        ) : (

                          <li key={"task1"} className="mt-1 mb-2 no-record">
                            <div className="ContactDescription p-2">
                              <div className="UserMessage text-center"><p>No record found</p></div>
                            </div>
                          </li>
                        )}
                      </ul>
                    </CardBody>
                  </Card>
                </CardBody>
              </Card>

            </Col>
            <Col lg={4} className="px-1">
              <Card className=" boxescard mt-3 calanderbox">
                <CardBody>
                  <section className="breakthrough clientProfileHeading sub-nav-bg mb-0 text-center">
                    <h4 className="card-title">Events</h4>
                  </section>
                  {/* <div className="selectboxtop PlusSign CalanderPanel"><h5 className="card-title mb-3">
                    <button className="btn btn-primary code-green border-0 float-end"><i className="fas fa-plus"></i></button>
                  </h5>
                  </div> */}
                  {
                    calendarReload && (
                      <CalanderView scheduleEvent={scheduleEvent} clientId={idx} />
                    )
                  }
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Col>
        <Col md={2}>
          <Card className="NoShadow custom-scroll FixedSidebar">
            <CardBody className="px-0">
              <div className="col-md-12 text-center third-column-meetings d-none">
                {
                  info.coach_login == 'yes' && info.linked_user_id != 0 ? (
                    <button
                      onClick={() => props.history.push(`/chat/${info.linked_user_id}/client`)}
                      // onClick={() => {
                      //   generateMetting();
                      // }}
                      className="btn btn-sm btn-primary pull-right m-2"
                    >
                      Start Meeting
                    </button>
                  ) : (
                    defaultCalendar != 0 && (
                      <button
                        onClick={() => {
                          setDynamicModal(true);
                          setDynamicModalName("meeting");
                        }}
                        className="btn btn-sm btn-primary pull-right m-2"
                      >
                        Schedule Meeting
                      </button>
                    )
                  )

                }
              </div>
              <div className="details ProflieActions meeting-schdule">
                <Accordion defaultActiveKey="10" className="border-0">
                  <Accordion.Item eventKey="2">
                    <div className="accordion-head deal-pipeline remove-arrow">
                      <Accordion.Header> Pipeline </Accordion.Header>
                      {
                        pipelineStatus.length > 0 ? (
                          <button
                            className="btn btn-link"
                            onClick={() => {
                              setpage5Class("slide-in");
                            }}
                          >
                            <img src={Cog}></img>
                          </button>
                        ) :
                          (
                            <button
                              className="btn btn-link"
                              onClick={() => swal({
                                title: "Assign Deal",
                                text: `You need to add pipeline for assign deal`,
                                icon: "warning",
                                dangerMode: true,
                              })}
                            >
                              <img src={Cog}></img>
                            </button>
                          )
                      }
                      {/* <button
                        className="btn btn-link"
                        onClick={() => {
                          setpage5Class("slide-in");
                        }}
                      >
                        <img src={Cog}></img>
                      </button> */}
                    </div>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <div className="accordion-head deal-pipeline remove-arrow">
                      <Accordion.Header>Attachments</Accordion.Header>
                      <button
                        className="btn btn-link"
                        onClick={() => {
                          setpage8Class("slide-in");
                        }}
                      >
                        <img src={Cog}></img>
                      </button>
                    </div>
                  </Accordion.Item>

                  <Accordion.Item eventKey="6">
                    <div className="accordion-head deal-pipeline remove-arrow">
                      <Accordion.Header> Assessment </Accordion.Header>
                      <button
                        className="btn btn-link"
                        onClick={() => {
                          setpage12Class("slide-in");
                          getActivity(13);
                        }}
                      >
                        <img src={Cog}></img>
                      </button>
                    </div>
                  </Accordion.Item>

                  <Accordion.Item eventKey="3" >
                    <div className="accordion-head deal-pipeline remove-arrow">
                      <Accordion.Header> Products </Accordion.Header>
                      <button
                        className="btn btn-link"
                        onClick={() => {
                          setpage6Class("slide-in");
                        }}
                      >
                        <img src={Cog}></img>
                      </button>
                    </div>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3" className="d-none">
                    <div className="accordion-head deal-pipeline remove-arrow">
                      <Accordion.Header> Training </Accordion.Header>
                      {(info.email != '' && (info.phone != '' || info.mobile != '') && info.linked_user_id != 0) ? (
                        <button
                          className="btn btn-link"
                          onClick={() => {
                            setpage11Class("slide-in");
                          }}
                        >
                          <img src={Cog}></img>
                        </button>
                      ) : (
                        <button
                          onClick={() => swal({
                            title: "Assign Training",
                            text: `For assign training, a Lead (phone or mobile), email, and {Lead login} are compulsory.`,
                            icon: "warning",
                            dangerMode: true,
                          })}
                          className="btn btn-link"
                        >
                          <img src={Cog}></img>
                        </button>
                      )}
                    </div>
                  </Accordion.Item>
                  <Accordion.Item eventKey="7">
                    <div className="accordion-head deal-pipeline remove-arrow">
                      <Accordion.Header>Scripts</Accordion.Header>
                      <button
                        className="btn btn-link"
                        onClick={() => {
                          setpage13Class("slide-in");
                        }}
                      >
                        <img src={Cog}></img>
                      </button>
                    </div>
                  </Accordion.Item>
                  {/* <Accordion.Item eventKey="4">
                    <div className="accordion-head deal-pipeline remove-arrow">
                      <Accordion.Header> Contracts </Accordion.Header>
                      <button
                        className="btn btn-link"
                        onClick={() => {
                          setpage7Class("slide-in");
                        }}
                      >
                        <i className="fas fa-plus"> </i> Add/View
                      </button>
                    </div>
                  </Accordion.Item> */}
                </Accordion>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Modal
        isOpen={tagModal}
        fade={false}
        toggle={() => removeTagModal()}
      >
        <ModalBody>
          <div className="warning-box text-center">
            <span className="warning">
              <i className="mdi mdi-alert-circle"> </i>
            </span>
            <h3> Are you sure ? </h3>
            <p> You want to remove this Tag ? </p>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="info"
            onClick={() => removeTagLead()}
          >
            Yes, Remove it
          </Button>
          <Button
            color="danger"
            onClick={() => removeTagModal()}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={taskModal}
        fade={true}
        size="md"
        toggle={() => {
          setTaskModal(false);
          setTaskData({});
        }}
      >
        <AvForm
          onValidSubmit={handleTaskSubmit}
          className="needs-validation"
        >
          <div className="note">
            <div className="modal-head top-heading">
              <h2> {taskData.meeting_id ? 'Manage' : 'Add'}  Task </h2>
              <button
                onClick={() => {
                  setTaskModal(false);
                  setTaskData({});
                }}
                className="btn btn-link text-white"
              >
                <i className="fas fa-times"> </i>
              </button>
            </div>
            <ModalBody className="pb-0">
              <div className="mb-0 row">
                <div className="col-md-12 mb-3">
                  <AvField
                    name="event_title"
                    label="Title"
                    className="form-control"
                    required
                    type="text"
                    value={taskData.event_title}
                  />
                </div>
                <div className="col-md-12 mb-3">
                  <AvField
                    name="server_date"
                    label="Select Date"
                    className="form-control"
                    required
                    type="date"
                    value={taskData.server_date}
                  />
                </div>
                <div className="col-md-12 mb-3">
                  <AvField
                    name="server_time"
                    label="Select Time"
                    className="form-control"
                    required
                    type="time"
                    value={taskData.server_time}
                  />
                </div>
                <Col md={6} className="d-none">
                  <AvGroup>
                    <label> MainTime Zone </label>
                    <div className="MainTZone MainTime One">
                      <Select
                        value={maintimezone}
                        getOptionLabel={(option) => option.label}
                        getOptionValue={(option) => option.id}
                        isMulti={false}
                        options={maintimezones}
                        classNamePrefix="select2-selection"
                        className={`${maintimezone != ""
                          ? "is-touched is-dirty av-valid"
                          : "is-touched is-pristine av-invalid is-invalid"
                          }`}
                        name="time_zone"
                        onChange={(e) => {
                          settimezone(e);
                        }}
                      />
                    </div>
                    <AvInput
                      type="hidden"
                      name="time_zone_1"
                      value={maintimezone.id}
                    />
                    <AvFeedback> This is required * </AvFeedback>
                  </AvGroup>
                </Col>
                <Col md={6} className="d-none">
                  <AvGroup>
                    <label> Time Zone </label>
                    <div className="MainTZone MainTime TwoTimeZone">
                      <Select
                        value={timezone}
                        getOptionLabel={(option) => option.label}
                        getOptionValue={(option) => option.id}
                        isMulti={false}
                        options={timeZone_data}
                        classNamePrefix="select2-selection"
                        className={`${timezone != ""
                          ? "is-touched is-dirty av-valid"
                          : "is-touched is-pristine av-invalid is-invalid"
                          }`}
                        name="time_zone1"
                        onChange={(e) => {
                          setTimeZone(e);
                        }}
                      />
                    </div>
                    <AvInput
                      type="hidden"
                      name="time_zone"
                      value={timezone.id}
                    />
                    <AvFeedback> This is required * </AvFeedback>
                  </AvGroup>
                </Col>
                <Col md={12}>
                  <div className="MainTZone Task Two">
                    <AvField
                      className="select form-control"
                      type="select"
                      name="assign_to"
                      label="Assign To"
                      classNamePrefix="select2-selection"
                      value={taskData.assign_to}
                    >
                      <option value={""}> Un Assign </option>
                      {
                        info.linked_user_id > 0 && (
                          <optgroup label="This Lead">
                            <option key={1} value={info.linked_user_id}>{info.first_name + " " + info.last_name + " - " + info.email}</option>
                          </optgroup>
                        )
                      }
                      <optgroup label="Coach">
                        <option key={2} value={coachUser.user_id}>{coachUser.name + " - " + coachUser.email}</option>
                      </optgroup>
                      <optgroup label="Coach Partner">
                        {coachPartners.map((row, i) => {
                          return (
                            <option key={i} value={row.user_id}>
                              {row.name + " - " + row.email}
                            </option>
                          );
                        })}
                      </optgroup>

                    </AvField>
                  </div>
                </Col>
              </div>
              <div className="mb-0">
                <AvField
                  type="hidden"
                  name="client_id"
                  value={info.client_id}
                />
              </div>
              {
                taskData.meeting_id && (
                  <AvField
                    type="hidden"
                    name="meeting_id"
                    value={taskData.meeting_id}
                  />
                )
              }
            </ModalBody>
            <ModalFooter>
              {
                taskData.meeting_id && (
                  <Button className="custom-btn-success" color="success" onClick={() => { deleteTaskAndEvents(taskData.meeting_id, 'complete') }}>
                    Done
                  </Button>
                )
              }
              {
                taskData.meeting_id && (
                  <Button className="custom-btn-danger" color="danger" onClick={() => { deleteTaskAndEvents(taskData.meeting_id, 'remove') }}>
                    Delete
                  </Button>
                )
              }
              <Button className="custom-btn-secondary" color="secondary" type="submit">
                Save
              </Button>
              <Button className="custom-btn-muted" color="muted" onClick={() => { setTaskModal(false); setTaskData({}); }}>
                Cancel
              </Button>
            </ModalFooter>
          </div>
        </AvForm>
      </Modal>
      <div className="dialer">
        <Loader loaded={loader}></Loader>
      </div>
      <div className={`page3 ${page3Class} custom-scroll`}>
        <div>
          <div className="modal-head top-heading">
            <h2> Add company to this lead</h2>
            <button
              onClick={() => {
                setpage3Class("slide-out2");
              }}
              className="btn btn-link text-white"
            >
              <i className="fas fa-times"> </i>
            </button>
          </div>
          <Tabs defaultActiveKey="existing" className="mb-3">
            <Tab eventKey="existing" title="Association existing">
              <Row>
                <Col className="col-12">
                  <Card>
                    <CardBody>
                      <div className="col-md-8 offset-2">
                        <div className="form-group relative">
                          <input
                            value={
                              searchClient != "" && searchClient != null
                                ? searchClient
                                : ""
                            }
                            onChange={(e) => setSearchClient(e.target.value)}
                            placeholder="Search leads"
                            name="searh"
                            type={"text"}
                            className={"form-control"}
                          />
                          {searchClient == "" ? (
                            <button className="btn btn-sm searchBtn">
                              <span className="fas fa-search"> </span>
                            </button>
                          ) : (
                            <button
                              onClick={() => setSearchClient(null)}
                              className="btn btn-sm searchBtn"
                            >
                              <span className="fas fa-times"> </span>
                            </button>
                          )}
                        </div>
                        <p>
                          {existing.length}
                          results
                        </p>
                        {existing.length < 1 && searchClient != "" && (
                          <p className="text-danger">
                            No company match the current search.
                          </p>
                        )}
                        {existing.length > 0 && (
                          <form method="POST" onSubmit={handleExistingClient}>
                            <fieldset className="form-group">
                              {/* onChange={this.handleChange.bind(this)} */}
                              <div className="form-control border-0 p-0 h-auto is-untouched is-pristine av-valid">
                                {existing.map((row, i) => {
                                  return (
                                    <div
                                      className="form-check pt-3"
                                      key={"search" + i}
                                    >
                                      <input
                                        name={`company_id[]`}
                                        type="radio"
                                        className=" form-check-input"
                                        value={`${row.client_id}`}
                                      />
                                      <label className="form-check-label">
                                        {`${row.first_name} ${row.last_name} (${row.email})`}
                                      </label>
                                    </div>
                                  );
                                })}
                              </div>
                            </fieldset>
                            <button
                              className="btn btn-lg btn-primary my-3"
                              type="submit"
                            >
                              Submit
                            </button>
                          </form>
                        )}
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Tab>
            <Tab eventKey="new" title="Association new">
              <CompanyCreateUpdate
                ref={childClientRef}
                getData={getCompanyClients}
                compannyId={info.client_id}
                from="clientDetails"
              />
            </Tab>
          </Tabs>
        </div>
      </div>

      <Modal
        isOpen={todoModal}
        fade={true}
        size="md"
        toggle={() => {
          setTodoModal(false);
        }}
      >
        <div className="note">
          <div className="modal-head top-heading">
            <h2> Add list </h2>
            <button
              onClick={() => {
                setTodoModal(false);
              }}
              className="btn btn-link text-white"
            >
              <i className="fas fa-times"> </i>
            </button>
          </div>
          <ModalBody>
            <AvForm
              onValidSubmit={handleTodoSubmit}
              // model={this.state.record}
              className="needs-validation"
            >
              <div className="mb-3">
                <AvField
                  className="form-control"
                  type="textarea"
                  name="description"
                  label=""
                  placeholder="Add a note"
                  required
                  rows={5}
                />
              </div>
              <ModalFooter>
                <Button color="info" type="submit">
                  Save
                </Button>
                <Button color="danger" onClick={() => setTodoModal(false)}>
                  Cancel
                </Button>
              </ModalFooter>
            </AvForm>
          </ModalBody>
        </div>
      </Modal>
      <Modal
        isOpen={DynamicModal}
        fade={true}
        size={DynamicModalName == 'call' ? 'lg' : 'md'}
        toggle={() => {
          setDynamicModal(false);
          setSmsBody("");
          SetSelectedMmsCustom("");
          SetSelectedMmsDefault("");
          setToken("");
          setSubject("");
          setHtml("");
          setTemplateId(0);
          setEventData({});
          setScript("");
        }}
      >
        <AvForm
          onValidSubmit={handleSubmit}
          // model={this.state.record}
          className="needs-validation"
        >
          {DynamicModalName == "Note" ? (
            <div className="note LeadDialer">
              <div className="modal-head top-heading">
                <h2> Add/View  Note </h2>
                <button
                  onClick={() => {
                    setDynamicModal(false);
                    setSendNote(false);
                    setSmsBody("");
                    SetSelectedMmsCustom("");
                    SetSelectedMmsDefault("");
                    setToken("");
                    setSubject("");
                    setHtml("");
                    setScript("");

                  }}
                  className="btn btn-link text-white"
                  type="button"
                >
                  <i className="fas fa-times"> </i>
                </button>
              </div>
              <ModalBody>
                <div className="d-flex justify-content-between">
                  <div className="MonthlyDropDown mb-2" style={{ width: 'auto' }}>
                    <select onChange={(e) => { setNotes([]); setNoteType(e.target.value); getNotes({ 'note_type': e.target.value }, 'notes') }} className="form-control">
                      <option value={'manual'}>Manual Notes</option>
                      <option value={'system'}>System Notes</option>
                      <option value="all">All Notes</option>
                    </select>
                  </div>
                  {
                    noteType == 'manual' && (
                      <div className="text-right mb-2">
                        <button onClick={() => setSendNote(true)} type="button" className="btn code-green" ><i className="fas fa-plus"></i></button>
                      </div>
                    )
                  }
                </div>
                {
                  sendNotes && (

                    <div className="mb-3">
                      <div className="mb-2">
                        <AvField
                          className="form-control"
                          type="textarea"
                          name="activity"
                          label=""
                          placeholder="Add a note"
                          required
                        />
                      </div>
                      <div className="btns">
                        <Button className="mr-3" color="info" type="submit">
                          Add Note
                        </Button>
                        <Button color="danger" onClick={() => { setSendNote(false) }}>
                          Cancel
                        </Button>
                      </div>
                    </div>
                  )
                }
                {notes.length > 0 ? (
                  notes.map((row, i) => {
                    return (
                      <div key={i} className="CompanyDetail ">
                        <div key={"note-" + i} className="note activity-info">
                          <div className="activity-body">
                            <div className="activity-bubble note">
                              <div className="activity-content">
                                <div className="activity_time">
                                  <div className="ActivityTitle" style={{ width: '65%' }}>
                                    <span> {row.type} </span>
                                  </div>
                                  {row.created_at}
                                </div>
                                <p> {row.activity} </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="col-12 col-lg-12 col-xl-12 text-center">
                    No Record Found
                  </div>
                )}
                {/* <div>
                  <AvField
                    className="form-control"
                    type="textarea"
                    name="activity"
                    label=""
                    placeholder="Add a note"
                    required
                  />
                </div> */}
              </ModalBody>
              {/* <ModalFooter>
                <Button color="info" type="submit">
                  Add Note
                </Button>
                <Button color="danger" onClick={() => setDynamicModal(false)}>
                  Cancel
                </Button>
              </ModalFooter> */}
            </div>
          ) : DynamicModalName == "sms" ? (
            <div className="sms">
              <div className="modal-head top-heading">
                <h2> Send SMS</h2>
                <button
                  onClick={() => {
                    setDynamicModal(false);
                    setSmsBody("");
                    SetSelectedMmsCustom("");
                    SetSelectedMmsDefault("");
                    setToken("");
                    setSubject("");
                    setHtml("");
                    setTemplateId(0);
                    setVerfiedFromEmail();
                  }}
                  type="button"
                  className="btn btn-link text-white"
                >
                  <i className="fas fa-times"> </i>
                </button>
              </div>
              <ModalBody>
                <div className="mb-3">
                  <div className="MainTZone SendSMS One">
                    <Select
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      isMulti={false}
                      options={templates}
                      classNamePrefix="select2-selection"
                      name="country_id"
                      placeholder={"Select sms template"}
                      onChange={(e) => {
                        setSmsBody(e.body);
                        SetSelectedMmsDefault(e.media_file);
                        SetSelectedMmsCustom("");
                        setTemplateId(e.id);
                      }}
                    />
                  </div>
                  <AvField type="hidden" name="template_id" value={template_id} />
                  <AvField type="hidden" name="tos" value={info.mobile != '' ? info.mobile : info.phone} />
                  <AvField type="hidden" name="activity" value={"sms send"} />
                </div>
                <div className="mb-3">
                  <AvField
                    className="select form-control"
                    type="select"
                    name="froms"
                    required
                  >
                    <option value=""> Select Phone Number </option>
                    {phoneNumbers.map((row, i) => {
                      return (
                        <option key={"phone-" + i} value={row.phone_number}>
                          {convertPhoneFormat(row.phone_number)}
                        </option>
                      );
                    })}
                  </AvField>
                </div>
                <div className="row mb-3">
                  <div className="col-md-8">
                    <div className="MainTZone SendSMS Two">
                      <Select
                        getOptionLabel={(option) => option.label}
                        getOptionValue={(option) => option.key}
                        // value={options.filter(({ id }) => id ===country_code)}
                        isMulti={false}
                        options={tokens}
                        classNamePrefix="select2-selection"
                        name="country_id"
                        placeholder={"Select token"}
                        onChange={(e) => {
                          setToken(`{${e.key}}`);
                        }}
                      />
                    </div>
                    {/* <AvField type="hidden" name="country_code" value={token} /> */}
                  </div>
                  <div className="col-md-4">
                    <button
                      onClick={() => setSmsBody(`${smsBody} ${token}`)}
                      type="button"
                      className="btn btn-primary"
                    >
                      Insert Token
                    </button>
                  </div>
                </div>
                <div className="mb-3">
                  <AvField
                    className="form-control"
                    type="textarea"
                    name="body"
                    required
                    value={smsBody}
                    placeholder={"Enter body"}
                    onChange={(e) => setSmsBody(e.target.value)}
                  />
                </div>
                {
                  clearFile && (
                    <>
                      <div className="mb-3">
                        <div className="row">
                          <div className="col-md-10">
                            <AvInput
                              name="media_file"
                              label="Media File"
                              className="form-control"
                              type="file"
                              accept="image/*"
                              onChange={(e) => changeHandler(e)}
                            />
                          </div>
                          <div className="col-md-2">
                            <button className="btn btn-info" type="button" onClick={() => handleClearFile()}>Clear</button>
                          </div>
                        </div>
                        <p className="text-info">
                          Note: Maximum file size 5 MB - gif, jpeg, png content files fully supported, content types outside of these are not fully supported.
                        </p>
                      </div>
                    </>
                  )
                }

                {selected_mms_default && (
                  <div className="mb-3">
                    <a
                      href={
                        AttachementsUrl +
                        "/user_" +
                        coach_id +
                        "/attachment/" +
                        selected_mms_default
                      }
                      download
                      onClick={(e) => download(e)}
                      target="blank"
                    >
                      <i className="fa fa-download" />
                      download
                    </a>
                  </div>
                )}
              </ModalBody>
              <ModalFooter>
                <Button color="info" type="submit">
                  Send
                </Button>
                <Button color="danger" onClick={() => setDynamicModal(false)}>
                  Cancel
                </Button>
              </ModalFooter>
            </div>
          ) : DynamicModalName == "rvm" ? (
            <div className="rvm">
              <div className="modal-head top-heading">
                <h2> Send RVM </h2>
                <button
                  onClick={() => {
                    setDynamicModal(false);
                    setSmsBody("");
                    setToken("");
                    setSubject("");
                    setHtml("");
                  }}
                  type="button"
                  className="btn btn-link text-white"
                >
                  <i className="fas fa-times"> </i>
                </button>
              </div>
              <ModalBody>

                <div className="mb-3">
                  <AvField
                    className="select form-control"
                    type="select"
                    name="froms"
                    required
                  >
                    <option value=""> Select Phone Number </option>
                    {phoneNumbers.map((row, i) => {
                      return (
                        <option key={"num-" + i} value={row.phone_number}>
                          {convertPhoneFormat(row.phone_number)}
                        </option>
                      );
                    })}
                  </AvField>
                </div>
                <div className="mb-3" >
                  <AvRadioGroup
                    inline name="file_type"
                    value={file_type}
                    onChange={
                      (e) =>
                        onChangefileTypeAction(e.target.value)
                    } >
                    <AvRadio label="Select Template"
                      value="template-section" />
                    <AvRadio label="Record"
                      value="record-section" />
                  </AvRadioGroup>
                </div>
                <div id="record-section" style={{ display: "none" }}>
                  <div className="row">
                    <div className="col-12" >
                      <AudioReactRecorder style={{ height: 100 }} state={recordState}
                        onStop={onStop}
                        backgroundColor="rgb(255,255,255)"
                      />
                      <audio id="audio"
                        controls src={
                          audioData ? audioData.url : null
                        }>
                      </audio>
                    </div >
                    <div className="col-12 mt-2" >
                      <Button type="button" className="m-2"
                        color="secondary"
                        onClick={start} >
                        Start </Button>
                      <Button type="button"
                        color="secondary"
                        onClick={stop} >
                        Stop </Button>
                    </div >
                  </div>
                </div>

                <div className="mb-3" id="template-section" style={{ display: "block" }}>
                  <div className="MainTZone SendRVM One">
                    <Select
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      // value={options.filter(({ id }) => id ===country_code)}
                      isMulti={false}
                      options={templates}
                      classNamePrefix="select2-selection"
                      name="country_id"
                      placeholder={"Select rvm template"}
                      onChange={(e) => {
                        setSmsBody(e.id);
                      }}
                    />
                  </div>
                  <AvField
                    type="hidden"
                    name="tos"
                    value={info.mobile ? info.mobile : info.phone}
                  />
                  <AvField type="hidden" name="activity" value={"RVM: send"} />
                  <AvField type="hidden" name="template_id" value={smsBody} />
                </div>
              </ModalBody>
              <ModalFooter>
                <Button color="info" type="submit">
                  Send
                </Button>
                <Button color="danger" onClick={() => setDynamicModal(false)}>
                  Cancel
                </Button>
              </ModalFooter>
            </div>
          ) : DynamicModalName == "call" ? (
            <div className="call">
              <ModalBody>
                <button
                  onClick={() => {
                    setDynamicModal(false);
                    setSmsBody("");
                    setToken("");
                    setSubject("");
                    setHtml("");
                    setScript("");

                  }}
                  className="btn btn-link pull-right"
                  type="button"
                >
                  <i className="fas fa-times"> </i>
                </button>
                <Tabs defaultActiveKey="existing" className="mb-3">

                  <Tab eventKey="existing" title="Dialer">
                    <Row>
                      <div className="col-lg-4"></div>
                      <div className="col-lg-4">
                        <div className="mb-3">
                          {callToken ? (
                            <Phone paramsters={paramsters} getCallSid={getCallSid}> </Phone>
                          ) : (
                            <p> Loading... </p>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-4"></div>
                    </Row>
                  </Tab>
                  <Tab eventKey="new" title="Script Templates">
                    <ModalBody>
                      <Col className="col-12">
                        <select
                          onChange={(e) => getScriptById(e.target.value)}
                          className="form-control"
                        >
                          <option value=""> Select Script Templates </option>
                          {scripts.map((row, i) => {
                            return (
                              <option key={"script-option-" + i} value={`${row.id}`}>
                                {row.name}
                              </option>
                            );
                          })}
                        </select>
                        {script != "" && scripts.length > 0 && (
                          <div
                            className="mt-3 script-render"
                            dangerouslySetInnerHTML={{
                              __html: script,
                            }}
                          />
                        )}
                      </Col>
                    </ModalBody>
                  </Tab>

                </Tabs>


              </ModalBody>
            </div>
          ) : DynamicModalName == "meeting" ? (
            <div className="meeting">
              <div className="modal-head top-heading">
                <h2> Schedule an event </h2>
                <button
                  onClick={() => {
                    setDynamicModal(false);
                    setSmsBody("");
                    setToken("");
                    setSubject("");
                    setHtml("");
                    setEventData({});
                  }}
                  type="button"
                  className="btn btn-link text-white"
                >
                  <i className="fas fa-times"> </i>
                </button>
              </div>
              <ModalBody>
                <div className="mb-3 row">
                  <div className="col-md-12 mb-3">
                    <AvField
                      name="event_title"
                      label="Title"
                      className="form-control"
                      required
                      type="text"
                      value={eventData.event_title}
                    />
                  </div>
                  <Col md={6}>
                    <AvGroup>
                      <label> MainTime Zone </label>
                      <div className="MainTZone MainTime One">
                        <Select
                          value={maintimezone}
                          getOptionLabel={(option) => option.label}
                          getOptionValue={(option) => option.id}
                          isMulti={false}
                          options={maintimezones}
                          classNamePrefix="select2-selection"
                          className={`${maintimezone != ""
                            ? "is-touched is-dirty av-valid"
                            : "is-touched is-pristine av-invalid is-invalid"
                            }`}
                          name="time_zone"
                          onChange={(e) => {
                            settimezone(e);
                          }}
                        />
                      </div>
                      <AvInput
                        type="hidden"
                        required
                        name="time_zone_1"
                        value={maintimezone.id}
                      />
                      <AvFeedback> This is required * </AvFeedback>
                    </AvGroup>
                  </Col>
                  <Col md={6}>
                    <AvGroup>
                      <label> Time Zone </label>
                      <div className="MainTZone MainTime TwoTimeZone">
                        <Select
                          value={timezone}
                          getOptionLabel={(option) => option.label}
                          getOptionValue={(option) => option.id}
                          isMulti={false}
                          options={timeZone_data}
                          classNamePrefix="select2-selection"
                          className={`${timezone != ""
                            ? "is-touched is-dirty av-valid"
                            : "is-touched is-pristine av-invalid is-invalid"
                            }`}
                          name="time_zone1"
                          onChange={(e) => {
                            setTimeZone(e);
                            if (eventData.meeting_id != undefined && eventData.meeting_id > 0) {
                              scheduleEvent(eventData, eventData.server_date, e.id)
                            }
                          }}
                        />
                      </div>
                      <AvInput
                        type="hidden"
                        required
                        name="time_zone"
                        value={timezone.id}
                      />
                      <AvFeedback> This is required * </AvFeedback>
                    </AvGroup>
                    {
                      eventData.meeting_id && (
                        <AvField
                          type="hidden"
                          name="meeting_id"
                          value={eventData.meeting_id}
                        />
                      )
                    }
                  </Col>
                  {
                    eventData.meeting_id ? (
                      <>
                        <div className="col-md-12 mb-3">
                          <AvField
                            name="server_date"
                            label="Select Date"
                            className="form-control"
                            required
                            type="date"
                            value={eventData.server_date}
                            onChange={(e) => scheduleEvent(eventData, e.target.value)}
                          />
                          <AvInput
                            name="body"
                            value={`A meeting schedule  request sent  to ({client_name}) {link}`}
                            type="hidden"
                          />
                          <AvInput
                            name="calendar_id"
                            value={defaultCalendar}
                            type="hidden"
                          />
                        </div>
                        <div className="col-md-12 mb-3">
                          <AvField
                            className="select form-control"
                            type="select"
                            name="server_time"
                            label="Slots"
                            required
                            id="server_time"
                            value={eventData.from_time_convert}
                          >
                            <option value="">Time Slot</option>
                            {
                              eventData?.time_slots != undefined && eventData?.time_slots.map((slot, i) => {
                                return <option key={i} value={slot}>{slot}</option>
                              })
                            }


                          </AvField>
                          {/* <AvField
                            name="server_time"
                            label="Select Time"
                            className="form-control"
                            required
                            type="time"
                            value={eventData.from_time}
                          /> */}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="col-md-12 mb-3">
                          <AvField
                            name="server_date"
                            label="Select Date"
                            className="form-control"
                            required
                            type="date"
                            value={eventData.server_date}
                          />
                          <AvInput
                            name="body"
                            value={`A meeting schedule  request sent  to ({client_name}) {link}`}
                            type="hidden"
                          />
                          <AvInput
                            name="calendar_id"
                            value={defaultCalendar}
                            type="hidden"
                          />
                        </div>
                        <div className="col-md-12 mb-3">
                          <AvField
                            name="server_time"
                            label="Select Time"
                            className="form-control"
                            required
                            type="time"
                            value={eventData.from_time}
                          />
                        </div>
                      </>
                    )
                  }

                  {
                    eventData.meeting_id && (
                      <Col md={12}>
                        <AvField
                          className="select form-control"
                          type="select"
                          name="status"
                          label="Status"
                          required
                          id="event_status"
                          value={eventData.status}
                        >
                          <option value="pending">Pending</option>
                          <option value="confirm">Confirm</option>
                          <option value="expired">Expired</option>


                        </AvField>
                      </Col>
                    )
                  }
                </div>
              </ModalBody>
              <ModalFooter>
                {
                  eventData.meeting_id && (
                    <Button color="warning" onClick={() => { deleteTaskAndEvents(eventData.meeting_id) }}>
                      Delete this schedule
                    </Button>
                  )
                }
                <Button color="info" type="submit">
                  Schedule
                </Button>
                <Button color="danger" onClick={() => setDynamicModal(false)}>
                  Cancel
                </Button>
              </ModalFooter>
            </div>
          ) : (
            <div className=""> </div>
          )}
        </AvForm>
      </Modal>
      <Modal
        isOpen={EmailModal}
        fade={true}
        size="lg"
        toggle={() => {
          setEmailModal(false);
          setSmsBody("");
          setToken("");
          setSubject("");
          setHtml("");

        }}
      >
        <AvForm
          onValidSubmit={handleEmailSubmit}
          className="needs-validation"
          enctype="multipart/form-data"
        >
          <div className="email">
            <div className="modal-head top-heading">
              <h2> Send Email</h2>
              <button
                onClick={() => {
                  setEmailModal(false);
                  setSmsBody("");
                  setToken("");
                  setSubject("");
                  setHtml("");
                  setVerfiedFromEmailId("");

                }}
                className="btn btn-link text-white"
              >
                <i className="fas fa-times"> </i>
              </button>
            </div>
            <ModalBody>
              <div className="mb-3">
                <div className="ClientDetails Two">
                  <Select
                    getOptionLabel={(option) => `${option.from_name} - ${option.from_email}`}
                    getOptionValue={(option) => option.id}
                    isMulti={false}
                    options={verifiedFromEmail}
                    classNamePrefix="select2-selection"
                    placeholder={"Select From Email"}
                    autocomplete="off"
                    value={defaultFromEmail}
                    name="froms"
                    onChange={(option) => {
                      setVerfiedFromEmailId(option.id);
                      SetDefaultFromEmail(option);
                    }}
                  />
                </div>
              </div>
              <div className="mb-3">
                <div className="ClientDetails One">
                  <Select
                    getOptionLabel={(option) => option.title}
                    getOptionValue={(option) => option.id}
                    isMulti={false}
                    options={templates}
                    classNamePrefix="select2-selection"
                    autocomplete="off"
                    placeholder={"Select email template"}
                    name="country_id"
                    onChange={(e) => {
                      setSubject(e.title);
                      getEmailTemplateHtml(e.id);
                    }}
                  />
                </div>
                <AvField type="hidden" name="tos" value={info.email} />
                <AvField type="hidden" name="activity" value={"Email: sent"} />
              </div>
              <div className="row mb-3">
                <div className="col-md-12 mb-3">
                  <div className="MainTZone ClientDetails Two">
                    <Select
                      getOptionLabel={(option) => option.label}
                      getOptionValue={(option) => option.key}
                      isMulti={false}
                      options={tokens}
                      classNamePrefix="select2-selection"

                      placeholder={"Select Token"}
                      autocomplete="off"
                      name="country_id"
                      onChange={(e) => {
                        setToken(`{${e.key}}`);
                      }}
                    />
                  </div>
                </div>


                {html == "" && (
                  <div className="col-md-2 col-2">
                    <button
                      onClick={() => setSmsBody(`${smsBody} ${token}`)}
                      type="button"
                      className="btn btn-primary"
                    >
                      Insert Body
                    </button>
                  </div>
                )}
                <div className="col-md-4 col-6">
                  <button
                    onClick={() => setSubject(`${subject} ${token}`)}
                    type="button"
                    className="btn btn-primary"
                  >
                    Insert Subject
                  </button>
                </div>
              </div>
              <div className="mb-3">
                <AvField
                  className="form-control"
                  autocomplete="off"
                  type="text"
                  name="subject"
                  placeholder="Subject"
                  required
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                />
              </div>
              {html == "" ? (
                <div className="mb-3"> 
                  {/* <AvField
                    className="form-control"
                    autocomplete="off"
                    type="textarea"
                    name="body"
                    placeholder="Enter body"
                    required
                    value={smsBody}
                    onChange={(e) => setSmsBody(e.target.value)}
                  /> */}
                  <SunEditor name="body"
                    setOptions={
                      {
                        height: 300,
                        width: '100%',
                        resizeEnable: 'false',
                        buttonList: [
                          ["undo", "redo", "font", "fontSize", "formatBlock"],
                          [
                            "bold",
                            "underline",
                            "italic",
                            "strike",
                            "subscript",
                            "superscript",
                            "removeFormat",
                          ],
                          [
                            "fontColor",
                            "hiliteColor",
                            "outdent",
                            "indent",
                            "align",
                            "horizontalRule",
                            "list",
                            "table",
                          ],
                          [
                            "link",
                            "image",
                            "video",
                            "fullScreen",
                            "showBlocks",
                            "codeView",
                            "preview",
                          ],

                        ],
                        proportion: 'Constrain proportions assasa',
                      }
                    }
                    setContents={smsBody}
                    value={smsBody}
                    onChange={(e) => {setSmsBody(e);}}
                  />
                  
                </div>
              ) : (
                <>
                  <div
                    id="custom-template"
                    className="height-500 custom-scroll"
                    dangerouslySetInnerHTML={{ __html: html }}
                  ></div>
                  {/* <AvField type="hidden" name="body" value={document.getElementById('custom-template')}/> */}
                </>
              )}
            </ModalBody>
            <ModalFooter>
              <button
              className="btn btn-primary waves-effect waves-light"
              type="submit"
              id="edit-email-content"
              >
                Send
              </button>
              <Button color="danger" onClick={() => {
                setEmailModal(false);
                setSmsBody("");
                setToken("");
                setSubject("");
                setHtml("");
                setVerfiedFromEmail("");
              }}>
                Cancel
              </Button>
            </ModalFooter>
          </div>
        </AvForm>
      </Modal>
      <Modal
        isOpen={sentEmailTemplateModal}
        fade={true}
        size="lg"
        toggle={() => {
          setSentEmailTemplateModal(false);
          setActivityData({});
        }}
      >
        <div className="email">
          <div className="modal-head top-heading">
            <h2 className="text-capitalize"> {activityData.type} </h2>
            <button
              onClick={() => {
                setSentEmailTemplateModal(false);
                setActivityData({});
              }}
              className="btn btn-link text-white"
            >
              <i className="fas fa-times"> </i>
            </button>
          </div>
          <ModalBody>
            <div className="row campddd_log">
              <div className="col-md-6">
                <h6><span><b>From:</b> </span> {activityData.froms}</h6>
                <h6><span><b>To:</b> </span> {activityData.tos}</h6>
                <h6><span><b>Direction:</b> </span> {activityData.direction}</h6>
                <h6><span><b>Status:</b> </span> {activityData.status}</h6>
              </div>
              <div className="col-md-6">
                <h6><span><b>Type:</b> </span> {activityData.type}</h6>
                {/* <h6><span><b>Duration:</b> </span> {activityData.call_duration}</h6> */}

              </div>
              {
                activityData.type == 'email' && (
                  <div className="col-md-12">
                    <h6><span><b>Subject:</b> </span> {activityData.sub_subject}</h6>
                    {/* <h4><span><b>Duration:</b> </span> {activityData.call_duration}</h4> */}

                  </div>
                )
              }

              {
                activityData.media_url != '' && (
                  <div className="col-md-12">
                    <h6><span><b>Attachment/Media:</b> </span> <a href={activityData.media_url} target="_blank">View</a> </h6>
                    {/* <h4><span><b>Duration:</b> </span> {activityData.call_duration}</h4> */}

                  </div>
                )
              }

              {
                activityData.type == 'email' && activityData.sub_body && (
                  <div className="row">
                    <div className="col-md-12">
                      <div dangerouslySetInnerHTML={{ __html: activityData.sub_body }} />
                    </div>
                  </div>
                )
              }

              {
                (activityData.type == 'rvm' || activityData.type == 'voice' || activityData.type == 'call') && activityData.body && (
                  <div className="row">
                    <div className="col-md-12">
                      <audio controls autoplay muted>
                        <source src={activityData.sub_body} />
                        Your browser does not support the audio element.
                      </audio>

                    </div>
                  </div>
                )
              }


            </div>
          </ModalBody>
        </div>
      </Modal>
      <Modal
        isOpen={taskCompleteModal}
        fade={false}
        toggle={() => setTaskCompleteModal(false)}
      >
        <ModalBody>
          <div className="warning-box text-center">
            <span className="warning">
              <i className="mdi mdi-alert-circle"> </i>
            </span>
            <h3> Are you sure ? </h3> <p> You want to mark this complete ? </p>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="info" onClick={() => completeTask()}>
            Yes, Change it
          </Button>
          <Button color="danger" onClick={() => setTaskCompleteModal(false)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={reminderModal}
        fade={true}
        size="lg"
        toggle={() => {
          setReminderModal(false);
        }}
      >
        <div className="email">
          <div className="modal-head top-heading">
            <h2> Appointment Reminder List </h2>
            <button
              onClick={() => {
                setReminderModal(false);
              }}
              className="btn btn-link text-white"
            >
              <i className="fas fa-times"> </i>
            </button>
          </div>
          <ModalBody>
            <table className="table table-striped  table-bordered">
              <thead>
                <tr>
                  <th>Appointment ID</th>
                  <th>Status</th>
                  <th>Reminder For</th>
                  <th>Reminder Time</th>
                </tr>
              </thead>
              <tbody>
                {
                  reminders.map((row, i) => {
                    return (
                      <tr key={i}>
                        <td>{row.reminder_id}</td>
                        <td>{row.status}</td>
                        <td>{row.reminder_for}</td>
                        <td>{row.reminder_datetime}</td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </table>
          </ModalBody>
        </div>
      </Modal>

      {
        page13Class == "slide-in" && (
          <div className={`page2 scripts-only ${page13Class} custom-scroll`}>
            <div>
              <div className="modal-head top-heading">
                <h2> Scripts </h2>
                <button
                  onClick={() => {
                    setpage13Class("slide-out2");
                  }}
                  className="btn btn-link text-white"
                >
                  <i className="fas fa-times"> </i>
                </button>
              </div>

            </div>
            <div className="">
              <div className="form-group px-3 py-3">
                <select
                  onChange={(e) => getScriptById(e.target.value)}
                  className="form-control"
                >
                  <option value=""> Select Script Templates </option>
                  {scripts.map((row, i) => {
                    return (
                      <option key={"script-option-" + i} value={`${row.id}`}>
                        {row.name}
                      </option>
                    );
                  })}
                </select>
              </div>
              {script != "" && scripts.length > 0 && (
                <div
                  className="mt-3 script-render"
                  dangerouslySetInnerHTML={{
                    __html: script,
                  }}
                />
              )}
            </div>
          </div>
        )
      }

      {page4Class == "slide-in" && (
        <div className={`page3 ${page4Class} custom-scroll`}>
          <div>
            <div className="modal-head top-heading">
              <h2> Add Task </h2>
              <button
                onClick={() => {
                  setpage4Class("slide-out2");
                }}
                className="btn btn-link text-white"
              >
                <i className="fas fa-times"> </i>
              </button>
            </div>
            <CreateTask
              cancelSlider={cancelTask}
              compannyId={info.client_id}
              from="companyDetails"
            />
          </div>
        </div>
      )}
      {page5Class == "slide-in" && (
        <div className={`page3 ${page5Class} custom-scroll deal-assign`}>
          <div>
            <div className="modal-head top-heading">
              <h2> Pipeline Status </h2>
              <button
                onClick={() => {
                  setpage5Class("slide-out2");
                  setPipelineModal(false);
                }}
                className="btn btn-link text-white"
              >
                <i className="fas fa-times"> </i>
              </button>
            </div>
            <Card>
              <CardBody>
                <div className="pipeline">
                  <div className="MonthlyDropDown mb-1">
                    <select defaultValue={masterPipelineId} onChange={(e) => { getClientPipeline(e.target.value) }}
                      className="form-control w-100">
                      {masterPipelines.map((row, i) => {
                        return (
                          <option key={i} value={row.pipeline_id}>{row.label}</option>
                        )
                      }
                      )}
                    </select>
                  </div>
                  {pipelineStatus.map((pstatus, i) => {
                    return (
                      <div className="DealCards mb-2 border-1" key={i + '--' + pstatus.pipeline_id}>
                        <div key={i + '--' + pstatus.pipeline_id} className={`form-check form-check-right d-flex justify-content-between align-items-center ${pstatus.is_complete == 1 ? 'complete' : 'pending'}`}>
                          <label
                            className="form-check-label"
                            htmlFor="customCheck2"
                          >
                            {pstatus.name} {pstatus.final_pipeline == 1 && (`- ${pstatus.current_status}`)}
                          </label>
                          {
                            pstatus.final_pipeline == 0 && (
                              <input
                                key={i + '--' + pstatus.pipeline_id}
                                type="checkbox"
                                title="Mark as complete"
                                className="form-check-input"
                                //disabled={pstatus.is_complete == 1 || pstatus.status_assign == 0 ? true : false}
                                defaultChecked={pstatus.is_complete == 1 ? true : false}
                                onChange={(e) => (handleDealSubmit(pstatus.pipeline_id, 0, info.client_id, pstatus.final_pipeline))}
                              />
                            )
                          }

                        </div>
                        <ul className="mb-0 py-2">
                          {pstatus.pipeline_status.map((row, i) => {
                            return (
                              <li
                                disabled={pstatus.is_complete == 1 && pstatus.final_pipeline == 0 ? true : false}
                                onClick={() => {
                                  handleDealSubmit(row.pipeline_id, row.status_id, info.client_id, pstatus.final_pipeline);
                                }}
                                key={"p_status-" + i}
                              >
                                <div className=""
                                  style={{
                                    backgroundColor: pstatus.is_complete == 1 ? 'transparent' : row.color_code,
                                    padding: '3px',
                                    display: 'inline-block',
                                    width: '90%'
                                  }}>
                                  <p className={pstatus.is_complete != 1 && (row.color_code == '#fff' ? 'text-dark' : 'text-white')}>{row.title}</p>
                                </div>
                                {/* {row.is_complete == 1 && (<i className="fas fa-check code-green"></i>)} */}
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    );
                  })}
                </div>
              </CardBody>
            </Card>
          </div>
        </div>
      )}

      {
        page12Class == "slide-in" && (
          <div className={`page3 ${page12Class} custom-scroll invoice-details`}>
            <div>
              <div className="modal-head top-heading">
                <h2> Assessments </h2>
                <button
                  onClick={() => {
                    setpage12Class("slide-out2");
                  }}
                  className="btn btn-link text-white"
                >
                  <i className="fas fa-times"> </i>
                </button>
              </div>
              <Card>
                <CardBody>
                  <div className="row">
                    <div className="col-md-12">
                      <button type="button" className="btn btn-link">
                        <i className="fas fa-file"> </i> View Assessments
                      </button>
                      <button onClick={() => {
                        setpage9Class("slide-in");
                      }} type="button" className="btn btn-link pull-right">
                        <i className="fas fa-plus"> </i> Add Assessment
                      </button>
                    </div>
                  </div>
                  <div className="table-responsive">
                    <table className="table table-bordered border-0">
                      <thead>
                        <tr>
                          <td> ASSESSMENT# </td>
                          <td> NAME </td>
                          <td> CREATED DATE </td>
                          <td> ACTION </td>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          activity.map((row, i) => {
                            return (
                              <tr key={i}>
                                <td>{row.assessment_id}</td>
                                <td>{row.name}</td>
                                <td>{row.created_at}</td>
                                <td>
                                  <Link
                                    type="button"
                                    color="info"
                                    className="btn btn-link btn-sm mb-1 mr-5"
                                    to={
                                      "/assessment/" + bcrypt(row.assessment_id) + "/summary"
                                    }
                                    title="Assessment Calculator"
                                  >
                                    <i className="fas fa-calculator"></i>
                                  </Link>
                                  <Link
                                    type="button"
                                    color="info"
                                    className="btn btn-link btn-sm mb-1 mr-5"
                                    to={
                                      "/progress/tracker/" + bcrypt(row.assessment_id) + "/" + row.running_category
                                    }
                                    title="Profit Center"
                                  >
                                    <i className="far fa-money-bill-alt"></i>
                                  </Link>
                                </td>

                              </tr>
                            )
                          })
                        }
                      </tbody>

                    </table>
                  </div>
                </CardBody>
              </Card>
            </div>
          </div>
        )
      }

      {page6Class == "slide-in" && (
        <div className={`page3 ${page6Class} custom-scroll invoice-details`}>
          <div>
            <div className="modal-head top-heading">
              <h2> Products </h2>
              <button
                onClick={() => {
                  setpage6Class("slide-out2");
                }}
                className="btn btn-link text-white"
              >
                <i className="fas fa-times"> </i>
              </button>
            </div>
            <Card>
              <CardBody>
                <div className="row">
                  <div className="col-md-12">
                    <button type="button" className="btn btn-link">
                      <i className="fas fa-file"> </i> View Products
                    </button>
                    <Link
                      to={"/invoice/add/" + bcrypt(info.client_id)}
                      className="btn btn-link pull-right"
                    >
                      <i className="fas fa-plus"> </i> Assign Product
                    </Link>
                  </div>
                </div>
                <div className="table-responsive">
                  <table className="table table-bordered border-0">
                    <thead>
                      <tr>
                        <th> Invoice# </th> <th> Start Date </th>
                        <th> Expiry Date </th> <th> Type(Recurring#) </th>
                        <th> Total Price </th> <th> Status </th>
                        <th> Action </th>
                      </tr>
                    </thead>
                    <tbody>
                      {invoices.map((row, i) => {
                        return (
                          <tr key={"invoice-" + i}>
                            <td>
                              #IN - {String(row.invoice_id).padStart(5, "0")}
                            </td>
                            <td> {row.start_date} </td>
                            <td> {row.expire_date} </td>
                            <td> {row.type} </td>
                            <td> {getInvoiceCurrency(row.currency_code)}{row.total_price} </td>
                            <td> {row.payment_status == 'Paid' ? <p className="badge bg-success">{row.payment_status}</p> : row.payment_status == 'Unpaid' ? <p className="badge bg-danger">{row.payment_status}</p> : <p>{row.payment_status}</p>} </td>
                            <td>
                              <Link
                                to={
                                  "/invoice/preview/" + bcrypt(row.invoice_id)
                                }
                                className="btn btn-link"
                              >
                                <i className="fas fa-eye"> </i>
                              </Link>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </CardBody>
            </Card>
          </div>
        </div>
      )}
      {page7Class == "slide-in" && (
        <div className={`page3 ${page7Class} custom-scroll contract-details`}>
          <div>
            <div className="modal-head top-heading">
              <h2> Contracts </h2>
              <button
                onClick={() => {
                  setpage7Class("slide-out2");
                }}
                className="btn btn-link text-white"
              >
                <i className="fas fa-times"> </i>
              </button>
            </div>
            <ClientContract
              cancelSlider={() => setpage7Class("slide-out2")}
              clientId={info.client_id}
              from="clientDetails"
            />
          </div>
        </div>
      )}
      {page8Class == "slide-in" && (
        <div
          style={{ width: "auto", minWidth: "50%" }}
          className={`page3 ${page8Class} custom-scroll attachment-details`}
        >
          <div>
            <div className="modal-head top-heading">
              <h2>Attachment </h2>
              <button
                onClick={() => {
                  setpage8Class("slide-out2");
                  setSubject("");
                  setSmsBody("");
                  setToken('');
                }}
                className="btn btn-link text-white"
              >
                <i className="fas fa-times"></i>
              </button>
            </div>
            <Tabs defaultActiveKey="existing" className="mb-3">
              <Tab eventKey="existing" title="Existing Attachment">
                <Row>
                  <Col className="col-12">
                    <Card>
                      <CardBody>
                        <div className="table-responsive">
                          <table className="table table-bordered">
                            <thead>
                              <tr>
                                <td>Title </td>
                                <td>Created At</td>
                                <td>Action</td>
                              </tr>
                            </thead>
                            <tbody>
                              {attachment &&
                                attachment.id &&
                                JSON.parse(attachment.attachments).map(
                                  (row, k) => {
                                    return (
                                      <tr key={k}>
                                        <td>{row.file_title}</td>
                                        <td>{row.created_at}</td>
                                        <td>
                                          <a
                                            target="_blank"
                                            href={`${AttachementsUrl}${row.file}`}
                                          >
                                            <i className="fas fa-download"></i>
                                          </a>
                                          <button
                                            className="btn btn-link"
                                            title="remove"
                                            onClick={() =>
                                              removeAttachement(
                                                attachment.id,
                                                k
                                              )
                                            }
                                          >
                                            <i className="fas fa-times"></i>
                                          </button>
                                        </td>
                                      </tr>
                                    );
                                  }
                                )}
                            </tbody>
                          </table>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Tab>
              <Tab eventKey="new" title="Add New">
                <ModalBody>
                  <Col className="col-12">
                    {
                      loader && (
                        <AvForm
                          onValidSubmit={handleAtSubmit}
                          ref={(c) => (this_form = c)}
                          className="needs-validation"
                          id="selection-attachementaa"
                        >
                          <div className="mb-3">
                            <AvField
                              className="form-control"
                              type="text"
                              name="file_title"
                              label="Title"
                              placeholder="Enter attachment title"
                              required
                            />
                          </div>
                          <div className="mb-3">
                            <AvInput
                              name="attachment_file"
                              label="Image"
                              className="form-control"
                              multiple={false}
                              type="file"
                              accept=".gif, .jpg, .png, .doc"
                              onChange={(e) => {
                                setattachmentFile(e.target.files[0]);
                              }}
                            />
                            <p className="text-danger"><b>Note:</b>The attachment file must be a file of type: jpeg, png, jpg, pdf, docx, doc</p>
                          </div>
                          <div className="button-items">
                            <Button
                              className="btn-ld"
                              color="primary"
                              type="submit"
                            >
                              Add
                            </Button>
                          </div>
                        </AvForm>
                      )
                    }
                  </Col>
                </ModalBody>
              </Tab>
              <Tab eventKey="request" title="Send Request 12">
                <ModalBody>
                  <Col className="col-12">
                    <AvForm
                      onValidSubmit={handleAttachementRequestSubmit}
                      ref={(c) => (this_form = c)}
                      className="needs-validation"
                    >
                      <div className="col-md-12 mb-3">
                        <div className="MainTZone MainTime Three">
                          <Select
                            getOptionLabel={(option) => option.label}
                            getOptionValue={(option) => option.key}
                            // value={options.filter(({ id }) => id ===country_code)}
                            isMulti={false}
                            options={tokens}
                            classNamePrefix="select2-selection"
                            placeholder="Select Token"
                            name="country_id"
                            onChange={(e) => {
                              setToken(`{${e.key}}`);
                            }}
                          />
                        </div>
                        <div className="row mb-3 mt-3">
                          <div className="col-md-4 col-6">
                            <button
                              onClick={() =>
                                setSmsBody(`${smsBody} ${token}`)
                              }
                              type="button"
                              className="btn btn-primary"
                            >
                              Insert Body
                            </button>
                          </div>
                          <div className="col-md-4 col-6">
                            <button
                              onClick={() =>
                                setSubject(`${subject} ${token}`)
                              }
                              type="button"
                              className="btn btn-primary"
                            >
                              Insert Subject
                            </button>
                          </div>
                        </div>

                        <div className="mb-3">
                          <AvField
                            className="form-control"
                            type="text"
                            name="subject"
                            placeholder="Subject"
                            required
                            value={subject}
                            onChange={(e) => setSubject(e.target.value)}
                          />
                        </div>
                        <div className="mb-3">
                          <AvField
                            className="form-control"
                            type="textarea"
                            name="body"
                            placeholder="Enter body"
                            required
                            value={smsBody}
                            onChange={(e) => setSmsBody(e.target.value)}
                          />
                        </div>

                      </div>
                      <div className="button-items">
                        <Button
                          className="btn-ld"
                          color="primary"
                          type="submit"
                        >
                          Send a request
                        </Button>
                      </div>
                    </AvForm>
                  </Col>
                </ModalBody>
              </Tab>
            </Tabs>
          </div>
        </div>
      )}
      {page8Class == "slide-in" && (
        <div
          style={{ width: "auto", minWidth: "50%" }}
          className={`page3 ${page8Class} custom-scroll attachment-details AttachmentsPopup`}
        >
          <div>
            <div className="modal-head top-heading">
              <h2>Attachment</h2>
              <button
                onClick={() => {
                  setpage8Class("slide-out2");
                  setSubject("");
                  setSmsBody("");
                  setToken('');
                }}
                className="btn btn-link text-white"
              >
                <i className="fas fa-times"></i>
              </button>
            </div>
            <Tabs defaultActiveKey="existing" className="mb-3">
              <Tab eventKey="existing" title="Existing Attachment">
                <Row>
                  <Col className="col-12">
                    <Card>
                      <CardBody>
                        <div className="table-responsive">
                          <table className="table table-bordered border-0">
                            <thead>
                              <tr>
                                <td>Title </td>
                                <td>Created At</td>
                                <td>Action</td>
                              </tr>
                            </thead>
                            <tbody>
                              {attachment &&
                                attachment.id &&
                                JSON.parse(attachment.attachments).map(
                                  (row, k) => {
                                    return (
                                      <tr key={k}>
                                        <td>{row.file_title}</td>
                                        <td>{row.created_at}</td>
                                        <td>
                                          <a
                                            target="_blank"
                                            href={`${AttachementsUrl}${row.file}`}
                                          >
                                            <i className="fas fa-download"></i>
                                          </a>
                                          <button
                                            className="btn btn-link"
                                            title="remove"
                                            onClick={() =>
                                              removeAttachement(
                                                attachment.id,
                                                k
                                              )
                                            }
                                          >
                                            <i className="fas fa-times"></i>
                                          </button>
                                        </td>
                                      </tr>
                                    );
                                  }
                                )}
                            </tbody>
                          </table>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Tab>
              <Tab eventKey="new" title="Add New">
                <ModalBody>
                  <Col className="col-12">
                    {
                      loader && (
                        <AvForm
                          onValidSubmit={handleAtSubmit}
                          ref={(c) => (this_form = c)}
                          className="needs-validation"
                          id="selection-attachement"
                        >
                          <div className="mb-3">
                            <AvField
                              className="form-control"
                              type="text"
                              name="file_title"
                              label="Title"
                              placeholder="Enter attachment title"
                              required
                            />
                          </div>
                          <div className="mb-3">
                            <AvInput
                              name="attachment_file"
                              label="Image"
                              className="form-control"
                              multiple={false}
                              type="file"
                              accept=".gif, .jpg, .png, .doc"
                              required
                              onChange={(e) => {
                                setattachmentFile(e.target.files[0]);
                              }}
                            />
                            <p className="text-danger"><b>Note:</b>The attachment file must be a file of type: jpeg, png, jpg, pdf, docx</p>
                          </div>
                          <div className="button-items">
                            <Button
                              className="btn-ld"
                              color="primary"
                              type="submit"
                            >
                              Add
                            </Button>
                          </div>
                        </AvForm>
                      )
                    }
                  </Col>
                </ModalBody>
              </Tab>
              <Tab eventKey="request" title="Send Request">
                <ModalBody>
                  <Col className="col-12">
                    <AvForm
                      onValidSubmit={handleAttachementRequestSubmit}
                      ref={(c) => (this_form = c)}
                      className="needs-validation"
                    >
                      <div className="col-md-12 mb-3">
                        <div className="MainTZone">
                          <Select
                            getOptionLabel={(option) => option.label}
                            getOptionValue={(option) => option.key}
                            // value={options.filter(({ id }) => id ===country_code)}
                            isMulti={false}
                            options={tokens}
                            classNamePrefix="select2-selection"
                            placeholder="Select Token"
                            name="country_id"
                            onChange={(e) => {
                              setToken(`{${e.key}}`);
                            }}
                          />
                        </div>
                        <div className="row mb-3 mt-3">
                          <div className="col-md-6 col-12">
                            <button
                              onClick={() =>
                                setSmsBody(`${smsBody} ${token}`)
                              }
                              type="button"
                              className="btn btn-primary"
                            >
                              Insert Body
                            </button>
                            <button
                              onClick={() =>
                                setSubject(`${subject} ${token}`)
                              }
                              type="button"
                              className="btn btn-primary m-2 mt-0 mb-0"
                            >
                              Insert Subject
                            </button>
                          </div>
                        </div>
                        <div className="mb-3">
                          <AvField
                            className="form-control"
                            type="text"
                            name="subject"
                            placeholder="Subject"
                            required
                            value={subject}
                            onChange={(e) => setSubject(e.target.value)}
                          />
                        </div>
                        <div className="mb-3">
                          <AvField
                            className="form-control"
                            type="textarea"
                            name="body"
                            placeholder="Enter body"
                            required
                            value={smsBody}
                            onChange={(e) => setSmsBody(e.target.value)}
                          />
                        </div>

                      </div>
                      <div className="button-items">
                        <Button
                          className="btn-ld"
                          color="primary"
                          type="submit"
                        >
                          Send a request
                        </Button>
                      </div>
                    </AvForm>
                  </Col>
                </ModalBody>
              </Tab>
            </Tabs>
          </div>
        </div>
      )}
      {page9Class == "slide-in" && (
        <div className={`page3 ${page9Class} custom-scroll`}>
          <div>
            <div className="modal-head top-heading">
              <h2> New Assessment </h2>
              <button
                onClick={() => {
                  setpage9Class("slide-out2");
                }}
                className="btn btn-link text-white"
              >
                <i className="fas fa-times"> </i>
              </button>
            </div>
            <CreateAssessment
              cancelSlider={cancelAssessment}
              client_id={info.client_id}
              props={props}
              from="companyDetails"
            />
          </div>
        </div>
      )}
      {page10Class == "slide-in" && (
        <div className={`page3 ${page10Class} custom-scroll`}>
          <div>
            <div className="modal-head top-heading">
              <h2> Edit Assessment </h2>
              <button
                onClick={() => {
                  setpage10Class("slide-out2");
                }}
                className="btn btn-link text-white"
              >
                <i className="fas fa-times"> </i>
              </button>
            </div>
            <EditAssessment
              cancelSlider={cancelAssessment}
              client_id={info.client_id}
              assessment_id={assessment_id}
            />
          </div>
        </div>
      )}
      {page11Class == "slide-in" && (
        <div className={`page3 ${page11Class} custom-scroll contract-details`}>
          <div>
            <div className="modal-head top-heading">
              <h2> Trainings </h2>
              <button
                onClick={() => {
                  setpage11Class("slide-out2");
                }}
                className="btn btn-link text-white"
              >
                <i className="fas fa-times"> </i>
              </button>
            </div>
            <WayTrainingAssign trainingFor={'3'} type={"single"} userId={info.linked_user_id} />

            <div className="d-none">
              <AvForm
                onValidSubmit={assignTraining}
                // model={this.state.record}
                className="needs-validation"
              >
                <Card>
                  <CardBody>
                    {
                      !isObjectEmpty(info) && (
                        <>
                          <div className="mb-3 mt-1">
                            <label>Assign Training</label>
                            <div className="MainTZone Trainings One">
                              <Select
                                getOptionLabel={(option) => option.title}
                                getOptionValue={(option) => option.cat_id}
                                value={training.allTraining.filter(obj => training.userTraining.includes(obj.cat_id))}
                                options={training.allTraining}
                                isMulti={true}
                                classNamePrefix="select2-selection"
                                name="tags_id"
                                onChange={handleTrainingChange}
                              />
                            </div>
                            <AvField
                              type="hidden"
                              name="cat_ids"
                              value={training.userTraining.toString()}
                              required
                            />
                          </div>
                          <div className="Training">
                            <div className="row">
                              {
                                training.subCategories.map((row, i) => {
                                  return <div key={i} className="col-md-6 mb-5">
                                    <div className={`${training.userTraining.includes(row.cat_id) ? 'active img-page' : 'img-page'}`}>
                                      <button type="button" className="btn btn-link" onClick={() => {

                                        if (training.userTraining.includes(row.cat_id)) {
                                          setTraining(prevTraining => ({
                                            ...prevTraining,
                                            userTraining: training.userTraining.filter(item => item !== row.cat_id)
                                          }));
                                        } else {
                                          setTraining(prevTraining => ({
                                            ...prevTraining,
                                            userTraining: [...training.userTraining, row.cat_id]
                                          }));
                                        }
                                      }}>
                                        <h3 className="title-page">{row.title}</h3>
                                        <img src={`${AttachementsUrl}/user_2/training/${row.image}`} width={'100%'} />
                                      </button>
                                    </div>
                                  </div>;
                                })
                              }
                            </div>
                          </div>
                          {/* <div className="mb-3 mt-1">
                          <label>Assign Resource</label>
                          <Select
                            getOptionLabel={(option) => option.title}
                            getOptionValue={(option) => option.cat_id}
                            value={training.allResource.filter(obj => training.userResource.includes(obj.cat_id))}
                            options={training.allResource}
                            isMulti={true}
                            classNamePrefix="select2-selection"
                            name="tags_id"
                            onChange={handleResourceChange}
                          />
                          <AvField
                            type="hidden"
                            name="cat_ids"
                            value={training.userResource.toString()}
                          // required
                          />
                        </div> */}
                          <div className="row">
                            <div className="col-md-6 offset-md-3 mt-3">
                              <button
                                type="submit"
                                className="btn btn-info width-100-percent"
                              >
                                Assign
                              </button>
                            </div>
                          </div>
                        </>
                      )
                    }
                  </CardBody>
                </Card>
              </AvForm>
            </div>
          </div>
        </div>
      )}
      <Modal
        isOpen={pipelineModal}
        fade={true}
        size="md"
        className="hello"
        toggle={() => {
          setPipelineModal(false);
          setSelectedPipeline({});
        }}
      >
        <AvForm
          onValidSubmit={handleDealSubmit}
          // model={this.state.record}
          className="needs-validation"
        >
          <div className="sms">
            <div className="modal-head top-heading">
              <h2> Opportunity </h2>
              <button
                onClick={() => {
                  setPipelineModal(false);
                  setSelectedPipeline({});
                }}
                className="btn btn-link text-white"
              >
                <i className="fas fa-times"> </i>
              </button>
            </div>
            <ModalBody>
              <div className="mb-3">
                <AvField
                  className="select form-control"
                  type="select"
                  name="pipeline_id"
                  label="Pipeline"
                  required
                  disabled
                  defaultValue={pipeline.pipeline_id}
                >
                  <option value={pipeline.pipeline_id}>{pipeline.name}</option>
                </AvField>
              </div>
              <div className="mb-3">
                <AvField
                  className="select form-control"
                  type="select"
                  name="status_id"
                  label="Deal Status"
                  required
                  disabled
                  defaultValue={selectedPipeline.status_id}
                >
                  <option value={selectedPipeline.status_id}>
                    {selectedPipeline.title}
                  </option>
                </AvField>
              </div>
              <div className="mb-3 d-none">
                <AvField
                  className="select form-control"
                  type="select"
                  name="assigned_to"
                  label="Assign Member"
                  required
                >
                  <option value={""}> Select Member </option>
                  {coachPartners.map((row, i) => {
                    return (
                      <option key={"member-" + i} value={row.user_id}>
                        {row.name}
                      </option>
                    );
                  })}
                </AvField>
              </div>
              <div className="mb-3">
                <AvField
                  type="hidden"
                  name="client_id"
                  value={info.client_id}
                />
              </div>
              <div className="mb-3">
                <AvField
                  className="form-control"
                  type="textarea"
                  name="comments"
                  required
                  placeholder={"Enter comments"}
                />
              </div>
            </ModalBody>
            <ModalFooter>
              <Button color="info" type="submit">
                Assign
              </Button>
              <Button
                color="danger"
                onClick={() => {
                  setPipelineModal(false);
                  setSelectedPipeline({});
                }}
              >
                Cancel
              </Button>
            </ModalFooter>
          </div>
        </AvForm>
      </Modal>
    </React.Fragment>
  );
};
export default LeadDetails;