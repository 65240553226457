import React, { Component, Fragment } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Alert,
  CardTitle,
} from "reactstrap";
import ReactDatatable from "@ashvin27/react-datatable";
import Helmet from "react-helmet";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
  bcrypt,
  convertPhoneFormat,
  dcrypt,
  hasRole,
  isAllowed,
  uInfo,
  uToken,
} from "../../../useToken";
import { ApiUrl, ProductName, ToastAutoClose } from "../../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import Select from "react-select";
import { Link } from "react-router-dom";
import swal from 'sweetalert';
import $ from "jquery";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { Tooltip } from "react-bootstrap";
import Phone from "../../InitiateCall/Phone";
import { createBrowserHistory } from "history";
import favicon from "../../../assets/images/favicon.png";
import WayTrainingAssign from "./WayTrainingAssign";
import CallWidget from "./CallWidget";
import SunEditor, { buttonList } from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";

export default class UsersDatatableTables extends Component {
  constructor(props) {
    super(props);

    this.columns = [
      {
        text: <input type='checkbox' onChange={(e) => {
          if (e.target.checked) {
            $(".lead-checkbox").prop("checked", false);
            this.setState({ is_bulk_check: true })
            this.setState((prevState) => ({
              customFieldData: {
                ...prevState.customFieldData,
                ['client_ids']: [],
              },
            }));
            let x = this.state.records.map(lead => lead.client_id.toString());

            this.setState((prevState) => ({
              customFieldData: {
                ...prevState.customFieldData,
                ['client_ids']: [...prevState.customFieldData['client_ids'], ...x],
              },
            }));

            $(".lead-checkbox").prop("checked", true);
          } else {
            this.setState({ is_bulk_check: false })
            $(".lead-checkbox").prop("checked", false);
            this.setState((prevState) => ({
              customFieldData: {
                ...prevState.customFieldData,
                ['client_ids']: [],
              },
            }));
          }

        }} />,
        key: "client_id_checkbox",

        cell: (record, index) => {
          return <label> {" "}<input className="lead-checkbox" onChange={this.handleChange.bind(this)} type={'checkbox'} name="client_ids" value={record.client_id} /></label>;
        },
      },
      {
        text: "ACTIVITY",
        key: "login_status",
        cell: (record, index) => {
          return <div className="">{" "}<i className={`fas fa-circle code-${record.login_color_code}`}></i> {record.login_status}</div>
        },
      },
      {
        text: "ACTION",
        key: "assessment_id",
        cell: (record, index) => {
          if (record.assessment_id != 0) {
            return (
              <button
                key={index}
                onClick={() => this.props.history.push(`/assessment/${bcrypt(record.assessment_id)}/summary`)
                }
                className="waves-effect border-0"
              >
                <img height={50} className="img" src={favicon} />
              </button>
            )
          } else {
            return (
              <button
                key={index}
                onClick={() => this.createAssessment(record.client_id, `${record.first_name} Pathway`)}
                className={`btn btn-sm btn-primary m-2 rounded-100`}
              >START
              </button>
            )
          }
        }
        // cell: (record, index) => {
        //     return index + 1;
        // },
      },
      {
        text: "LAST LOGIN",
        key: "last_login",
        // cell: (record, index) => {
        //     return index + 1;
        // },
      },
      {
        text: "BILLING STATUS",
        key: "billing_status",
        cell: (record, index) => {
          return <button className={`btn btn-sm rounded-100 m-2 code-${record.billing_status == 'Active' ? 'green' : 'red'}`}  > {record.billing_status}</button>

        },
      },
      {
        text: "SUBSCRIPTION",
        key: "subscription_name",
        // cell: (record, index) => {
        //     return index + 1;
        // },
      },
      {
        text: "NAME",
        key: "first_name",
        cell: (record, index) => {
          return (
            <Fragment>
              <button
                key={index}
                onClick={() => this.props.history.push(
                  "/client/dialer/" +
                  bcrypt(record.client_id) +
                  "/" +
                  bcrypt(record.coach_id)
                )
                }
                className="waves-effect border-0 text-left"
              >
                <i className="fas fa-newspaper pr-5"> </i>
                {record.first_name + " " + record.last_name}
              </button>
            </Fragment>
          );
        },
      },
      {
        text: "MOBILE",
        key: "mobile",
      },
      {
        text: "EMAIL",
        key: "email",
      },
      {
        text: "QUICK CONNECT",
        key: "contact_info_notes",
        cell: (record, index) => {
          return (
            <Fragment>
              <div className="client-actions">
                <OverlayTrigger
                  placement="auto"
                  overlay={
                    <Tooltip id="overlay-example"> Create a note </Tooltip>
                  }
                >
                  <button
                    onClick={() => {
                      this.setState({
                        dynamicModal: true,
                        dynamicModalName: "Note",
                        selectedClient: record
                      });
                      this.getNotes(record.client_id, { 'note_type': 'manual' }, 'notes');
                    }}
                    className="btn btn-md file-active"
                  >
                    <i className="far fa-file-alt"> </i>
                  </button>
                </OverlayTrigger>
                {(record.mobile != '' || record.phone != '') ? (
                  record.phone_status == 1 ?
                    <OverlayTrigger
                      placement="auto"
                      overlay={
                        <Tooltip id="overlay-example"> Send SMS {record.phone ? record.phone : record.mobile} </Tooltip>
                      }
                    >
                      <button
                        onClick={() => {
                          this.setState({
                            dynamicModal: true,
                            dynamicModalName: "sms",
                            selectedClient: record
                          })
                          this.getSmsTemplate();
                        }}
                        className="btn btn-md sms-active"
                      >
                        <i className="far fa-comment"> </i>
                      </button>
                    </OverlayTrigger>
                    :
                    <button
                      title="Unsubscribe from sms"
                      className="btn btn-danger btn-md disableded sms-block"
                    >
                      <i className="far fa-comment"> </i>
                      {/* <i className="fas fa-ban" aria-hidden="true"></i> */}
                    </button>
                ) : (
                  <button
                    title="Phone not exist"
                    className="btn btn-md disableded sms-disable"
                  >
                    <i className="far fa-comment"> </i>
                    {/* <i className="fas fa-ban" aria-hidden="true"></i> */}
                  </button>
                )}
                {record.email != "" && record.email != null ? (
                  record.email_status == 1 ?
                    <OverlayTrigger
                      placement="auto"
                      overlay={
                        <Tooltip id="overlay-example">Send Email ({record.email})</Tooltip>
                      }
                    >
                      <button
                        onClick={() => {
                          this.setState({
                            emailModal: true,
                            dynamicModalName: "email",
                            selectedClient: record
                          });
                          this.getEmailTemplate();
                          this.getVerifiedFromEmail("");
                        }}
                        className="btn btn-md email-active"
                      >
                        <i className="fas fa-envelope"> </i>
                      </button>
                    </OverlayTrigger>
                    :
                    <button
                      className="btn btn-danger btn-md disableded email-block"
                      title="Unsubscribe from email"
                    >
                      <i className="fas fa-envelope"> </i>
                      <i className="fas fa-ban" aria-hidden="true"></i>
                    </button>
                ) : (
                  <button
                    title="Email not exist"
                    className="btn btn-md disableded email-disable"
                  >
                    <i className="fas fa-envelope"> </i>
                    <i className="fas fa-ban" aria-hidden="true"></i>
                  </button>
                )}
                {(record.mobile != '' || record.phone != '') ? (
                  record.phone_status == 1 ?
                    <OverlayTrigger
                      placement="auto"
                      overlay={
                        <Tooltip id="overlay-example">Dial Pad {record.phone ? record.phone : record.mobile}</Tooltip>
                      }
                    >

                      <CallWidget className="btn btn-md phone-active" info={{
                        phoneno: record.phone
                          ? record.phone
                          : record.mobile,
                        record: "false",
                        phone: record.phone,
                        mobile: record.mobile,
                        client_id: record.client_id,
                        coach_id: record.coach_id,

                      }} onPopupTrigger={console.log('call')} />

                      {/* <button
                        onClick={() => {
                          var phone_number = record.phone
                            ? record.phone
                            : record.mobile;
                          this.setState(prevState => ({
                            paramsters: {                   // object that we want to update
                              ...prevState.paramsters,    // keep all other key-value pairs
                              phoneno: phone_number,
                              record: "false",
                              phone: record.phone,
                              mobile: record.mobile,
                              client_id: record.client_id,
                              coach_id: record.coach_id,     // update the value of specific key
                            }
                          }));
                          this.setState({
                            dynamicModal: true,
                            dynamicModalName: "call",
                            selectedClient: record,
                            phoneno: phone_number,
                            phone: record.phone,
                            mobile: record.mobile,
                          });


                        }}
                        className="btn btn-md phone-active"
                      >
                        <i className="fas fa-phone-alt client-list"> </i>
                      </button> */}
                    </OverlayTrigger>
                    :
                    <button
                      title="Unsubscribe from phone"
                      className="btn btn-danger btn-md disableded top-btns phone-block"
                    >
                      <i className="fas fa-phone-alt client-list"> </i>
                      {/* <i className="fas fa-ban" aria-hidden="true"></i> */}
                    </button>
                ) : (
                  <button
                    title="Phone not exist"
                    className="btn btn-md disableded phone-disable"
                  >
                    <i className="fas fa-phone-alt client-list"> </i>
                    <i className="fas fa-ban" aria-hidden="true"></i>
                  </button>
                )}
              </div>
            </Fragment>
          )
        }
      },

      {
        text: "TASK MANAGER",
        key: "task_manager",
        cell: (record, index) => {
          if (record.assessment_id != 0) {
            return (
              <button
                key={index}
                className={`btn btn-sm rounded-100 m-2 border-3 code-${record.task_color_code}`}
              >{record.task_manager}
              </button>
            )
          } else {
            return '';
          }
        }
      },

      {
        text: "PROGRESSION",
        key: "progression",
        cell: (record, index) => {
          if (record.assessment_id != 0) {
            return (
              <button
                key={index}
                className={`btn btn-sm rounded-100 m-2 border-3 code-${record.task_color_code}`}
              >{record.progression}%
              </button>
            )
          } else {
            return '';
          }
        }
      }
      // {
      //   key: "action",
      //   text: "Action",
      //   cell: (record, index) => {
      //     return (
      //       <Fragment>
      //         {/* {isAllowed(uInfo(), ["can_edit"]) && ( */}
      //         <button
      //           type="button"
      //           color="info"
      //           className="btn btn-primary btn-sm mr-5"
      //           onClick={() => this.props.history.push(
      //             "/client/edit/" +
      //             bcrypt(record.client_id) +
      //             "/" +
      //             bcrypt(record.coach_id)
      //           )
      //           }
      //         // onClick={() => {
      //         //   this.setState({
      //         //     modal: !this.state.modal
      //         //   })
      //         // }}
      //         >
      //           Edit
      //         </button>
      //         {/* )} */} {/* {isAllowed(uInfo(), ["can_delete"]) && ( */}
      //         <button
      //           className="btn btn-danger btn-sm"
      //           onClick={this.deleteRecord.bind(this, record, index)}
      //         >
      //           Delete
      //         </button>
      //         {/* )} */}
      //       </Fragment>
      //     );
      //   },
      // },
    ];
    this.config = {
      page_size: 25,
      length_menu: [25, 100, 150, 250, 500, 1000, 1500, 2000],
      show_filter: true,
      show_pagination: true,
      button: {
        excel: false,
        print: true,
        csv: true,
      },
    };
    this.state = {
      records: [],
      coachPartners: [],
      total_pages: 0,
      modal: false,
      is_bulk_check: false,
      record: {},
      filter: {},
      page_name: "Client",
      customDate: true,
      ClientSubcribeModal: false,
      groups: [],
      currentClient: {
        id: "",
        email_status: 1,
        call_status: 1,
        groups: [],
        tags: [],
      },
      customFieldData: { client_ids: [] },
      page1Class: '',
      page2Class: '',
      phoneNumbers: [],
      quickDialer: [],
      slide: '',
      tokens: [],
      phoneNumbers: [],
      smsTemplates: [],
      dynamicModal: false,
      emailModal: false,
      dynamicModalName: 'note',
      selectedClient: {},
      token: '',
      html: '',
      callToken: null,
      phone_number: '',
      paramsters: {
        from_number: uInfo().outbound_phone,
      },
      notes: [],
      searchFilter: false,
      actionTitle: 'Action',
      noteType: 'manual',
      sendNote: false,
      verifiedFromEmail: [],
      verifiedFromEmailId:"",
      verifiedFromName: "",

    };
    this.identity = "proglobal";
  }

  createAssessment = async (client_id, name) => {
    fetch(`${ApiUrl}` + "add/assessment", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ name: name, client_id: client_id, assessment_currency: 'USD', module: 6 }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === true) {
          this.props.history.push(`/assessment/${bcrypt(data.data.assessment_id)}/summary`)
        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  handleChange(e) {
    console.log(this.state.is_bulk_check, 'is_bulk_check')

    const { name, value, checked } = e.target;
    if (!checked) {
      this.setState({ is_bulk_check: false })
      this.setState((prevState) => ({
        customFieldData: {
          ...prevState.customFieldData,
          ['client_ids']: this.state.customFieldData['client_ids'].filter(
            (color) => color !== value
          ),
        },
      }));
      $(e).prop("checked", false);
    } else {
      this.setState({ is_bulk_check: true })
      this.setState((prevState) => ({
        customFieldData: {
          ...prevState.customFieldData,
          ['client_ids']: [...prevState.customFieldData['client_ids'], value],
        },
      }));
    }

  }
  deleteRecord = (record, index) => {
    swal({
      title: "Are you sure?",
      text: "You want to remove this record.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((confirmation) => {
        if (confirmation) {
          fetch(`${ApiUrl}` + "delete/client", {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ id: record.client_id, coach_id: record.coach_id }),
          })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((data) => {
              if (data.status === true) {
                this.getData();
                // toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
              } else {
                toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
              }
            })
            //Then with the error genereted...
            .catch((error) => {
              console.error("Error:", error);
            });
        }
      });
  };

  componentDidMount() {
    const history = createBrowserHistory();
    if (history.location.state && (history.location.state.msg || history.location.state.back)) {
      this.openForm(); this.setState({
        slide: 'slide-in'
      });

      let state = { ...history.location.state };
      delete state.msg;
      delete state.back;
      history.replace({ ...history.location, state });
    }


    this.getData();
    this.getfilterData();
    this.getFormData();
    this.handleOutCall();
  }

  removeQuickDialer = (id) => {
    swal({
      title: "Are you sure?",
      text: "You want to remove this record.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((confirmation) => {
        if (confirmation) {
          fetch(`${ApiUrl}` + "remove/quick/dialer", {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ id: id }),
          })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((data) => {
              if (data.status === true) {
                this.getFormData();
                // toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
              } else {
                toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
              }
            })
            //Then with the error genereted...
            .catch((error) => {
              console.error("Error:", error);
            });
        }
      });
  }

  getFormData = () => {
    fetch(`${ApiUrl}` + "get/quick/dialer", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {

        if (response.status === true) {
          this.setState({
            quickDialer: response.data.records,
            phoneNumbers: response.data.phoneNumbers,
          });
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };



  getData = (queryString = "", data) => {
    let query = "";
    if (queryString == "") {
      query = "?lead_type=client&linked_user_id=1";
    } else {
      query = queryString + "&lead_type=client&linked_user_id=1";
    }
    fetch(`${ApiUrl}` + "clients" + query, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          console.log(response, "TESTINGGG");
          this.setState({
            total_pages: response.data.total,
            records: response.data.records,
            customFieldData: { client_ids: [] },
            coachPartners: response.data.coach_partners
          });
          $('input[type="checkbox"]:checked').prop('checked', false)

        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  handleFilterSubmit = async (event, values) => {
    this.setState({
      filter: values,
    });
    this.getData("", values);
  };

  cancelFilter = async () => {
    this.form && this.form.reset();
    this.setState({ filter: {}, customDate: true, group_id: 0 });
    this.getData("", "");
  };

  tableChangeHandler = (data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        if (key === "sort_order" && data[key]) {
          return (
            encodeURIComponent("sort_order") +
            "=" +
            encodeURIComponent(data[key].order) +
            "&" +
            encodeURIComponent("sort_column") +
            "=" +
            encodeURIComponent(data[key].column)
          );
        } else {
          return encodeURIComponent(key) + "=" + encodeURIComponent(data[key]);
        }
      })
      .join("&");
    this.getData("?" + queryString, this.state.filter);
  };

  openClientSubcribeModal = (client, index) => {
    this.setState(
      {
        currentClient: client,
      },
      () =>
        this.setState({
          ClientSubcribeModal: true,
        })
    );
  };

  ClientSubcribeSubmit = async (event, values) => {
    if (this.state.customFieldData['client_ids'].length > 0) {
      values.id = this.state.customFieldData['client_ids'];
    } else {
      values.id = this.state.currentClient.client_id;
    }
    // values.coach_id = this.state.currentClient.coach_id;
    fetch(`${ApiUrl}` + "client/subscribe/status", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          this.setState({
            ClientSubcribeModal: false,
          });
          this.getData();
          this.setState({
            currentClient: {
              id: "",
              email_status: "",
              call_status: "",
            },
            customFieldData: { client_ids: [] },
          });
          // toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });

        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  getfilterData = () => {
    fetch(`${ApiUrl}` + "get/group/tag", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ coach_id: uInfo().user_id }),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          this.setState({
            groups: response.data.groups,
            tags: response.data.tags,
          });
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  handleSubmitQuickDialer = async (event, values) => {
    values.clients_ids = this.state.customFieldData.client_ids.toString();
    fetch(`${ApiUrl}` + "add/quick/dialer", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      .then((data) => {

        if (data.status === true) {
          setTimeout(
            () => this.props.history.push(`/campaign/outbounddialer/start?runId=${bcrypt(data.data.campaign_id)}&dialer_type=quick-dialer`),
            1000
          );

        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  openForm() {
    document.getElementById("myForm").style.display = "block";
  }

  closeForm() {
    document.getElementById("myForm").style.display = "none";
  }


  groupArrayOfObjects(list, key) {
    return list.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  }

  getSmsTemplate = () => {
    fetch(`${ApiUrl}` + "get/sms/template", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          var templates = this.groupArrayOfObjects(response.data.templates, "type");
          var templates_group = [
            { label: "SMS", options: templates.SMS },
            { label: "MMS", options: templates.MMS },
          ];

          var tokens_group = [
            {
              label: "Default Field",
              options: response.data.tokens.default_fields,
            },
            {
              label: "Custom Field",
              options: response.data.tokens.custom_fields,
            },
          ];

          this.setState({
            smsTemplates: templates_group,
            tokens: tokens_group,
            phoneNumbers: response.data.phoneNumbers
          })
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  closeDynamicModal = () => {
    this.setState({
      dynamicModal: false,
      emailModal: false,
      dynamicModalName: '',
      smsBody: '',
      token: "",
      subject: "",
      html: "",
      notes: [],
      selectedClient: {},
      sendNote: false,
      noteType: 'manual'

    });
  }

  handleSubmit = async (event, values) => {
    values.client_id = this.state.selectedClient.client_id;
    values.type = this.state.dynamicModalName.toLowerCase();
    fetch(`${ApiUrl}` + "lead/activity", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((data) => {
        console.log(data, "Editing");
        if (data.status === true) {
          if (values.type == 'note') {
            this.setState({
              sendNote: false
            })
            this.getNotes(this.state.selectedClient.client_id, { 'note_type': 'manual' }, 'notes');
          } else {
            this.closeDynamicModal();
          }
          //toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }

      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  handleEmailSubmit = async (event, values) => {
    event.preventDefault();
    values.client_id = this.state.selectedClient.client_id;
    values.type = this.state.dynamicModalName.toLowerCase();
    values.from_name = this.state.verifiedFromName;
    values.from_email_id = this.state.verifiedFromEmailId;

    if (this.state.html != "") {
      let body = document.getElementById("custom-template").innerHTML;
      values.body = body;
    }else{
      values.body = this.state.smsBody;
    }

    fetch(`${ApiUrl}` + "company/email/activity", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((data) => {
        console.log(data, "Editing");
        if (data.status === true) {
          this.closeDynamicModal();
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  getEmailTemplate = () => {
    fetch(`${ApiUrl}` + "get/email/template", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          var tokens_group = [
            {
              label: "Default Field",
              options: response.data.tokens.default_fields,
            },
            {
              label: "Custom Field",
              options: response.data.tokens.custom_fields,
            },
          ];

          this.setState({
            tokens: tokens_group,
            smsTemplates: response.data.templates
          })
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  getVerifiedFromEmail = () => {
    fetch(`${ApiUrl}` + "verify/email/list", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
            const defaultFromEmail = response.data.records && response.data.records.filter(item => item.is_default === 1);
            this.setState({
              verifiedFromEmail:response.data.records,
              defaultFromEmail:defaultFromEmail,
              verifiedFromEmailId: defaultFromEmail && defaultFromEmail[0].id
            });
        } else {
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });

  };


  getEmailTemplateHtml = (id) => {
    fetch(`${ApiUrl}` + "get/html/" + id + "/" + uInfo().user_id, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        this.setState({ html: response.theme })
        // localStorage.setItem("theme", response.theme);
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  handleOutCall = () => {
    fetch(`${ApiUrl}` + "voice/token", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ identity: encodeURIComponent(this.identity) }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {

          this.setState(prevState => ({
            paramsters: {                   // object that we want to update
              ...prevState.paramsters,    // keep all other key-value pairs
              token: response.data.token,
              from_number: uInfo().outbound_phone,
            }
          }));

          this.setState({
            callToken: response.data.token
          })
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  getNotes = (id, data = {}, from = '') => {
    fetch(`${ApiUrl}` + "get/activity/" + id + "/5", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          this.setState({
            notes: response.data.records
          })
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };


  getCallSid = (call_sid) => {

  }

  resetDialAttempt = (campaign_id) => {
    fetch(`${ApiUrl}` + "campaign/reset/attempt", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ id: campaign_id }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          //toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
          swal({
            title: "Success",
            text: response.message,
            icon: "success",
            dangerMode: false,
          })
          setTimeout(() => {
            this.props.history.push({
              pathname: '/client/list',
            });
          }, 2000);
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  agentAction = (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const submitData = new FormData();
    for (let [key, value] of formData.entries()) {
      submitData.append(key, value);
    }
    submitData.append('ids', this.state.customFieldData.client_ids);
    submitData.append('action_type', event.nativeEvent.submitter.name)
    fetch(`${ApiUrl}` + "client/agent/action", {
      method: "POST",
      headers: {
        Authorization: `Bearer ` + uToken(),
      },
      body: submitData,
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((data) => {
        if (data.status === true) {
          swal({
            title: "Success",
            text: data.message,
            icon: "success",
            dangerMode: false,
          })
          this.getData();
        } else {
          swal({
            title: "Error",
            text: data.message,
            icon: "warning",
            dangerMode: true,
          })
          // toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
        // this.setState({ loader: true });
      })
      //Then with the error genereted...
      .catch((error) => {
        this.setState({ loader: true });

        console.error("Error:", error);
      });
  }
  render() {

    const queryParams = this.props.location.state ? this.props.location.state : '';
    const sessionMSg = queryParams.msg ? queryParams.msg : '';
    const sessionCampaignId = queryParams.campaign_id ? queryParams.campaign_id : 0;



    return (
      <React.Fragment>
        <Helmet>
          <title>
            {this.state.page_name}
          </title>
        </Helmet>
        <Row className="mb-2">


          <Col sm={8}>

          </Col>
          <Col sm={4}>
            <div className="input-group justify-content-end">
              <Dropdown className="left-shown">
                <Dropdown.Toggle drop={'start'} variant="primary" id="dropdown-basic" style={{ width: '32px', height: '34px', marginRight: '5px' }}>
                  <b className="fa fa-ellipsis-v" aria-hidden="true"></b>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item eventKey={'1'} onClick={() => {
                    this.openForm(); this.setState({
                      slide: 'slide-in'
                    })
                  }}><i className="fas fa-th-list"></i> Quick Dialer</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>

              {/* {isAllowed(uInfo(), ["can_create"]) && (
                <Link
                  type="button"
                  color="info"
                  className="btn btn-info mr-5"
                  to={"/client/add"}
                >
                  <i className="mdi mdi-plus"> </i>
                  Create
                </Link>
              )}
              {hasRole(uInfo(), ["Import Leads"]) && (
                <Link to={"/import/client"} className="btn  btn-success mr-5">
                  Import
                </Link>
              )}

              <Link to={"/client/forms"} className="btn btn-dark mr-5">
                Client Forms
              </Link> */}
            </div>
          </Col>

        </Row>
        {
          sessionMSg && sessionMSg != '' && (
            <>
              <Alert color="info">{dcrypt(sessionMSg)} <button onClick={() => this.resetDialAttempt(sessionCampaignId)} className="btn btn-danger">Click to reset</button></Alert>
            </>

          )
        }



        <div className={`form-popup-client page3 ${this.state.slide}`} id="myForm">
          <div className="form-container">
            <div className="modal-head top-heading">
              <h2> Quick Dialer </h2>
              <button
                onClick={() => {
                  this.closeForm();
                  this.openForm(); this.setState({
                    slide: ''
                  })
                }}
                className="btn btn-link text-white"
              >
                <i className="fas fa-times"> </i>
              </button>
            </div>
            <Card>
              <CardBody>
                <table className="table table-bordered table-stripped">
                  <thead>
                    <tr>
                      <th>#id</th>
                      <th>Title</th>
                      <th>Phone Number</th>
                      <th>Total Client</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      this.state.quickDialer.map((row, i) => {
                        return <tr key={i}>
                          <td>{row.campaign_id}</td>
                          <td>{row.title}</td>
                          <td>{row.phone_number}</td>
                          <td>{row.total_clients}</td>
                          <td>
                            <Link title="Start dialer" to={`/campaign/outbounddialer/start?runId=${bcrypt(row.campaign_id)}&dialer_type=quick-dialer&from=client`}><i className="fas fa-grip-horizontal"></i></Link>
                            <button onClick={() => this.removeQuickDialer(row.campaign_id)} className="btn btn-link text-danger" >
                              <i className="fas fa-trash"></i>
                            </button>
                          </td>

                        </tr>
                      })
                    }

                  </tbody>
                </table>
              </CardBody>
            </Card>
          </div>
        </div>
        {
          this.state.page1Class == "slide-in" && (
            <div className={`page3 ${this.state.page1Class} custom-scroll contract-details`}>
              <div>
                <div className="modal-head top-heading">
                  <h2> Quick Dialer </h2>
                  <button
                    onClick={() => {
                      this.setState({
                        page1Class: 'slide-out2'
                      })
                    }}
                    className="btn btn-link text-white"
                  >
                    <i className="fas fa-times"> </i>
                  </button>
                </div>
                <AvForm
                  onValidSubmit={this.handleSubmitQuickDialer}
                  // model={this.state.record}
                  ref={(c) => (this.form = c)}
                  className="needs-validation"
                >
                  <ModalBody className="row">
                    <div className="col-md-12 mb-3">
                      <AvField
                        name="title"
                        defaultValue={'Quick Dialer'}
                        label="Title"
                        className="form-control"
                        required
                      />
                    </div>
                    <div className="col-md-6 mb-3 d-none">
                      <AvField
                        name="from_name"
                        defaultValue={uInfo().from_name}
                        label="From Name"
                        className="form-control"
                      />
                    </div>
                    <div className="col-md-6 mb-3 d-none">
                      <AvField
                        name="from_email"
                        defaultValue={uInfo().from_email}
                        label="From Email"
                        className="form-control"
                      />
                    </div>
                    <div className="col-md-12 mb-3">
                      <AvField
                        className="select form-control"
                        type="select"
                        name="phone_number"
                        required
                        label=" Phone Number"
                        value={this.state.record.phone_number}
                      >
                        <option value=""> Select Phone Number </option>
                        {
                          this.state.phoneNumbers.map((row, i) => {
                            return (
                              <option key={i} value={row.phone_number} > {convertPhoneFormat(row.phone_number)}</option>
                            );
                          })
                        }
                      </AvField>
                    </div>
                  </ModalBody>
                  <ModalFooter>

                    <Button
                      type="button"
                      color="secondary"
                      onClick={() => this.setState({ page1Class: 'slide-out2' })}
                    >
                      Cancel
                    </Button>

                    <button
                      className="btn btn-primary w-md waves-effect waves-light"
                      type="submit"
                    >
                      Submit
                    </button>
                  </ModalFooter>
                </AvForm>
              </div>
            </div>
          )
        }
        {
          this.state.page2Class == "slide-in" && (
            <div className={`page3 ${this.state.page2Class} custom-scroll contract-details`}>
              <div>
                <div className="modal-head top-heading">
                  <h2> Training </h2>
                  <button
                    onClick={() => {
                      this.setState({
                        page2Class: 'slide-out2'
                      })
                    }}
                    className="btn btn-link text-white"
                  >
                    <i className="fas fa-times"> </i>
                  </button>
                </div>
                {
                  this.state.is_bulk_check &&
                  this.state.customFieldData.client_ids.length > 0 && (
                    <WayTrainingAssign trainingFor={'3'} type={"client_list"} userId={this.state.customFieldData.client_ids.toString()} />
                  )
                }
              </div>
            </div>
          )
        }
        <ToastContainer />

        <Row>
          <Col className="col-12">
            <Card>
              <CardBody className="m-2 border-20">
                <div className="table-upper d-flex justify-content-between">
                  <CardTitle className="h2 mb-2 mt-1">All Clients
                    <Dropdown drop="up" className="d-inline ml-5 d-none">
                      <Dropdown.Toggle variant="primary"
                        className="AllLead btn btn-danger btn-sm">
                        Select Current Page
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item onClick={() => {
                          $(".lead-checkbox").prop("checked", false);
                          this.setState((prevState) => ({
                            customFieldData: {
                              ...prevState.customFieldData,
                              ['client_ids']: [],
                            },
                          }));
                          let x = this.state.records.map(lead => lead.client_id.toString());

                          this.setState((prevState) => ({
                            customFieldData: {
                              ...prevState.customFieldData,
                              ['client_ids']: [...prevState.customFieldData['client_ids'], ...x],
                            },
                          }));

                          $(".lead-checkbox").prop("checked", true);
                        }}> Select</Dropdown.Item>
                        <Dropdown.Item onClick={() => {
                          $(".lead-checkbox").prop("checked", false);
                          this.setState((prevState) => ({
                            customFieldData: {
                              ...prevState.customFieldData,
                              ['client_ids']: [],
                            },
                          }));
                        }}> UnSelect</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </CardTitle>

                  <div className="filter-table">
                    {

                      this.state.customFieldData.client_ids.length > 0 ? (

                        <Row>
                          <Col xl="12" className="mb-2">
                            <div className="full-width">
                              <div className="ContentActionSearch">
                                <p className="mr-5 TotalCount">Selected {this.state.customFieldData.client_ids.length} of {this.state.total_pages} Clients</p>
                                <div className="DropDownSelecter">
                                  <DropdownButton
                                    as={'key'}
                                    key={'Info'}
                                    id={`dropdown-variants-Info`}
                                    variant={'info'}
                                    title={this.state.actionTitle}
                                    size="lg"
                                    className="ml-5"
                                  >
                                    <Dropdown.Item onClick={() => this.setState({ page1Class: 'slide-in', actionTitle: 'Action', assignAgent: false, assignTags: false, copyGroups: false, removeLeads: false })} eventKey="1"><i className="fas fa-plus"></i>  Create quick dialer</Dropdown.Item>
                                    <Dropdown.Item onClick={() => this.setState({ page2Class: 'slide-in', actionTitle: 'Action', assignAgent: false, assignTags: false, copyGroups: false, removeLeads: false })} eventKey="1"><i className="fas fa-chalkboard-teacher"></i>  Training</Dropdown.Item>
                                    {/* <Dropdown.Item onClick={() => this.setState({ ClientSubcribeModal: true, actionTitle: 'Action', assignAgent: false, assignTags: false, copyGroups: false , removeLeads: false})} eventKey="1"><i className="fas fa-envelope"></i> Unsubscribe/Subscribe</Dropdown.Item>
                                    <Dropdown.Item onClick={() => { this.setState({ actionTitle: 'Assign Agent Action', assignAgent: !this.state.assignAgent, assignTags: false, copyGroups: false , removeLeads: false}) }} eventKey="1"><i className="fas fa-envelope"></i>  Assign Agent</Dropdown.Item>
                                    <Dropdown.Item onClick={() => { this.setState({ actionTitle: 'Assign/Remove Tags Action', assignAgent: false, assignTags: !this.state.assignTags, copyGroups: false, removeLeads: false }) }} eventKey="1"><i className="fas fa-envelope"></i> Assign/Remove Tags</Dropdown.Item>
                                    <Dropdown.Item onClick={() => { this.setState({ actionTitle: 'Copy/Move Clients', assignAgent: false, assignTags: false, copyGroups: !this.state.copyGroups, removeLeads: false }) }} eventKey="1"><i className="fas fa-envelope"></i>  Copy/Move Clients</Dropdown.Item> */}
                                    <Dropdown.Item onClick={() => this.setState({ actionTitle: 'Action', assignAgent: false, assignTags: false, copyGroups: false, removeLeads: true })} eventKey="1"><i className="fas fa-trash"></i>  Remove</Dropdown.Item>

                                  </DropdownButton>
                                </div>
                              </div>
                              <div className="ContentActionForm">
                                <form className={`form-horizontal form-clients ${this.state.assignAgent ? '' : 'd-none'}`}
                                  onSubmit={this.agentAction} method="post"
                                  encType="multipart/form-data">
                                  <div className="FormInputs">
                                    <div className="SelectTags">
                                      <Select
                                        getOptionLabel={(option) => option.name}
                                        getOptionValue={(option) => option.user_id}
                                        // value={options.filter(({ id }) => id === this.state.country_code)}
                                        isMulti={true}
                                        options={this.state.coachPartners}
                                        classNamePrefix="select2-selection"
                                        name="assign_to_agents_arr"
                                        placeholder={"Select Agent"}
                                      />
                                    </div>
                                    {/* <div className="SelectTags">
                <div className="form-group">
                  <input type={'text'} className="form-control"></input>
                </div>
              </div> */}
                                    <div className="btns">
                                      <input type={'submit'} name="add_agent" className="btn btn-md btn-primary" value='Assign To Agent(s)' />
                                      {/* <input type={'submit'} name="remove_agent" className="btn btn-md btn-primary ml-5" value='Remove Assigned Agent' /> */}
                                    </div>
                                  </div>

                                </form>
                              </div>
                              <div className="ContentActionForm">
                                <form className={`form-horizontal form-clients ${this.state.assignTags ? '' : 'd-none'}`}
                                  onSubmit={this.agentAction} method="post"
                                  encType="multipart/form-data">
                                  <div className="FormInputs">
                                    <div className="SelectTags">
                                      <Select
                                        getOptionLabel={(option) => option.title}
                                        getOptionValue={(option) => option.group_id}
                                        // value={options.filter(({ id }) => id === this.state.country_code)}
                                        isMulti={false}
                                        isClearable
                                        options={this.state.tags}
                                        classNamePrefix="select2-selection"
                                        name="assign_to_agents_arr"
                                        placeholder={"Tag lists"}
                                      />
                                    </div>
                                    {/* <div className="SelectTags">
                <div className="form-group">
                  <input type={'text'} className="form-control"></input>
                </div>
              </div> */}
                                    <div className="btns">
                                      <input type={'submit'} name="add_tags" className="btn btn-md btn-primary" value='Add Tags' />
                                      <input type={'submit'} name="remove_tags" className="btn btn-md btn-primary ml-5" value='Remove Tags' />
                                    </div>
                                  </div>

                                </form>
                              </div>
                              <div className="ContentActionForm">
                                <form className={`form-horizontal form-clients ${this.state.copyGroups ? '' : 'd-none'}`}
                                  onSubmit={this.agentAction} method="post"
                                  encType="multipart/form-data">
                                  <div className="FormInputs">
                                    <div className="SelectTags">
                                      <Select
                                        getOptionLabel={(option) => option.title}
                                        getOptionValue={(option) => option.group_id}
                                        // value={options.filter(({ id }) => id === this.state.country_code)}
                                        isMulti={false}
                                        isClearable
                                        options={this.state.groups}
                                        classNamePrefix="select2-selection"
                                        name="assign_to_agents_arr"
                                        placeholder={"Group lists"}
                                      />
                                    </div>
                                    {/* <div className="SelectTags">
                <div className="form-group">
                  <input type={'text'} className="form-control"></input>
                </div>
              </div> */}
                                    <div className="btns">
                                      <input type={'submit'} name="copy_groups" className="btn btn-md btn-primary" value='Copy' />
                                      <input type={'submit'} name="move_groups" className="btn btn-md btn-primary ml-5" value='Move' />
                                    </div>
                                  </div>

                                </form>
                              </div>
                              <div className="ContentActionForm">
                                <form className={`form-horizontal form-clients ${this.state.removeLeads ? '' : 'd-none'}`}
                                  onSubmit={this.agentAction} method="post"
                                  encType="multipart/form-data">
                                  <input type={'hidden'} name={'assign_to_agents_arr'} value={'1'} />
                                  <div className="btns">
                                    <input type={'submit'} name="remove" className="btn btn-md btn-primary" value='Delete Record' />
                                  </div>

                                </form>
                              </div>
                            </div>
                          </Col>
                        </Row>

                      ) : (
                        <Row className={`${this.state.searchFilter ? '' : 'd-none'}`}>
                          <Col xl="12">

                            <AvForm
                              onValidSubmit={this.handleFilterSubmit}
                              ref={(c) => (this.form = c)}
                              className="needs-validation"
                            >
                              <Row>
                                <Col lg={10}>
                                  <Row>
                                    <Col md="4">
                                      <div className="">
                                        <AvField
                                          className="select form-control"
                                          type="select"
                                          name="date_range"
                                          // label="Duration"
                                          onChange={(e) =>
                                            e.target.value == "Custom Date"
                                              ? this.setState({
                                                customDate: false,
                                              })
                                              : this.setState({
                                                customDate: true,
                                              })
                                          }
                                        >
                                          <option value=""> All Time </option>
                                          <option value="Current Week"> Current Week </option>
                                          <option value="Last Week"> Last Week </option>
                                          <option value="Current Month"> Current Month </option>
                                          <option value="Last Month"> Last Month </option>
                                          <option value="Last 3 Months"> Last 3 Months </option>
                                          <option value="Last 6 Months"> Last 6 Months </option>
                                          <option value="Current Year"> Current Year </option>
                                          <option value="Last Year"> Last Year </option>
                                          <option value="Custom Date"> Custom Range </option>
                                        </AvField>
                                      </div>
                                    </Col>
                                    <Col md="2" className="px-0">
                                      <div className="">
                                        <AvField
                                          name="start_date"
                                          // label="Start date"
                                          className="form-control"
                                          placeholder="Enter Email Address"
                                          type="date"
                                          disabled={this.state.customDate}
                                          required={!this.state.customDate}
                                        />
                                      </div>
                                    </Col>
                                    <Col md="2">
                                      <div className="">
                                        <AvField
                                          name="end_date"
                                          // label="End date"
                                          className="form-control"
                                          placeholder="Enter Email Address"
                                          type="date"
                                          disabled={this.state.customDate}
                                          required={!this.state.customDate}
                                        />
                                      </div>
                                    </Col>
                                    <Col md="4" className="px-0">
                                      <div className="">
                                        <div className="MainTZone1">
                                          <Select
                                            placeholder="All Groups"
                                            getOptionLabel={(option) => option.title}
                                            getOptionValue={(option) => option.group_id}
                                            value={this.state.groups.filter(({ group_id }) => group_id === this.state.group_id)}
                                            isMulti={false}
                                            options={this.state.groups}
                                            classNamePrefix="select2-selection"
                                            name="group"
                                            isClearable
                                            onChange={(e) => {
                                              this.setState({ group_id: e ? e.group_id : "" });
                                            }}
                                          />
                                        </div>
                                        <AvField
                                          type="hidden"
                                          name="group_id"
                                          value={this.state.group_id}
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                </Col>
                                <Col lg={2}>
                                  <div className="button-items text-center">
                                    <div className="visibility-hidden mt-1" style={{ width: '100%' }}></div>
                                    <Button className="btn-sm" color="primary" type="submit">
                                      Filter
                                    </Button>
                                    <Button
                                      onClick={() => this.cancelFilter()}
                                      outline
                                      color="secondary"
                                      className="waves-effect btn-sm"
                                      type="button"
                                    >
                                      Reset
                                    </Button>
                                  </div>
                                </Col>
                              </Row>
                            </AvForm>

                          </Col>
                        </Row>
                      )
                    }
                  </div>

                  <div className="table-filter d-flex">
                    <div className="MonthlyDropDown mb-2">
                      {/* <select className="form-control">
                        <option>Filter By</option>
                      </select> */}
                    </div>
                    <div className="filter ml-5">
                      <button
                        className="btn btn-warning btn-sm"
                        onClick={() => this.setState({ searchFilter: !this.state.searchFilter })}
                      >
                        <i className="fas fa-filter"> </i>
                      </button>
                    </div>
                  </div>

                </div>
                <hr />
                <div className=" dialer-table-leadClient">
                  <ReactDatatable
                    className="table table-border"
                    id="lead-datatable"
                    config={this.config}
                    records={this.state.records}
                    columns={this.columns}
                    dynamic={true}
                    total_record={this.state.total_pages}
                    onChange={this.tableChangeHandler}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Modal
          isOpen={this.state.dynamicModal}
          fade={true}
          size="md"
          toggle={() => this.closeDynamicModal()}
        >
          <AvForm
            onValidSubmit={this.handleSubmit}
            // model={this.state.record}
            className="needs-validation"
          >
            {this.state.dynamicModalName == "Note" ? (
              <div className="note">
                <div className="modal-head top-heading">
                  <h2> Add Note </h2>
                  <button
                    onClick={() => this.closeDynamicModal()}
                    className="btn btn-link text-white"
                  >
                    <i className="fas fa-times"> </i>
                  </button>
                </div>
                <ModalBody>
                  <div className="d-flex justify-content-between">
                    <div className="MonthlyDropDown mb-2" style={{ width: 'auto' }}>
                      <select onChange={(e) => { this.setState({ notes: [], noteType: e.target.value }); this.getNotes(this.state.selectedClient.client_id, { 'note_type': e.target.value }, 'notes') }} className="form-control">
                        <option value={'manual'}>Manual Notes</option>
                        <option value={'system'}>System Notes</option>
                        <option value="all">All Notes</option>
                      </select>
                    </div>
                    {
                      this.state.noteType == 'manual' && (
                        <div className="text-right mb-2">
                          <button onClick={() => this.setState({ sendNote: true })} type="button" className="btn code-green" ><i className="fas fa-plus"></i></button>
                        </div>
                      )
                    }
                  </div>

                  {
                    this.state.sendNote && (
                      <div className="">
                        <div className="mb-0">
                          <AvField
                            className="form-control"
                            type="textarea"
                            name="activity"
                            label=""
                            placeholder="Add a note"
                            required
                          />
                        </div>
                        <ModalFooter>
                          <Button color="info" type="submit">
                            Add Note
                          </Button>
                          <Button color="danger" onClick={() => this.setState({ sendNote: false })}>
                            Cancel
                          </Button>
                        </ModalFooter>
                      </div>

                    )
                  }
                  {this.state.notes.length > 0 ? (
                    this.state.notes.map((row, i) => {
                      return (
                        <div className="CompanyDetail ">
                          <div key={"note-" + i} className="note activity-info">
                            <div className="activity-body">
                              <div className="activity-bubble note">
                                <div className="activity-content">
                                  <div className="activity_time">
                                    <div className="ActivityTitle" style={{ width: '65%' }}>
                                      <span> {row.type} </span>
                                    </div>
                                    {row.created_at}
                                  </div>
                                  <p> {row.activity} </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className="col-12 col-lg-12 col-xl-12 text-center">
                      No Record Found
                    </div>
                  )}

                  {/* <hr />
                  <div className="mb-3">
                    <AvField
                      className="form-control"
                      type="textarea"
                      name="activity"
                      label=""
                      placeholder="Add a note"
                      required
                    />
                  </div> */}
                </ModalBody>
                {/* <ModalFooter>
                  <Button color="info" type="submit">
                    Add Note
                  </Button>
                  <Button color="danger" onClick={() => this.closeDynamicModal()}>
                    Cancel
                  </Button>
                </ModalFooter> */}
              </div>
            ) : this.state.dynamicModalName == "sms" ? (
              <div className="sms">
                <div className="modal-head top-heading">
                  <h2> Send SMS </h2>
                  <button
                    onClick={() => this.closeDynamicModal()}
                    className="btn btn-link text-white"
                  >
                    <i className="fas fa-times"> </i>
                  </button>
                </div>
                <ModalBody>
                  <div className="mb-3">
                    <div className="MainTZone1 SendSMS One">
                      <Select
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        // value={options.filter(({ id }) => id === this.state.country_code)}
                        isMulti={false}
                        options={this.state.smsTemplates}
                        classNamePrefix="select2-selection"
                        name="country_id"
                        placeholder={"Select sms template"}
                        onChange={(e) => {
                          this.setState({
                            smsBody: e.body,
                            template_id: e.id
                          })
                        }}
                      />
                    </div>
                    <AvField type="hidden" name="template_id" value={this.state.template_id} />
                    <AvField type="hidden" name="tos" value={this.state.selectedClient.mobile != '' ? this.state.selectedClient.mobile : this.state.selectedClient.phone} />
                    <AvField type="hidden" name="activity" value={"sms send"} />
                  </div>
                  <div className="mb-3">
                    <AvField
                      className="select form-control"
                      type="select"
                      name="froms"
                      required
                    >
                      <option value=""> Select Phone Number </option>
                      {this.state.phoneNumbers.map((row, i) => {
                        return (
                          <option key={"phone-" + i} value={row.phone_number}>
                            {convertPhoneFormat(row.phone_number)}
                          </option>
                        );
                      })}
                    </AvField>
                  </div>
                  <div className="row mb-3">
                    <div className="col-md-8">
                      <div className="MainTZone">
                        <Select
                          getOptionLabel={(option) => option.label}
                          getOptionValue={(option) => option.key}
                          // value={options.filter(({ id }) => id === this.state.country_code)}
                          isMulti={false}
                          options={this.state.tokens}
                          classNamePrefix="select2-selection"
                          name="country_id"
                          placeholder={"Select token"}
                          onChange={(e) => {
                            this.setState({
                              token: `{${e.key}}`
                            })
                          }}
                        />
                      </div>
                      {/* <AvField type="hidden" name="country_code" value={token} /> */}
                    </div>
                    <div className="col-md-4">
                      <button
                        onClick={() => this.setState({ smsBody: `${this.state.smsBody} ${this.state.token}` })}
                        type="button"
                        className="btn btn-primary"
                      >
                        Insert Token
                      </button>
                    </div>
                  </div>
                  <div className="mb-3">
                    <AvField
                      className="form-control"
                      type="textarea"
                      name="body"
                      required
                      value={this.state.smsBody}
                      placeholder={"Enter body"}
                      onChange={(e) => this.setState({ smsBody: e.target.value })}
                    />
                  </div>
                </ModalBody>
                <ModalFooter>
                  <Button color="info" type="submit">
                    Send
                  </Button>
                  <Button color="danger" onClick={() => this.closeDynamicModal()}>
                    Cancel
                  </Button>
                </ModalFooter>
              </div>
            ) : this.state.dynamicModalName == "call" ? (
              <div className="call">
                <div className="modal-head top-heading">
                  <h2> Initiate Outbound Call </h2>
                  <button
                    onClick={() => this.closeDynamicModal()}
                    className="btn btn-link text-white"
                    type="button"
                  >
                    <i className="fas fa-times"> </i>
                  </button>
                </div>
                <ModalBody>
                  <div className="mb-3">
                    {this.state.callToken ? (
                      <Phone paramsters={this.state.paramsters} getCallSid={this.getCallSid}> </Phone>
                    ) : (
                      <p> Loading... </p>
                    )}
                  </div>
                </ModalBody>
              </div>
            ) : (
              <div className=""> </div>
            )}
          </AvForm>
        </Modal>

        <Modal
          isOpen={this.state.emailModal}
          fade={true}
          size="lg"
          toggle={() => this.closeDynamicModal()}
        >
          <AvForm
            onValidSubmit={this.handleEmailSubmit}
            autoComplete="off"
            className="needs-validation"
          >
            <div className="email">
              <div className="modal-head top-heading">
                <h2> Send Email </h2>
                <button
                  onClick={() => this.closeDynamicModal()}
                  className="btn btn-link text-white"
                >
                  <i className="fas fa-times"> </i>
                </button>
              </div>
              <ModalBody>
                <div className="col-md-12 mb-3">
                  <div className="MainTZone ClientDetails Two">
                    <Select
                      getOptionLabel={(option) => `${option.from_name}  -  ${option.from_email}`}
                      getOptionValue={(option) => option.id}
                      isMulti={false}
                      options={this.state.verifiedFromEmail}
                      classNamePrefix="select2-selection"
                      placeholder={"Select From Email"}
                      autocomplete="off"
                      value={this.state.defaultFromEmail}
                      name="froms"
                      onChange={(option) => {
                        this.setState({
                          defaultFromEmail: option,
                          verifiedFromEmailId : option.id
                        })
                      
                      }}
                    />
                  </div>
                </div>
                <div className="mb-3">
                  <div className="CommonSelectTwo Zindex">
                    <Select
                      getOptionLabel={(option) => option.title}
                      getOptionValue={(option) => option.id}
                      isMulti={false}
                      options={this.state.smsTemplates}
                      classNamePrefix="select2-selection"
                      placeholder={"Select email template"}
                      name="country_id"
                      onChange={(e) => {
                        this.setState({
                          subject: e.title
                        })
                        this.getEmailTemplateHtml(e.id);


                      }}
                    />
                    <AvField type="hidden" name="tos" value={this.state.selectedClient.email} />
                    <AvField type="hidden" name="activity" value={"Email: sent"} />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-md-12 mb-3">
                    <div className="CommonSelectTwo">
                      <Select
                        getOptionLabel={(option) => option.label}
                        getOptionValue={(option) => option.key}
                        isMulti={false}
                        options={this.state.tokens}
                        classNamePrefix="select2-selection"
                        name="country_id"
                        onChange={(e) => {
                          this.setState({
                            token: `{${e.key}}`
                          })
                        }}
                      />
                    </div>
                  </div>

                  
                  {this.state.html == "" && (
                    <div className="col-md-2">
                      <button
                        onClick={() => this.setState({ smsBody: `${this.state.smsBody} ${this.state.token}` })}
                        type="button"
                        className="btn btn-primary"
                      >
                        Insert Body
                      </button>
                    </div>
                  )}
                  <div className="col-md-4">
                    <button
                      onClick={() => this.setState({ subject: `${this.state.subject} ${this.state.token}` })}
                      type="button"
                      className="btn btn-primary"
                    >
                      Insert Subject
                    </button>
                  </div>
                </div>


                <div className="mb-3">
                  <AvField
                    className="form-control"
                    type="text"
                    name="subject"
                    placeholder="Subject"
                    required
                    value={this.state.subject}
                    onChange={(e) => this.setState({ subject: e.target.value })}
                  />
                </div>
                {this.state.html == "" ? (
                  <div className="mb-3">
                    {/* <AvField
                      className="form-control"
                      type="textarea"
                      name="body"
                      placeholder="Enter body"
                      required
                      value={this.state.smsBody}
                      onChange={(e) => this.setState({ smsBody: e.target.value })}
                    /> */}
                    <SunEditor name="body"
                    setOptions={
                      {
                        height: 300,
                        width: '100%',
                        resizeEnable: 'false',
                        buttonList: [
                          ["undo", "redo", "font", "fontSize", "formatBlock"],
                          [
                            "bold",
                            "underline",
                            "italic",
                            "strike",
                            "subscript",
                            "superscript",
                            "removeFormat",
                          ],
                          [
                            "fontColor",
                            "hiliteColor",
                            "outdent",
                            "indent",
                            "align",
                            "horizontalRule",
                            "list",
                            "table",
                          ],
                          [
                            "link",
                            "image",
                            "video",
                            "fullScreen",
                            "showBlocks",
                            "codeView",
                            "preview",
                          ],

                        ],
                        proportion: 'Constrain proportions assasa',
                      }
                    }
                    setContents={this.state.smsBody}
                    value={this.state.smsBody}
                    onChange={(e) => this.setState({ smsBody: e})}
                  />
                  </div>
                ) : (
                  <>
                    <div
                      id="custom-template"
                      className="height-500 custom-scroll"
                      dangerouslySetInnerHTML={{ __html: this.state.html }}
                    ></div>
                    {/* <AvField type="hidden" name="body" value={document.getElementById('custom-template')}/> */}
                  </>
                )}
              </ModalBody>
              <ModalFooter>
                <Button color="info" type="submit">
                  Send
                </Button>
                <Button color="danger" onClick={() => this.closeDynamicModal()}>
                  Cancel
                </Button>
              </ModalFooter>
            </div>
          </AvForm>
        </Modal>
        {/* ////////////////// ClientSubcribeModal /////////////////////  */}
        <Modal
          isOpen={this.state.ClientSubcribeModal}
          role="dialog"
          centered={true}
          className="exampleModal"
          tabIndex="-1"
          toggle={() => {
            this.setState({
              ClientSubcribeModal: !this.state.ClientSubcribeModal,
            });
          }}
        >
          <div className="modal-content">
            <ModalHeader
              toggle={() => {
                this.setState({
                  ClientSubcribeModal: !this.state.ClientSubcribeModal,
                });
              }}
            >
              Subscribe / Unsubscribe Client
            </ModalHeader>
            <AvForm
              onValidSubmit={this.ClientSubcribeSubmit}
              className="needs-validation"
            >
              <ModalBody>
                <div className="mb-3">
                  <AvField
                    className="select form-control"
                    type="select"
                    name="call_status"
                    label="Landline & Mobile"
                    required
                    value={'1'}
                  >
                    <option value={'1'}> Subscribe </option>
                    <option value={'0'}> Unsubscribe </option>
                  </AvField>
                </div>
                <div className="mb-3">
                  <AvField
                    className="select form-control"
                    type="select"
                    name="email_status"
                    label="Email"
                    required
                    value={'1'}
                  >
                    <option value={'1'}> Subscribe </option>
                    <option value={'0'}> Unsubscribe </option>
                  </AvField>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button
                  type="button"
                  color="secondary"
                  onClick={() => {
                    this.setState({
                      ClientSubcribeModal: !this.state.ClientSubcribeModal,
                    });
                  }}
                >
                  Close
                </Button>
                <button
                  className="btn btn-primary waves-effect waves-light"
                  type="submit"
                >
                  Save
                </button>
              </ModalFooter>
            </AvForm>
          </div>
        </Modal>
        {/* ////////////////// ClientSubcribeModal //////////////////// */}
      </React.Fragment >
    );
  }
}
