import React, { useEffect, useRef, useState } from 'react';
import Loader from "react-loader";
import swal from 'sweetalert';
import { ApiUrl, AttachementsUrl } from '../../config';
import { bcrypt, uInfo, uToken } from '../../useToken';
import WayBookContext from './WaybookContext';
import Dropdown from 'react-bootstrap/Dropdown';
import ProgressBar from 'react-bootstrap/ProgressBar';
import ListGroup from 'react-bootstrap/ListGroup';
import { Link } from 'react-router-dom';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import grapesjs from 'grapesjs'
import 'grapesjs/dist/css/grapes.min.css'
import 'grapesjs/dist/grapes.min.js'
import 'grapesjs-preset-webpage/dist/grapesjs-preset-webpage.min.css'
import 'grapesjs-preset-webpage/dist/grapesjs-preset-webpage.min.js'
import $ from 'jquery'
import FroalaEditor from 'froala-editor'

// Load a plugin.
import 'froala-editor/js/plugins/inline_style.min.js'
import "froala-editor/js/plugins/align.min.js"
import "froala-editor/js/plugins/code_beautifier.min.js"
import "froala-editor/js/plugins/code_view.min.js"
import "froala-editor/js/plugins/colors.min.js"
import "froala-editor/js/plugins/emoticons.min.js"
import "froala-editor/js/plugins/draggable.min.js"
import "froala-editor/js/plugins/font_size.min.js"
import "froala-editor/js/plugins/font_family.min.js"
import "froala-editor/js/plugins/image.min.js"
import "froala-editor/js/plugins/image_manager.min.js"
import "froala-editor/js/plugins/line_breaker.min.js"
// import "froala-editor/js/plugins/quick_insert.min.js"
import "froala-editor/js/plugins/link.min.js"
import "froala-editor/js/plugins/lists.min.js"
import "froala-editor/js/plugins/paragraph_format.min.js"
import "froala-editor/js/plugins/paragraph_style.min.js"
import "froala-editor/js/plugins/video.min.js"
import "froala-editor/js/plugins/table.min.js"
import "froala-editor/js/plugins/url.min.js"
import "froala-editor/js/plugins/emoticons.min.js"
import "froala-editor/js/plugins/file.min.js"
import "froala-editor/js/plugins/entities.min.js"
import "froala-editor/js/plugins/inline_style.min.js"
import "froala-editor/js/plugins/save.min.js"
import "froala-editor/js/plugins/fullscreen.min.js"

// Require Editor CSS files.
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/css/plugins/code_view.css';
import 'froala-editor/css/plugins/image_manager.css';
import 'froala-editor/css/plugins/image.css';
import 'froala-editor/css/plugins/table.css';
import 'froala-editor/css/plugins/video.css';
import Froala from '../Froala';


// Initialize editor.
function DocumentContents(props) {
    const params = React.useContext(WayBookContext);
    const [lists, setLists] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [list, setList] = useState({});
    const [documentList, setDocumentList] = useState({});
    const [token, setToken] = useState([]);

    useEffect(() => {
        // new FroalaEditor("#edit", {
        //     toolbarInline: true,
        //     toolbarVisibleWithoutSelection: true,
        //     toolbarButtons: [['bold', 'italic', 'underline', 'strikeThrough', 'textColor', 'backgroundColor', 'emoticons'], ['paragraphFormat', 'align', 'formatOL', 'formatUL', 'indent', 'outdent'], ['insertImage', 'insertLink', 'insertFile', 'insertVideo', 'undo', 'redo']],
        //     toolbarButtonsXS: null,
        //     toolbarButtonsSM: null,
        //     toolbarButtonsMD: null
        // });
        // var hello;
        // document.getElementById('init').addEventListener('click', function (e) {
        //     console.log("Initializing Froala Editor...")
        //     document.getElementById('edit').classList.remove('fr-view')
        //     hello = new FroalaEditor("#edit", {
        //         toolbarInline: true,
        //         toolbarButtons: [['bold', 'italic', 'underline', 'strikeThrough', 'textColor', 'backgroundColor', 'emoticons'], ['paragraphFormat', 'align', 'formatOL', 'formatUL', 'indent', 'outdent'], ['insertImage', 'insertLink', 'insertFile', 'insertVideo', 'undo', 'redo']],
        //     })
        // })
        // document.getElementById('destroy').addEventListener('click', function (e) {
        //     console.log("Destroying Froala Editor...")
        //     console.log(hello)
        //     hello('destroy');
        //     document.getElementById('edit').classList.add('fr-view')
        // })
        getWayBookStepsLists();

    }, []);

    const handleSubmit = (event, content) => {
        fetch(`${ApiUrl}` + "update/waybook/steps/content/" + params.documents, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ step_id: params.step ? params.step : list.step_id, content: content }),
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {
                if (response.status === true) {
                    swal({
                        title: response.message,
                        icon: "success",
                    });
                } else {
                    swal({
                        title: response.message,
                        icon: "warning",
                    });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    };


    const getWayBookStepsLists = () => {
        fetch(`${ApiUrl}` + "get/waybook/steps/" + params.documents, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ step_id: params.step, can_edit: params.can_edit }),
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {
                if (response.status === true) {
                    setLists(response.data.records);
                    setList(response.data.record);
                    setDocumentList(response.data.document)
                } else {
                    swal({
                        title: response.message,
                        icon: "warning",
                    });
                }
                setLoaded(true);

            })
            //Then with the error genereted...
            .catch((error) => {
                setLoaded(true);

                console.error("Error:", error);
            });
    };

    const updateWayBookStepListOrder = (newlists) => {
        fetch(`${ApiUrl}` + "update/waybook/steps/order/" + params.document, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ can_edit: params.can_edit, lists: newlists }),
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {
                if (response.status === false) {
                    swal({
                        title: response.message,
                        icon: "warning",
                    });
                    getWayBookStepsLists();
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    const dragItem = useRef();
    const dragOverItem = useRef();
    const dragStart = (e, position) => {
        // e.prevenDefault(); remove this for reordering


        if (!params.can_edit) {
            e.prevenDefault();
        }
        dragItem.current = position;
    };

    const dragEnter = (e, position) => {
        // e.preventDefault();
        dragOverItem.current = position;
        // console.log(e.target.innerHTML);
    };

    const dropTwo = (e, i) => {
        const copyListItems = [...lists];
        const dragItemContent = copyListItems[dragItem.current];
        copyListItems.splice(dragItem.current, 1);
        copyListItems.splice(dragOverItem.current, 0, dragItemContent);
        dragItem.current = null;
        dragOverItem.current = null;

        if (!copyListItems.some(element => element === undefined)) {
            setLists(copyListItems);
            updateWayBookStepListOrder(copyListItems)
        }
    };




    // $('#init').on('click', function name(e) {
    //     editors = null;
    //     console.log('Editior Render')
    //     e.preventDefault();
    //     document.getElementById('edit').classList.remove('fr-view')
    //     editors = new FroalaEditor("#edit", {
    //         // toolbarInline: true,
    //         // toolbarVisibleWithoutSelection: true,
    //         // toolbarButtons: [['bold', 'italic', 'underline', 'strikeThrough', 'textColor', 'backgroundColor', 'emoticons'], ['paragraphFormat', 'align', 'formatOL', 'formatUL', 'indent', 'outdent'], ['insertImage', 'insertLink', 'insertFile', 'insertVideo', 'undo', 'redo']],

    //         events: {
    //             initialized: function (e) {
    //                 const editor = this
    //                 document.getElementById('get-text').addEventListener("click", function (e) {
    //                     e.preventDefault();
    //                     console.log('ho')
    //                 })
    //             }
    //         }
    //     })
    // });

    // $('#destroy').on('click', function name(e) {
    //     e.preventDefault();
    //     if (editors && editors.length > 0) {
    //         editors.forEach(element => {
    //             element.destroy()
    //         });
    //     }
    //     document.getElementById('edit').classList.add('fr-view')
    // });


    return (
        <div>
            <Loader loaded={loaded}></Loader>
            <div className='row'>
                <div className='col-lg-12 ps-0'>
                    <div className='way-header-right'>
                        <div className='modal-header'>
                            <h5>{documentList.title}

                                {
                                    params.can_edit && (
                                        <button className="btn btn-sm btn-link" onClick={() => params.subjectOrDocumentAction('Document', documentList.cat_id, documentList.title, 'Update', documentList.training_for)}>
                                            <i className='fas fa-edit'></i>
                                        </button>
                                    )
                                }
                                <br />
                                <small>In Subject <img height={20} width={20} src={`${AttachementsUrl}/user_${documentList.master?.user_id}/training/${documentList.master?.image ?? ''}`} alt="" /> {documentList.master?.title ?? ''}</small>

                            </h5>

                        </div>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-lg-3 px-0 border-right  HeightHundred'>


                    {
                        params.can_edit ? (
                            <div className='edit-step-action mt-3 mx-3'>
                                <button className='btn btn-primary w-100 submit-step-content' id='submit-step-content'>Update Changes</button>
                            </div>
                        ) : (
                            <div className='step-content py-2 px-3'>
                                <div className='d-flex justify-content-between align-items-center'>
                                    <span>
                                        {documentList.percentage}% Complete
                                    </span>
                                    <button className='btn btn-reset' onClick={() => params.documentLogsReset(params.documents)}>Reset</button>
                                </div>


                                <ProgressBar now={documentList.percentage} />
                               
                            </div>
                        )
                    }

                    <div className='OverFlowAuto'>
                        <ListGroup className='mt-3 step-lists content-page-documents-list-item'>
                            {
                                lists.map((sub, i) => {
                                    return <ListGroup.Item
                                        key={sub.step_id}
                                        className={`${list.step_id == sub.step_id && ('active')} ${sub.disabled ? 'disabled' : ''}`}
                                    >
                                        <div className='d-flex justify-content-between'
                                            onDragEnd={(e) => dropTwo(e, i)}
                                            onDragEnter={(e) => dragEnter(e, i)}
                                        >
                                            <div className='steps-list'>
                                                {
                                                    params.can_edit && (
                                                        <i className='fas fa-grip-vertical draggable drag-handle document-drag-handle has-tooltip'
                                                            onDragStart={(e) => dragStart(e, i)}
                                                            draggable
                                                        >

                                                        </i>
                                                    )
                                                }
                                                <Link className={`${sub.disabled ? 'disabled' : ''} text-dark`} to={`/trainings/${bcrypt(params.category_i)}/${bcrypt(params.category)}/${bcrypt(params.subject_i)}/${bcrypt(params.subject)}/${bcrypt(params.documents_i)}/${bcrypt(params.documents)}/${bcrypt(i)}/${bcrypt(sub.step_id)}/?step=${bcrypt(sub.step_id)}`}>

                                                    {sub.title}
                                                    {
                                                        sub.status == 'Draft' && (
                                                            <div className='text-danger'><small>unpublished</small></div>
                                                        )
                                                    }
                                                </Link>
                                            </div>
                                            <div className='step-action'>

                                                {
                                                    params.can_edit ? (
                                                        <Dropdown onSelect={(e) => params.stepMethodAction('Step', sub.step_id, sub.title, e, sub.training_for)}>
                                                            <Dropdown.Toggle variant="link">
                                                                <i className='fas fa-ellipsis-h'></i>
                                                            </Dropdown.Toggle>

                                                            <Dropdown.Menu>
                                                                {
                                                                    sub.status == 'Publish' ?
                                                                        <Dropdown.Item eventKey="Draft">Switch to Draft</Dropdown.Item>
                                                                        :
                                                                        <Dropdown.Item eventKey="Publish">Switch to Publish</Dropdown.Item>

                                                                }
                                                                <Dropdown.Item eventKey="Update">Rename</Dropdown.Item>
                                                                <Dropdown.Item eventKey="Move">Move</Dropdown.Item>
                                                                <Dropdown.Item eventKey="Delete">Delete</Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    ) : (
                                                        <div className='mark-option'>
                                                            {
                                                                sub.log_id ? (
                                                                    <OverlayTrigger
                                                                        placement="auto"
                                                                        overlay={<Tooltip id="button-tooltip">
                                                                            You've completed this step
                                                                        </Tooltip>}
                                                                    >
                                                                        <i className='fas fa-check-circle'></i>
                                                                    </OverlayTrigger>
                                                                ) : (
                                                                    <OverlayTrigger
                                                                        placement="auto"
                                                                        overlay={<Tooltip id="button-tooltip">
                                                                            You've not completed this step
                                                                        </Tooltip>}
                                                                    >
                                                                        <i className='fas fa-circle'></i>
                                                                    </OverlayTrigger>
                                                                )
                                                            }
                                                        </div>
                                                    )
                                                }

                                            </div>
                                        </div>

                                    </ListGroup.Item>
                                })
                            }
                        </ListGroup>
                    </div>

                    <div className='create-step'>

                        {
                            params.can_edit && (
                                <div className='add-new-step'>
                                    <div className='add-step-btn text-center mt-3 w-100'>
                                        <button onClick={() => params.subjectOrDocumentAction('Step', params.documents, documentList.title, 'AddStep')} className='bwaves-effect waves-light btn btn-outline-dark w-100'> New Step</button>
                                    </div>
                                </div>
                            )
                        }

                    </div>
                </div>
                <div className='col-lg-9 pt-3 ps-4 pe-3'>

                    <h4>{list.title}</h4>
                    {
                        params.can_edit ?
                            <>
                                <div className={list.step_id > 0 ? '' : 'd-none'}>
                                    {
                                        list.step_id > 0 && (
                                            <Froala inlineEditior={true} btnId={'submit-step-content'} content={list.content} handleSubmit={handleSubmit} use_token={'users'} />
                                        )
                                    }
                                </div>


                                <div className={list.step_id ? 'd-none' : 'add-new-step'}>
                                    <div className='add-step-btn text-center mt-3 w-100'>
                                        <button onClick={() => params.subjectOrDocumentAction('Step', params.documents, documentList.title, 'AddStep')} className='bwaves-effect waves-light btn btn-outline-dark w-100'> New Step</button>
                                    </div>
                                </div>

                            </>
                            :
                            <>
                                <div className='TextEditor fr-view'>
                                    <div className="TraningContent" dangerouslySetInnerHTML={{ __html: list.body }} />

                                    <div className={`${list.step_id > 0 ? 'd-block' : 'd-none'}`}>
                                        {
                                            list.log_id > 0 ? (
                                                <div className='not-edit-action'>
                                                    <div className='btn-mark text-center mb-3'>
                                                        <button disabled className='btn btn-primary'>Marked as complete</button>
                                                    </div>
                                                    <div className='btn btn-unmark text-center w-100'>
                                                        <button onClick={() => params.documentLogsReset(params.documents, list.step_id)} className='btn btn-link'>Unmark as Complete?</button>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className={`not-edit-action mt-3 text-center d-flex justify-content-center ${uInfo().user_id == 2 && ('d-none')}`}>
                                                    <div className='w-75'>
                                                        <h6>If you've read and understand everything in this step you can mark it as complete.
                                                            This will help you track your progress on this document.
                                                        </h6>
                                                        <div className='btn-mark mt-3 mb-3'>
                                                            <button onClick={() => params.markAsComplete(params.documents, list.step_id)} className='btn btn-primary'>Mark as complete</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </>
                    }

                </div>
            </div>

            <div className='row'>
                <div className='col-lg-2'>

                </div>
            </div>
        </div>
    );
}

export default DocumentContents;